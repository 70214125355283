import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CSpinner,
	CCard,
	CCardHeader,
	CCardBody,
	CRow,
	CCol,
	CLink,
	CCloseButton,
} from '@coreui/react';
import PropTypes from 'prop-types';
import { UFunc } from "../helpers/functions";

const pjson 	= require('../../package.json')
const MyDialogpasien = props => {
	const { 
		show, toggle, pasienID, 
		setLoading, prosesExpired, setSessionaktif,
		...rest 
	} = props;

	const uBahasaObj	= useSelector(state => state.listBahasa);
	const uTokenObj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uDatacabangArr= JSON.parse(localStorage.getItem("cabanglist")||"[]")

	const [uPasiendataArr,setPasiendataArr] = React.useState([]);
	const [uPasienloadObj,setPasienloadObj] = React.useState({});
	const [uIsDlgloading,setDlgloading]		= React.useState(false);
	const [uApimsg,setApimsg]  				= React.useState(null);

	const contentDialog = () => {
		if(uIsDlgloading)  return (
			<div className="d-flex justify-content-center align-items-center"
				style={{minHeight:100,height:"25%"}}>
			<CSpinner color="info"/>
			</div>
		)//>

		if(uApimsg) return (
			<CCard>
			<CCardHeader>Something Wrong, Dude !</CCardHeader>
			<CCardBody>{UFunc.renderHTML(uApimsg)}</CCardBody>
			</CCard>
		)//>

		if(JSON.stringify(uPasienloadObj||{})==="{}") return (<></>);//>

		const vTgldaftarDT = UFunc.toDatetime(uPasienloadObj.tgl_input);

		return (
		<>
			<div className="text-center">
				<big className="text-info align-middle">{uPasienloadObj.pasien_caption}</big>
				<span className="mx-1">&middot;</span>
				<b style={{color:(uPasienloadObj.sex==="L"?"navy":"pink")}}>[{uPasienloadObj.sex}]</b>
				<span className="mx-1">&middot;</span>
				<CLink 
					onClick={()=>apiLoadpasien(pasienID)}
					className="classikontabel classikonreset classcursorpointer"
					style={{}}/>
			</div>

			{(uPasienloadObj.asuransi_caption||"")!=="" && (
			<>
				<div className="text-center">
				<small className="fst-italic" style={{color:"purple"}}>
					Asuransi: <b>{uPasienloadObj.asuransi_caption}</b>
				</small>
				</div>

			</>
			)}

			<div className="my-3 classborderbottom"/>

			<CRow className="align-items-stretch">
			<CCol lg="6" className="mb-3">
				<CCard className="h-100">
				<CCardHeader className="classfontsmaller py-0 pt-1">Pendaftaran</CCardHeader>
				<CCardBody className="py-1 classfontsmaller">
					<CRow className="my-1">
					<CCol xs="4">Tgl Daftar</CCol>
					<CCol xs="8" className="text-end">
						{UFunc.TglAngkaDT(uPasienloadObj.tgl_input)}
					</CCol>
					</CRow>

					<CRow className="my-1">
					<CCol xs="4">Nomor RM</CCol>
					<CCol xs="8" className="text-end fw-bolder">
						{uPasienloadObj.no_rm || "-"}
					</CCol>
					</CRow>

					<CRow className="my-1">
					<CCol xs="4">NIK</CCol>
					<CCol xs="8" className="fw-bolder text-end">{uPasienloadObj.nik||"-"}</CCol>
					</CRow>

					{(uPasienloadObj.ihs_number||"") !=="" && (
					<CRow className="my-1">
					<CCol xs="4">IHS Number</CCol>
					<CCol xs="8" className="text-end text-success">{uPasienloadObj.ihs_number}</CCol>
					</CRow>
					)}

					{uDatacabangArr.length  > 1 && (
					<CRow className="my-1">
					<CCol xs="4">Cabang</CCol>
					<CCol xs="8" className="text-end">{uPasienloadObj.cabang_register}</CCol>
					</CRow>
					)}

					<CRow className="my-1">
					<CCol xs="4">Inputby</CCol>
					<CCol xs="8" className="text-danger text-end">[{uPasienloadObj.userinput||"UNDF"}]</CCol>
					</CRow>

					{(uPasienloadObj.keterangan||"")!=="" && (
					<CRow className="my-1">
					<CCol xs="4">
						<small className="d-md-none">Kondisi Khusus</small>
						<span className="d-none d-md-block">Kondisi Khusus</span>
					</CCol>
					<CCol xs="8" className="text-end text-success">
						<span className="classblinking">{uPasienloadObj.keterangan||""}</span>
					</CCol>
					</CRow>
					)}
				</CCardBody>
				</CCard>
			</CCol>
			
			<CCol className="mb-3">
				<CCard className="h-100">
				<CCardHeader className="py-0 pt-1 classfontsmaller">Data Pribadi</CCardHeader>
				<CCardBody className="py-1">
					<small>
					{(uPasienloadObj.panggilan||"")!=="" && (
					<CRow className="my-1">
					<CCol xs="4">Panggilan</CCol>
					<CCol xs="8" className="text-end">{uPasienloadObj.panggilan}</CCol>
					</CRow>
					)}

					<CRow className="my-1">
					<CCol xs="3">Umur</CCol>
					<CCol xs="9" className="text-end">
						{(uPasienloadObj.tgl_lahir||"")!=="" ? (
						<>
						<b className="align-middle">{uPasienloadObj.umur||"0 th"}</b>
						<span className="mx-1">&middot;</span>
						({(uPasienloadObj.tempat_lahir||"")!=="" && (
							<>{uPasienloadObj.tempat_lahir}, </>
						)}
						{UFunc.TglAngka(uPasienloadObj.tgl_lahir)})
						</>
						) : ("-")}
					</CCol>
					</CRow>

					{(uTokenObj.userhak !== "DOKTER") && (uPasienloadObj.telp_hp||"")!=="" && (
					<CRow className="my-1">
					<CCol xs="4">Telp/HP</CCol>
					<CCol xs="8" className="fw-bolder text-primary text-end">{uPasienloadObj.telp_hp||"-"}</CCol>
					</CRow>
					)}

					<CRow className="my-1">
					<CCol xs="4">Alamat</CCol>
					<CCol xs="8" className="text-end">
						<span className="fst-italic">
						{UFunc.renderHTML(uPasienloadObj.alamat||"-")}
						</span>
					</CCol>
					</CRow>

					{(uPasienloadObj.pekerjaan_caption||"")!=="" && (
					<CRow className="my-1">
					<CCol xs="4">Pekerjaan</CCol>
					<CCol xs="8" className="text-end"><span style={{color:"brown"}}>{uPasienloadObj.pekerjaan_caption}</span></CCol>
					</CRow>
					)}
					</small>
				</CCardBody>
				</CCard>
			</CCol>
			</CRow>

			<CCard className="mb-3">
			<CCardHeader className="py-0 pt-1 classfontsmaller">Riwayat Alergi</CCardHeader>
			<CCardBody>
				{((uPasienloadObj.alergi||"")==="" || (uPasienloadObj.alergi||"")==="-") && ("-")}
				{(uPasienloadObj.alergi||"")!=="" && (uPasienloadObj.alergi||"")!=="-" && (
				<marquee className="text-warning fw-bolder">
				&middot;&middot; {uPasienloadObj.alergi||""} &middot;&middot;
				</marquee>
				)}
			</CCardBody>
			</CCard>
		</>
		)
	}

	const apiLoadpasien = (_IDPASIEN) => {
		setApimsg("");

		setPasienloadObj({});
		setDlgloading(true);

		if(UFunc.isEmpty(uTokenObj) )
			{ setLoading(true); prosesExpired(); return; }

		const vDATAS	= JSON.stringify({
			send_pasienid : _IDPASIEN,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			setDlgloading(false);
			if(output_string.tampil) {
				const vTmpObj	= JSON.parse(output_string.datapasien||"{}");
				if(JSON.stringify(vTmpObj||"{}")==="{}") {
					setApimsg(uBahasaObj.caption_dataempty||"Maaf, Data Tidak Ditemukan !");
					return;
				}

				setPasienloadObj(vTmpObj);
				const vTmpArr	= {
					id_pasien: parseInt(_IDPASIEN)||0,
					data_obj: vTmpObj,
				}
				setPasiendataArr([...uPasiendataArr,vTmpArr]);
			} else if(output_string.info) {
				setApimsg(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyDialogpasien - apiLoadpasien) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setApimsg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setDlgloading(false);
			console.log("(MyDialogpasien - apiLoadpasien) catch-error : "+error);
			setApimsg(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		return () => {
			setPasiendataArr([])
		}
	},[])
	React.useEffect(()=>{
		if(!show) 
			{ setPasienloadObj({}); return }
		if(pasienID <= 0) { toggle(); return }

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			const vElfocus = document.getElementById("btnDialogpasienok");
			vElfocus && vElfocus.focus();
		},300);

		const vIdxArr	= UFunc.getObjectIndeks(uPasiendataArr,pasienID,"id_pasien");
		if(vIdxArr >= 0) {
			setPasienloadObj(uPasiendataArr[vIdxArr].data_obj||{});
			return;
		} 
		apiLoadpasien(pasienID);
	},[show])

	//console.log("(MyDialogpasien) uPasienloadObj = "+JSON.stringify(uPasienloadObj||{}));
	return (
		<CModal 
			size={"lg"}
			alignment={"top"}
			scrollable
			visible={show}>
		<CModalHeader className="p-2 px-3" closeButton={false}>
			<CModalTitle><small>Data Detil Pasien</small></CModalTitle>
			<CCloseButton onClick={toggle}/>
		</CModalHeader>

		<CModalBody>
			{contentDialog()}
		</CModalBody>
		<CModalFooter className="border-0">
		<CButton 
			className="me-2 me-md-4 bg-transparent border-light"
			onClick={toggle}
			id="btnDialogpasienok">
		<strong className="text-dark">{uBahasaObj.word_ok||"OK"}</strong>
		</CButton>
		</CModalFooter>
		</CModal>
	)
}

export default MyDialogpasien;