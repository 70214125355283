import React from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CFormCheck,
	CFormSelect,
	CButton,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CBadge,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import {
	MyButton,
	MyHeaderProfile,
	MyDialogform,
	MyDialoglookup,
	MyDialogview,
	MySessionextender,
} from '../../components/index'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Periksa = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { tabel_id } 				= useParams(); 

	//--DOM--/
	const uNavigate 				= useNavigate();
	const uDispatch 				= useDispatch();
	//--END DOM--/

	const uPasienID 				= parseInt(tabel_id || "0")
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uUsersettingObj 			= useSelector(state => state.gListUserSetting);
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uEfekview2,setEfekview2] = React.useState(false);
	const uMaxData					= isMobile ? (uUsersettingObj.jml_mobile || 10) : (uUsersettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISAdmininit				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 

	const uDatamainObj 				= useSelector(state => state.gBillingperiksaObj) || {};
	const uItemsArr					= uDatamainObj.items_arr || [];
	const uJanjianArr				= uDatamainObj.janjian_arr || [];
	const uPerawatArr				= uDatamainObj.perawat_arr || [];
	const [uTambahitemObj,setTambahitemObj]	= React.useState({})
	const [uTabelitemObj,setTabelitemObj]	= React.useState({})
	const uFototambahArr			= uDatamainObj.foto_arr || [];// useSelector(state => state.gFototambahArr) || [];
	const [uUpdatefield,setUpdatefield] 	= React.useState(false);

	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatapasienObj,setDatapasienObj]	= React.useState({})
	const [uDatadokterArr,setDatadokterArr]	= React.useState([])
	const [uDataanamnaseArr,setDataanamnaseArr]	= React.useState([])
	const [uDataperawatArr,setDataperawatArr]	= React.useState([])
	const [uDatacatdokterArr,setDatacatdokterArr]= React.useState([])
	const [uDatadiagnosaArr,setDatadiagnosaArr]= React.useState([])
	const [uDataterapiArr,setDataterapiArr]= React.useState([])
	const [uDatajanjianArr,setDatajanjianArr]= React.useState([])
	const [uDataitemcicilanArr,setDataitemcicilanArr]= React.useState([])
	const [uHeaderloading,setHeaderloading]	= React.useState(false)
	const [uIsFormupdate,setIsFormupdate]	= React.useState(false)
	const uCabanginitObj 				= useSelector(state => state.gCabangatribut) || {};

	const [uCekantrianshow,setCekantrianshow] = React.useState(false);
	const [uCekantrianObj,setCekantrianObj] = React.useState({});
	const uCekDataantrianArr				= uCekantrianObj.antrian_arr || [];

	//---DLG_LOOKUP--/
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false)
	const [uDlglookupheader,setDlglookupheader]	= React.useState("")
	const [uInpDlglookup,setInpDlglookup]	= React.useState("")
	const [uDataDlglookupArr,setDataDlglookupArr]= React.useState([])
	const [uDlglookupjenis,setDlglookupjenis]	= React.useState("")
	//---END DLG_LOOKUP--/

	//---DLG_VIEW--/
	const [uIsDlgviewshow,setDlgviewshow]		= React.useState(false)
	const [uDlgviewheader,setDlgviewheader]		= React.useState("")
	const [uDlgviewjenis,setDlgviewjenis]		= React.useState("")
	//---END DLG_VIEW--/

	//--DLG_FORM--/
	//const [uDlgformheader,setDlgformheader]		= React.useState("")
	const [uIsDlgformshow,setDlgformshow]		= React.useState(false)
	const [uDlgformdataObj,setDlgformdataObj]	= React.useState({})
	//--END DLG_FORM--/

	//--DOM--/
	const uJmlcarddataheader= uISAdmininit ? 5 : 4;
	/*const uElcardanamnesa	= document.getElementById("idcardanamnase");
	const uElcardperawat	= document.getElementById("idcardperawat");
	const uElcardnote		= document.getElementById("idcardnote");
	const uElcardfooter		= document.getElementById("idcardfooter");

	const uHeightanamnesa	= uElcardanamnesa && parseFloat(uElcardanamnesa.clientHeight)||0;
	const uHeightperawat	= uElcardperawat && parseFloat(uElcardperawat.clientHeight)||0;
	const uHeightnote		= uElcardnote && parseFloat(uElcardnote.clientHeight)||0;
	const uHeightfooter		= uElcardfooter && parseFloat(uElcardfooter.clientHeight)||0;
	//--END DOM--*/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {
		if(_EV.keyCode === 27) {
			if(uCekantrianshow) {
				_EV.preventDefault();
				_EV.stopPropagation();

				setCekantrianshow(false);
			}
			return;
		}
		if(_EV.keyCode === 120) {
			const vElsimpan = document.getElementById("btnSimpanmain");
			vElsimpan && (vElsimpan.click())
			return;
		}
	}
	const hdlKlikBack = () => {
		uNavigate("/subpasien/pasien/rekammedis/"+uPasienID,{replace:true}); return;
	}
	const hdlKlikReset = async() => {
		const vResetawal = () => {
			setTambahitemObj({})
			setTabelitemObj({})
			apiLoadawal()
		}

		if(uIsFormupdate) {
			if(await(Konfirm(
				"<b>Billing Periksa Akan DIRESET..</b>"+
				"<br/>Form Billing Kemungkinan Sudah ADA Perubahan Field Data. "+
					"Anda Belum Menyimpannya, dan dapat membatalkan Perubahan Tersebut !"+
				"<br/><br/>Apakah Anda Yakin ?"+
				""))) initResetmain();
		} else {
			vResetawal();
		}
	}
	const hdlToggleDlgLookup = () => { setDlglookupshow(false)}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		if(uDlglookupjenis==="DOKTER")
			lookupPilihdokter(_IDITEM);
		else if(uDlglookupjenis==="DOKTERJANJIAN")
			lookupPilihdokterjanjian(_IDITEM);
		else if(uDlglookupjenis==="ANAMNASE")
			lookupPilihanamnase(_IDITEM);
		else if(uDlglookupjenis==="CATDOKTER")
			lookupPilihcatdokter(_IDITEM);
		else if(uDlglookupjenis==="TERAPI")
			lookupPilihterapi(_IDITEM)
		else if(uDlglookupjenis==="DIAGNOSA")
			lookupPilihdiagnosa(_IDITEM);
		else if(uDlglookupjenis==="DIAGNOSAFORM")
			lookupPilihFormdiagnosa(_IDITEM);
		else if(uDlglookupjenis==="FORMTERAPI")//--MOBILE
			lookupFormterapi(_IDITEM);

		hdlToggleDlgLookup();
	}
	const hdlToggleDlgform = () => { setDlgformshow(false); }
	const hdlKlikDlgformsimpan = () => {
		if(uDlgformdataObj.jenis === "PILIHTANGGAL") {
			initKlikformtanggal(); return;
		}
		if(uDlgformdataObj.jenis === "JANJIAN") {
			initKlikformjanjian(); return;
		}
		if(uDlgformdataObj.jenis === "FORMTERAPI") {
			initKlikformitem(); return;
		}
		if(uDlgformdataObj.jenis === "PILIHPERAWAT") {
			initKlikformperawat(); return;
		}
	}
	const hdlToggleDlgview = () => { setDlgviewshow(false)}

	const hdlKlikPilihtanggal = () => {
		uDlgformdataObj.jenis = "PILIHTANGGAL";
		uDlgformdataObj.dlg_header= "Tanggal Periksa";
		uDlgformdataObj.dlg_center = true;

		uDlgformdataObj.tgl_periksa = uDatamainObj.tgl_periksa;
		setDlgformshow(true);
	}
	const hdlKlikDokterlookup = () => {
		setInpDlglookup("");
		setDlglookupjenis("DOKTER");

		const vTmpArr	= uDatadokterArr.map(vItems => {
			return {id:vItems.id,caption:vItems.dokter_caption}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: NAMA DOKTER");
		setDlglookupshow(true);
	}
	const hdlKlikAnamnaselookup = () => {
		setInpDlglookup("");
		setDlglookupjenis("ANAMNASE");

		const vTmpArr	= uDataanamnaseArr.map(vItems => {
			return {id:vItems.id,caption:vItems.anamnase_caption}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: ANAMNASE");
		setDlglookupshow(true);
	}
	const hdlKlikPerawattambah = () => {
		/*//--LAMA--/
		setDlgviewjenis("PERAWAT");
		setDlgviewheader("Pilih NAMA PERAWAT");
		setDlgviewshow(true);
		//--END LAMA--*/

		uDlgformdataObj.jenis 			= "PILIHPERAWAT";
		uDlgformdataObj.dlg_header 		= "Pilih NAMA PERAWAT";
		uDlgformdataObj.dlg_center		= false;
		uDlgformdataObj.perawatid_arr 	= uPerawatArr.map(vItems=>vItems.id);
		setDlgformshow(true);
	}
	const hdlChangePerawatcheck = (e) => {
		const vValueID	= parseInt(e.target.value)||0;

		const vArridx = UFunc.getObjectIndeks(uPerawatArr,vValueID);
		//console.log("(Periksa - hdlChangePerawatcheck) vArridx => "+vArridx)
		if(vArridx < 0) {
			const vTmpArr = (uDataperawatArr.filter(vItems=>
				(vItems.id||"0").toString() === vValueID.toString()));

			//console.log("(Periksa - hdlChangePerawatcheck) vArridx => "+JSON.stringify(vTmpArr))
			uDatamainObj.perawat_arr = [...uPerawatArr,vTmpArr[0]];
		} else {
			uPerawatArr.splice(vArridx,1);
			uDatamainObj.perawat_arr = uPerawatArr;
		}
		setUpdatefield(!uUpdatefield);
	}
	const hdlKlikPerawatclear = async() => {
		if(uPerawatArr.length <= 0) return;

		if(await(Konfirm("Apakah Yakin Akan Menghapus SELURUH Perawat ?"))) {
			uDatamainObj.perawat_arr = [];
			setUpdatefield(!uUpdatefield);
		}
 	}
	const hdlKlikCatdokterlookup = () => {
		setInpDlglookup("");
		setDlglookupjenis("CATDOKTER");

		const vTmpArr	= uDatacatdokterArr.map(vItems => {
			return {id:vItems.id,caption:vItems.note_caption}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: CATATAN DOKTER");
		setDlglookupshow(true);
	}
	const hdlKlikDokterjanjianlookup = () => {
		setInpDlglookup("");
		setDlglookupjenis("DOKTERJANJIAN");

		const vTmpArr	= uDatadokterArr.map(vItems => {
			return {id:vItems.id,caption:vItems.dokter_caption}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: NAMA DOKTER");
		setDlglookupshow(true);
	}
	const hdlKDownTerapicaption = (_EV) => {
		initResettambahform();
		if(_EV.keyCode !== 13) return;
		const vTerapicaption	= (uTambahitemObj.terapi_caption||"").trim();
		if(vTerapicaption === "") return;

		_EV.preventDefault()
		_EV.stopPropagation();

		const vArrCariMaster 	= uDataterapiArr.filter((vItem)=>
			vItem.terapi_caption.toUpperCase().includes((vTerapicaption).toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uTambahitemObj.id				= (vArrCariMaster[0].id);
			uTambahitemObj.terapi_caption	= (vArrCariMaster[0].terapi_caption);
			uTambahitemObj.terapi_tarif		= parseInt(vArrCariMaster[0].terapi_tarif || 0);
			uTambahitemObj.terapi_main		= (vArrCariMaster[0].terapi_main);
			uTambahitemObj.status_garansi 	= vArrCariMaster[0].status_garansi;
			uTambahitemObj.terapi_qty		= 1;

			setEfekview(!uEfekview);
			const vElfocus = document.getElementById("inptambahitemqty");
			vElfocus && vElfocus.focus();
			return;
		}

		hdlKlikTerapilookup();
	}
	const hdlKlikTerapilookup = () => {
		//--CEK_IDDOKTER--/
		const vDokteridx = UFunc.getObjectIndeks(uDatadokterArr,uDatamainObj.id_dokter);
		if(vDokteridx < 0) {
			document.getElementById("inpdoktercaption") &&
				document.getElementById("inpdoktercaption").focus();

			showToast("Nama Dokter Harus Dipilih Terlebih Dahulu !"); return;
		}
		const vArrkompetensiID = uDatadokterArr[vDokteridx].kompetensi_arr || [];
		//--END CEK_IDDOKTER--/

		setInpDlglookup(uTambahitemObj.terapi_caption||"");
		setDlglookupjenis("TERAPI");

		const vTmpArr	= uDataterapiArr.filter(vItems =>{
			return (parseInt(vItems.id_kompetensi||0) <= 0 ||
				vArrkompetensiID.includes(vItems.id_kompetensi.toString())
			);
		}).map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.terapi_caption,// +" ("+vItems.id_kompetensi+")",
				harga_satuan:vItems.terapi_tarif
			}
		});
		//console.log("(hdlKlikTerapilookup) vTmpArr => "+JSON.stringify(vTmpArr));
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: TERAPI");
		setDlglookupshow(true);
	}
	const hdlKlikDiagnosalookup = (_IDXITEM) => {
		_IDXITEM = _IDXITEM===undefined ? -1 : parseInt(_IDXITEM);
		if(_IDXITEM < 0) { setTabelitemObj({}) }

		setInpDlglookup("");
		setDlglookupjenis("DIAGNOSA");

		const vTmpArr	= uDatadiagnosaArr.map(vItems => {
			return {id:vItems.id,caption:vItems.nama_diagnosa}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: DIAGNOSA");
		setDlglookupshow(true);
	}
	const hdlKlikketgigiview = (_IDXITEM) => {
		_IDXITEM = _IDXITEM===undefined ? -1 : parseInt(_IDXITEM);

		if(_IDXITEM < 0) { setTabelitemObj({}) }

		setDlgviewjenis("KETGIGI");
		setDlgviewheader("Pilih NOMOR GIGI");
		setDlgviewshow(true);
	}
	const hdlKlikPilihketgigi = (_EV,_TEKSVALUE) => {
		_TEKSVALUE		= (_TEKSVALUE||"").trim();
		const vElcheck	= document.getElementById("iddiagramchecked");
		const vISFormItem = (uDlgformdataObj.jenis === "FORMTERAPI");

		//console.log("(hdlKlikPilihketgigi) uDlgformdataObj.jenis => "+uDlgformdataObj.jenis);
		//console.log("(hdlKlikPilihketgigi) vISFormItem => "+vISFormItem);

		if(vISFormItem) {
			uDlgformdataObj.ketgigi = ((uDlgformdataObj.ketgigi||"").trim() === "" ? ""
				: uDlgformdataObj.ketgigi+", ")+ _TEKSVALUE;
		} else {
			if(JSON.stringify(uTabelitemObj||{})==="{}") {
				uTambahitemObj.ketgigi = (uTambahitemObj.ketgigi||"") +
					((uTambahitemObj.ketgigi||"").trim()!=="" ? ", " : "")+
					_TEKSVALUE; 
			} else {
				uTabelitemObj.keterangan = (uTabelitemObj.keterangan||"") +
					((uTabelitemObj.keterangan||"").trim()!=="" ? ", " : "")+
					_TEKSVALUE; 
			}
		}
		setEfekview(!uEfekview);

		if(vElcheck) {
			vElcheck.style.top 	= (_EV.clientY - _EV.target.offsetTop - 18)+"px";
			vElcheck.style.left = (_EV.clientX - _EV.target.offsetLeft + 7)+"px";
			vElcheck.style.display = "block";
			//alert(_EV.pageX);
		}

		let vTimeout = setTimeout((e) => {
			clearTimeout(vTimeout);
			vElcheck && (vElcheck.style.display = "none");
		},700);//--*/
	}
	const hdlKlikTambahitem = () => {
		const vTerapiID = parseInt(uTambahitemObj.id)||0;
		if(vTerapiID <= 0) return;

		if(parseInt(uTambahitemObj.terapi_qty||0) < 1) {
			document.getElementById("inptambahitemqty") &&
				document.getElementById("inptambahitemqty").focus();
			return;
		}

		const vTerapimain = uTambahitemObj.terapi_main || "";
		const vTmpObj = {
			id_terapi_sub : vTerapiID,
			caption_terapi : uTambahitemObj.terapi_caption || "",
			tarif_terapi: uTambahitemObj.terapi_tarif || "0",
			qty_terapi: uTambahitemObj.terapi_qty || 1,
			diagnosa_id : uTambahitemObj.diagnosa_id||0,
			diagnosa_caption : (uTambahitemObj.diagnosa_caption||"").trim(),
			diagnosa_keterangan : (uTambahitemObj.diagnosa_keterangan||"").trim(),
			tindakan : UFunc.nl2br((uTambahitemObj.tindakan||"").trim()),
			keterangan : (uTambahitemObj.ketgigi||"").trim(),
			status_ada_garansi : (uTambahitemObj.status_garansi||"TIDAK"),
			tgl_batas_garansi : "",
		}

		uDatamainObj.items_arr = [vTmpObj,...uItemsArr];

		const vTmpresetObj = {
			terapi_caption : vTerapimain
		}
		setTambahitemObj(vTmpresetObj);

		document.getElementById("inptambahitemdiagnosa") &&
			document.getElementById("inptambahitemdiagnosa").focus();
		setUpdatefield(!uUpdatefield);
	}
	const hdlKlikTabelsimpan = (_IDXITEM) => {
		_IDXITEM = parseInt(_IDXITEM); if(_IDXITEM < 0) return;

		if(parseInt(uTabelitemObj.qty_terapi||0) < 1) {
			document.getElementById("inptabelitemqty") &&
				document.getElementById("inptabelitemqty").focus();
			return;
		}
		//---PROSES_EDIT--/
		uItemsArr[_IDXITEM].diagnosa_id = uTabelitemObj.diagnosa_id;
		uItemsArr[_IDXITEM].diagnosa_caption = (uTabelitemObj.diagnosa_caption||"").trim();
		uItemsArr[_IDXITEM].diagnosa_keterangan = (uTabelitemObj.diagnosa_keterangan||"").trim();
		uItemsArr[_IDXITEM].tindakan 	= UFunc.nl2br((uTabelitemObj.tindakan||"").trim());
		uItemsArr[_IDXITEM].keterangan 	= (uTabelitemObj.keterangan||"").trim();
		uItemsArr[_IDXITEM].qty_terapi 	= uTabelitemObj.qty_terapi||1;

		uDatamainObj.items_arr = uItemsArr;
		//---END PROSES_EDIT--/

		setTabelitemObj({});
		setUpdatefield(!uUpdatefield);
	}
	const hdlKlikTabeledit = (_IDXITEM) => {
		_IDXITEM = parseInt(_IDXITEM); if(_IDXITEM < 0) return;

		const vTmpObj = {
			tabelidx: _IDXITEM,
			diagnosa_id: uItemsArr[_IDXITEM].diagnosa_id||0, 
			diagnosa_caption: uItemsArr[_IDXITEM].diagnosa_caption||"", 
			diagnosa_keterangan: uItemsArr[_IDXITEM].diagnosa_keterangan||"", 
			tindakan: UFunc.br2nl(uItemsArr[_IDXITEM].tindakan||""), 
			keterangan: uItemsArr[_IDXITEM].keterangan||"", 
			qty_terapi: uItemsArr[_IDXITEM].qty_terapi||1, 
		}
		setTabelitemObj(vTmpObj);
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inptabelitemdiagnosa"+_IDXITEM);
			vElfocus && vElfocus.focus();
		},350)
	}
	const hdlKlikTabelhapus = async(_IDXITEM) => {
		_IDXITEM = parseInt(_IDXITEM); if(_IDXITEM < 0) return;

		const vFuncnext = () => {
			uItemsArr.splice(_IDXITEM,1);
			uDatamainObj.items_arr = uItemsArr;
			setUpdatefield(!uUpdatefield);
		}

		if(await(Konfirm("Apakah Yakin Akan Dihapus ?"))) vFuncnext()
	}
	const hdlKlikTambahitemmobile = () => {
		uDlgformdataObj.dlg_header	= "Tambah Item Terapi";
		uDlgformdataObj.jenis 		= "FORMTERAPI";
		setDlgformshow(true);
	}
	const hdlKlikTabelEditmobile = (_IDXITEM) => {
		const vArridx = parseInt(_IDXITEM);

		uDlgformdataObj.idxtabel		= vArridx;
		uDlgformdataObj.terapi_sub_id 	= uItemsArr[vArridx].id_terapi_sub || 0;
		uDlgformdataObj.terapi_caption 	= uItemsArr[vArridx].caption_terapi;
		uDlgformdataObj.terapi_tarif  	= uItemsArr[vArridx].tarif_terapi;
		uDlgformdataObj.terapi_main		= uItemsArr[vArridx].id_terapi_sub;
		uDlgformdataObj.diagnosa_id		= uItemsArr[vArridx].diagnosa_id;
		uDlgformdataObj.diagnosa_caption= uItemsArr[vArridx].diagnosa_caption;
		uDlgformdataObj.diagnosa_keterangan= uItemsArr[vArridx].diagnosa_keterangan;
		uDlgformdataObj.tindakan 		= UFunc.br2nl(uItemsArr[vArridx].tindakan);
		uDlgformdataObj.ketgigi 		= uItemsArr[vArridx].keterangan;
		uDlgformdataObj.status_garansi 	= uItemsArr[vArridx].status_ada_garansi;
		uDlgformdataObj.terapi_qty 		= uItemsArr[vArridx].qty_terapi || 1;

		uDlgformdataObj.dlg_header	= "Edit Terapi";
		uDlgformdataObj.jenis 		= "FORMTERAPI";
		setDlgformshow(true);
	}
	const hdlKDownFormitemcaption = (_EV) => {
		uDlgformdataObj.terapi_sub_id 	= 0;
		uDlgformdataObj.terapi_tarif  	= 0;
		uDlgformdataObj.terapi_main		= "";
		uDlgformdataObj.status_garansi 	= 0;
		uDlgformdataObj.terapi_qty 		= 0;
		if(_EV.keyCode !== 13) { setEfekview(!uEfekview); return; }
 		const vTerapicaption	= (uDlgformdataObj.terapi_caption||"").trim();
		if(vTerapicaption === "") { setEfekview(!uEfekview); return; }

		_EV.preventDefault()
		_EV.stopPropagation();

		const vArrCariMaster 	= uDataterapiArr.filter((vItem)=>
			vItem.terapi_caption.toUpperCase().includes((vTerapicaption).toUpperCase()));
		
		if (vArrCariMaster.length === 1) {
			uDlgformdataObj.terapi_sub_id	= (vArrCariMaster[0].id);
			uDlgformdataObj.terapi_caption	= (vArrCariMaster[0].terapi_caption);
			uDlgformdataObj.terapi_tarif	= parseInt(vArrCariMaster[0].terapi_tarif || 0);
			uDlgformdataObj.terapi_main		= (vArrCariMaster[0].terapi_main);
			uDlgformdataObj.status_garansi 	= vArrCariMaster[0].status_garansi;
			uDlgformdataObj.terapi_qty		= 1;

			setEfekview(!uEfekview);
			document.getElementById("inpformitemqty") &&
				document.getElementById("inpformitemqty").focus();
			return;
		}

		hdlKlikFormitemlookup();
	}
	const hdlKlikFormitemlookup = () => {
		//--CEK_IDDOKTER--/
		const vDokteridx = UFunc.getObjectIndeks(uDatadokterArr,uDatamainObj.id_dokter);
		if(vDokteridx < 0) {
			showToast("Nama Dokter Harus Dipilih Terlebih Dahulu !"); return;
		}
		const vArrkompetensiID = uDatadokterArr[vDokteridx].kompetensi_arr || [];
		//--END CEK_IDDOKTER--/

		setInpDlglookup(uDlgformdataObj.terapi_caption||"");
		setDlglookupjenis("FORMTERAPI");


		const vTmpArr	= uDataterapiArr.filter(vItems =>{
			return (parseInt(vItems.id_kompetensi||0) <= 0 ||
				vArrkompetensiID.includes(vItems.id_kompetensi.toString())
			);
		}).map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.terapi_caption,// +" ("+vItems.id_kompetensi+")",
				harga_satuan:vItems.terapi_tarif
			}
		});

		/*const vTmpArr	= uDataterapiArr.map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.terapi_caption,
				harga_satuan:vItems.terapi_tarif
			}
		});//-*/
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: TERAPI");
		setDlglookupshow(true);
	}
	const hdlKlikFormitemdiagnosalookup = () => {
		setInpDlglookup("");
		setDlglookupjenis("DIAGNOSAFORM");

		const vTmpArr	= uDatadiagnosaArr.map(vItems => {
			return {id:vItems.id,caption:vItems.nama_diagnosa}});
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: DIAGNOSA");
		setDlglookupshow(true);
	}
	const hdlKlikJanjiantambah = () => {
		if((parseInt(uDatamainObj.id_dokter)||0) <= 0) {
			document.getElementById("inpdoktercaption") &&
				document.getElementById("inpdoktercaption").focus()

			showToast("Nama Dokter Harus Dipilih Dahulu !"); return;
		}

		uDlgformdataObj.dlg_size	= "lg";
		uDlgformdataObj.dlg_header 	= "Input Janjian Berikutnya";
		uDlgformdataObj.jenis 		= "JANJIAN";
		uDlgformdataObj.no_ruang 	= 1;

		uDlgformdataObj.id_cabang		= uCabanginitObj.id_cabang;
		uDlgformdataObj.dokter_id		= uDatamainObj.id_dokter;
		uDlgformdataObj.dokter_caption	= uDatamainObj.nama_dokter;
		setDlgformshow(true);
	}
	const hdlKlikJanjianhapus = async(_IDXITEM) => {
		_IDXITEM = _IDXITEM===undefined ? -1 : parseInt(_IDXITEM);
		if(_IDXITEM < 0) return;

		if((parseInt(uJanjianArr[_IDXITEM].id_antrian)||0) > 0) return;

		if(await(Konfirm(uBahasaObj.confirm_hapus))) {
			uJanjianArr.splice(_IDXITEM,1);
			uDatamainObj.janjian_arr = uJanjianArr;
			setUpdatefield(!uUpdatefield)
		}
	}
	const hdlKlikCekantriantoggle = () => {
		if(!uCekantrianshow) {
			if((uDlgformdataObj.tgl_antri||"")==="") {
				showToast("Harap Tentukan Tanggal Antrian Terlebih Dahulu !");
				return;
			}
			if(uDlgformdataObj.tgl_antri === UFunc.DbDate()) {
				showToast("Tanggal Cek Antrian Hanya Untuk Tanggal SETELAH Hari Ini !");
				return;	
			}
			const vTglantriDT = UFunc.toDate(uDlgformdataObj.tgl_antri);
			if(UFunc.DateIsMundur(vTglantriDT)) {
				showToast("Tanggal Cek Antrian Sudah Kadaluwarsa !");
				return;	
			}
		}
		setCekantrianshow(!uCekantrianshow);
	}
	const hdlKlikCekantrianview = () => {
		if((uCekantrianObj.tgl_antri||uDlgformdataObj.tgl_antri)==="") {
			uCekantrianObj.errormsg = ("Harap Tentukan Tanggal Antrian Terlebih Dahulu !");
			setEfekview(!uEfekview);return;
		}
		if(uCekantrianObj.tgl_antri === UFunc.DbDate()) {
			uCekantrianObj.errormsg = ("Tanggal Cek Antrian Hanya Untuk Tanggal SETELAH Hari Ini !");
			setEfekview(!uEfekview);return;
		}
		const vTglantriDT = UFunc.toDate(uCekantrianObj.tgl_antri);
		if(UFunc.DateIsMundur(vTglantriDT)) {
			uCekantrianObj.errormsg = ("Tanggal Cek Antrian Sudah Kadaluwarsa !");
			setEfekview(!uEfekview);return;
		}
		apiLoadantrian();
	}
	const hdlKlikSimpanmain = async() => {
		if(!uIsFormupdate) return;
		if(uTabelitemObj.tabelidx !== undefined) {
			showToast("Kemungkinan Proses <b>Edit Terapi Masih BELUM Disimpan</b>..<br/>Mohon dicek Terlebih Dahulu !");
			return;
		}
		let vElfocus = document.getElementById("inptglperiksa");
		if((uDatamainObj.tgl_periksa||"").trim()==="") {
			vElfocus && vElfocus.focus()
			showToast("Tanggal Antrian Tidak Valid !"); return
		}
		const vTglantriDT = UFunc.toDate(uDatamainObj.tgl_periksa);
		if(UFunc.DateIsBeyond(vTglantriDT)) {
			vElfocus && vElfocus.focus()
			showToast("Tanggal Antrian Melewati Hari Ini !"); return
		}
		vElfocus = document.getElementById("inpdoktercaption");
		if(parseInt(uDatamainObj.id_dokter||0) <= 0) {
			vElfocus && vElfocus.focus()
			showToast("Dokter Harus Dipilih !"); return
		}
		vElfocus = document.getElementById("inpanamnasecaption");
		if((uDatamainObj.anamnase||"").trim()==="") {
			vElfocus && vElfocus.focus()
			showToast("Anamnase Harus Dipilih !"); return
		}
		if((uPerawatArr.length) <= 0) {
			showToast("Belum Ada PERAWAT yang Disertakan !"); return
		}
		if((uItemsArr.length) <= 0) {
			showToast("Belum Ada Item Terapi yang Diinputkan !"); return
		}

		//showToast(JSON.stringify(uDatamainObj)); return;

		if(await(Konfirm(
			//"<b>Simpan Billing PERIKSA</b>.."+
			//"<br/><br/>"+
			//"<div>"+uDatamainObj.dokter_id+"</div>"+
			"<div class='d-flex justify-content-between mx-3'>"+
				"<div>Tgl Periksa</div>"+
				"<b>"+UFunc.TglAngka(uDatamainObj.tgl_periksa)+"</b>"+
			"</div>"+
			"<div class='d-flex justify-content-between mx-3'>"+
				"<div>Dokter</div>"+
				"<b class='text-primary'>"+uDatamainObj.nama_dokter+"</b>"+
			"</div>"+
			"<div class='d-flex justify-content-between mx-3'>"+
				"<div>Perawat</div>"+
				"<div class='ps-2 fst-italic text-end d-block'>"+
				(uPerawatArr.map((vItems,vKeys)=>{
					return vItems.perawat_caption;
				})).join(" &middot; ")+
				"</div>"+
			"</div>"+

			"<hr class='border-none border-botom' />"+

			"<div class='d-flex justify-content-between mx-3'>"+
				"<div>Jumlah Terapi</div>"+
				"<div><b>"+UFunc.formatAngka(uItemsArr.length)+"</b> Terapi</div>"+
			"</div>"+
			"<div class='d-flex justify-content-between mx-3'>"+
				"<div>Nilai Terapi</div>"+
				"<div class='text-success'>Rp "+UFunc.formatAngka(uDatamainObj.nilai_bruto)+"</div>"+
			"</div>"+
			(parseInt(uDatamainObj.nilai_diskon) <=0 ? "" 
				: "<div class='d-flex justify-content-between mx-3 text-danger'>"+
				"<div>Diskon</div>"+
				"<div class=''>Rp "+UFunc.formatAngka(uDatamainObj.nilai_diskon)+"</div>"+
				"</div>"+
				"")+
			
			"<hr class='border-none border-botom' />"+
			"<div class='d-flex justify-content-between mx-3 fw-bolder'>"+
				"<big>Nilai TOTAL</big>"+
				"<big class='text-success'>Rp "+UFunc.formatAngka(uDatamainObj.nilai_netto)+"</big>"+
			"</div>"+

			"<br/><br/>"+
			"<center>Apakah Yakin Akan Menyimpan ?</center>"+
			""))) apiSimpanmain()
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const initResetmain = () => {
		uDispatch({type: "set", gBillingperiksaObj: undefined});
		setTambahitemObj({})
		setTabelitemObj({})
		setEfekview(!uEfekview);
	}
	const initResettambahform = () => {//-->RESET_SEMUA_KECUALI_CAPTION--/
		uTambahitemObj.id 				= undefined;
		uTambahitemObj.terapi_tarif 	= undefined;
		uTambahitemObj.status_garansi 	= undefined;
		uTambahitemObj.terapi_main 		= undefined;
		uTambahitemObj.terapi_qty 		= undefined;
		setEfekview(!uEfekview)
	}
	const lookupPilihdokter = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM);

		uDatamainObj.id_dokter 		= uDatadokterArr[vArridx].id;
		uDatamainObj.nama_dokter 	= uDatadokterArr[vArridx].dokter_caption;
		setUpdatefield(!uUpdatefield)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inpanamnasecaption");
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihdokterjanjian = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatadokterArr,_IDITEM);

		uDlgformdataObj.dokter_id 		= uDatadokterArr[vArridx].id;
		uDlgformdataObj.dokter_caption 	= uDatadokterArr[vArridx].dokter_caption;
		setUpdatefield(!uUpdatefield)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inpjamawal");
			if(!isMobile) vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihanamnase = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDataanamnaseArr,_IDITEM);

		uDatamainObj.anamnase_id 	= uDataanamnaseArr[vArridx].id;
		uDatamainObj.anamnase 		= uDataanamnaseArr[vArridx].anamnase_caption;
		setUpdatefield(!uUpdatefield)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inptambahitemdiagnosa");
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihcatdokter = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatacatdokterArr,_IDITEM);

		uDatamainObj.note_dokter 	= uDatacatdokterArr[vArridx].note_caption||"";
		setUpdatefield(!uUpdatefield)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inpcatatantambahan");
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihterapi = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDataterapiArr,_IDITEM);

		uTambahitemObj.id 				= uDataterapiArr[vArridx].id;
		uTambahitemObj.terapi_caption 	= uDataterapiArr[vArridx].terapi_caption;
		uTambahitemObj.terapi_tarif 	= uDataterapiArr[vArridx].terapi_tarif;
		uTambahitemObj.status_garansi 	= uDataterapiArr[vArridx].status_garansi;
		uTambahitemObj.terapi_main		= (uDataterapiArr[vArridx].terapi_main);
		uTambahitemObj.terapi_qty 		= 1;
		setEfekview(!uEfekview)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inptambahitemqty");
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihdiagnosa = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vISTambah	= JSON.stringify(uTabelitemObj||{})==="{}";
		const vArridx	= UFunc.getObjectIndeks(uDatadiagnosaArr,_IDITEM);

		let vElfocus;
		if(vISTambah) {
			vElfocus = document.getElementById("inptambahitemdiagnosaket");
			uTambahitemObj.diagnosa_id		= uDatadiagnosaArr[vArridx].id;
			uTambahitemObj.diagnosa_caption	= uDatadiagnosaArr[vArridx].nama_diagnosa;
		} else {
			uTabelitemObj.diagnosa_id		= uDatadiagnosaArr[vArridx].id;
			uTabelitemObj.diagnosa_caption	= uDatadiagnosaArr[vArridx].nama_diagnosa;
			vElfocus = document.getElementById("inptabelitemdiagnosaket");
		}
		setEfekview(!uEfekview)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupPilihFormdiagnosa = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDatadiagnosaArr,_IDITEM);
		uDlgformdataObj.diagnosa_id		= uDatadiagnosaArr[vArridx].id;
		uDlgformdataObj.diagnosa_caption= uDatadiagnosaArr[vArridx].nama_diagnosa;
		setEfekview(!uEfekview);

		const vElfocus = document.getElementById("inpformitemdiagnosaketerangan");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			vElfocus && vElfocus.focus()
		},350)
	}
	const lookupFormterapi = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uDataterapiArr,_IDITEM);

		uDlgformdataObj.terapi_sub_id	= uDataterapiArr[vArridx].id;
		uDlgformdataObj.terapi_caption 	= uDataterapiArr[vArridx].terapi_caption;
		uDlgformdataObj.terapi_tarif 	= uDataterapiArr[vArridx].terapi_tarif;
		uDlgformdataObj.status_garansi 	= uDataterapiArr[vArridx].status_garansi;
		uDlgformdataObj.terapi_main		= (uDataterapiArr[vArridx].terapi_main);
		uDlgformdataObj.terapi_qty		= 1;
		setEfekview(!uEfekview)

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			const vElfocus = document.getElementById("inpformitemqty");
			vElfocus && vElfocus.focus()
		},350)
	}
	const initKlikformjanjian = () => {
		let vElfocus = document.getElementById("inpformcabangid");
		if(parseInt(uDlgformdataObj.id_cabang||"0") <= 0) {
			vElfocus && vElfocus.focus()
			showToast("Cabang Tidak Valid !"); return
		}
		vElfocus = document.getElementById("inptglantri");
		if((uDlgformdataObj.tgl_antri||"") === "") {
			vElfocus && vElfocus.focus()
			showToast("Tgl Antrian Tidak Valid !"); return
		}
		if(uDlgformdataObj.tgl_antri === UFunc.DbDate()) {
			vElfocus && vElfocus.focus()
			showToast("Tanggal Antrian Hanya Dapat Tanggal SETELAH Hari Ini !");
			return
		}
		const vTglantriDT = UFunc.toDate(uDlgformdataObj.tgl_antri);
		if(UFunc.DateIsMundur(vTglantriDT)) {
			vElfocus && vElfocus.focus()
			showToast("Tgl Antrian Sudah Kadaluwarsa !"); return
		}
		//--CEK_SUDAH_ADA_DIDATA_LOKAL--/
		const vFilterTglExistArr = uJanjianArr.filter(vItems=>{
			return ((parseInt(vItems.id_antrian)||0) <= 0 
				&& vItems.tgl_antri === uDlgformdataObj.tgl_antri
				)
		});
		if(vFilterTglExistArr.length > 0) {
			vElfocus && vElfocus.focus()
			showToast("Tgl Antrian Tersebut Sudah Terdaftar !"); return
		}
		//--END CEK_SUDAH_ADA_DIDATA_LOKAL--/

		vElfocus = document.getElementById("inpjanjiandoktercaption");
		if(parseInt(uDlgformdataObj.dokter_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Dokter Rujukan Harus Dipilih.."+
				"<br/>Mohon Diperbaiki !"+
				""); return
		}

		vElfocus = document.getElementById("inpjamawal");
		if((uDlgformdataObj.jam_awal||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Antrian Merupakan Antrian JANJIAN.."+
				"<br/>Jam Janjian AWAL Harus Diisi !"+
				""); return;
		}

		vElfocus = document.getElementById("inpjamakhir");
		if((uDlgformdataObj.jam_akhir||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Jenis Antrian Merupakan Antrian JANJIAN.."+
				"<br/>Jam Janjian AKHIR Harus Diisi !"+
				""); return
		}

		const vTimeAwalDT	= UFunc.toDatetime(uDlgformdataObj.tgl_antri+" "+uDlgformdataObj.jam_awal+":00");
		const vTimeAkhirDT	= UFunc.toDatetime(uDlgformdataObj.tgl_antri+" "+uDlgformdataObj.jam_akhir+":00");

		if(vTimeAwalDT > vTimeAkhirDT) {
			vElfocus && vElfocus.focus();
			showToast("Jam Janjian AWAL Lebih Besar dari Jam Janjian AKHIR !"); return
		}
		vElfocus = document.getElementById("inpnoruang");
		if((parseInt(uDlgformdataObj.no_ruang)||0) <= 0
			|| (parseInt(uDlgformdataObj.no_ruang)||0) > (parseInt(uCabanginitObj.jml_ruangan)||2)) {
			vElfocus && vElfocus.focus();
			showToast("No Ruangan Tidak Valid ! "); return
		}

		apiJanjianchecking();
	}
	const initKlikformtanggal = () => {
		let vElfocus = document.getElementById("inptglperiksa");
		if((uDlgformdataObj.tgl_periksa||"") === "") {
			vElfocus && vElfocus.focus()
			showToast("Tgl Periksa Tidak Valid !"); return
		}

		uDatamainObj.tgl_periksa = uDlgformdataObj.tgl_periksa;
		setUpdatefield(!uUpdatefield);
		setDlgformshow(false);
	}
	const initKlikformitem = () => {
		const vTerapiID = parseInt(uDlgformdataObj.terapi_sub_id)||0;
		if(vTerapiID <= 0) return;

		const vArridx = uDlgformdataObj.idxtabel===undefined 
			? -1 : parseInt(uDlgformdataObj.idxtabel);
		if(vArridx >= 0) {
			uItemsArr[vArridx].diagnosa_id 	= uDlgformdataObj.diagnosa_id;
			uItemsArr[vArridx].diagnosa_caption = (uDlgformdataObj.diagnosa_caption||"").trim();
			uItemsArr[vArridx].diagnosa_keterangan = (uDlgformdataObj.diagnosa_keterangan||"").trim();
			uItemsArr[vArridx].tindakan 	= UFunc.nl2br((uDlgformdataObj.tindakan||"").trim());
			uItemsArr[vArridx].keterangan  	= (uDlgformdataObj.ketgigi||"").trim();
			uItemsArr[vArridx].status_ada_garansi = (uDlgformdataObj.status_garansi||"TIDAK");
			uItemsArr[vArridx].qty_terapi 	= uDlgformdataObj.terapi_qty||1;

			hdlToggleDlgform();
			return;
		}

		const vTerapimain = uDlgformdataObj.terapi_main || "";
		const vTmpObj = {
			id_terapi_sub : vTerapiID,
			caption_terapi : uDlgformdataObj.terapi_caption || "",
			tarif_terapi: uDlgformdataObj.terapi_tarif || "0",
			qty_terapi: uDlgformdataObj.terapi_qty || 0,
			diagnosa_id: uDlgformdataObj.diagnosa_id || 0,
			diagnosa_caption : (uDlgformdataObj.diagnosa_caption||"").trim(),
			diagnosa_keterangan : (uDlgformdataObj.diagnosa_keterangan||"").trim(),
			tindakan : UFunc.nl2br((uDlgformdataObj.tindakan||"").trim()),
			keterangan : (uDlgformdataObj.ketgigi||"").trim(),
			status_ada_garansi : (uDlgformdataObj.status_garansi||"TIDAK"),
			tgl_batas_garansi : "",
		}

		uDatamainObj.items_arr = [vTmpObj,...uItemsArr];

		const vTmpresetObj = {
			jenis: uDlgformdataObj.jenis,
			terapi_caption : vTerapimain,
		}
		setDlgformdataObj(vTmpresetObj);
		showToast(uBahasaObj.pesan_sukses,"SUKSES");
		//setEfekview(!uEfekview);
	}
	const initKlikformperawat = () => {
		/*if((uDlgformdataObj.perawatid_arr||[]).length <= 0) {
			showToast("Perawat Belum Ada yg Dipilih !"); return
		}//-*/

		uDatamainObj.perawat_arr = uDataperawatArr.filter(vItems => 
			(uDlgformdataObj.perawatid_arr||[]).includes(vItems.id)
			) || [];
		setUpdatefield(!uUpdatefield);
		setDlgformshow(false);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentHeadermain = () => {
		if(uHtml500msg || uHeaderloading) 
			return (<b>{uBahasaObj.caption_billingperiksa||"Billing Periksa"}</b>)//>

		return (
		<>
		<b className="d-none d-lg-inline">{uBahasaObj.caption_billingperiksa||"Billing Periksa"}: </b>
		<span className="text-info fw-bolder d-none d-md-inline">{uDatapasienObj.pasien_caption||"UNDF"}</span>
		<span className="text-info fw-bolder d-md-none">{UFunc.Usercaptionfilter(uDatapasienObj.pasien_caption)}</span>
		<span className="mx-1 d-none d-md-inline">&middot;</span>
		<b className="d-none d-md-inline" style={{color:((uDatapasienObj.sex||"L")==="P"?"pink":"navy")}}>
			[<span>{uDatapasienObj.sex||"L"}</span>]
		</b>

		{(uDatapasienObj.asuransi_caption||"")!=="" && (
			<span className="classfontsmaller d-none d-md-inline">
			<span className="mx-1">&middot;</span>
				<CBadge color="light" className="" style={{border:"1px solid purple"}}>
				<small className="fst-italic" style={{color:"purple"}}>{uDatapasienObj.asuransi_caption}</small>
				</CBadge>
			</span>
		)}
		</>
		)//>
	}
	const contentHeadersub = () => {
		if(uHeaderloading) return (<div className="text-primary classblinking ps-3">Loading..</div>)//>
		if(uHtml500msg) return "";

		const vTgllahirDT 	= (uDatapasienObj.tgl_lahir||"")===""?null:UFunc.toDate(uDatapasienObj.tgl_lahir);
		const vTglnowDT		= new Date();
		const vISBirthdaytoday = vTgllahirDT 
			? (vTgllahirDT.getDate() === vTglnowDT.getDate() &&
				vTgllahirDT.getMonth() === vTglnowDT.getMonth()) : false;

		return (
		<small>
		<div>
			{(uDatapasienObj.pekerjaan_caption||"")!=="" && (
			<>
				<b className="text-primary">{uDatapasienObj.pekerjaan_caption}</b>
				<span className="mx-2">&middot;</span>
			</>
			)}

			{(uDatapasienObj.tgl_lahir||"")!=="" && (
			<>
				({uDatapasienObj.umur||"0th"} - {UFunc.TglAngka(uDatapasienObj.tgl_lahir)}
				{vISBirthdaytoday && (
				<>
					<span className="ms-1">&middot;</span>
					<CTooltip className="d-none d-lg-block" content="--Pasien Berulang Tahun Hari Ini--">
					<CImage src={pjson.homepage+"api/images/icon_birthday.png"} className="classblinking" height={30}/>
					</CTooltip>
				</>
				)}
				)
				<span className="mx-2">&middot;</span>
			</>
			)}

			{(uDatapasienObj.kondisi_khusus||"").length >= 3 && (
			<>
			<span className="classblinking text-success">{uDatapasienObj.kondisi_khusus}</span>
			<span className="mx-2">&middot;</span>
			</>
			)}

			{(uDatamainObj.progress_keterangan||"")!=="" && (
			<small className="fst-italic text-danger">
				Ini untuk keterangan
			</small>
			)}
		</div>
		
		{(((uDatapasienObj.pekerjaan_caption||"")!=="" ||
			(uDatapasienObj.tgl_lahir||"")!=="" || 
			(uDatamainObj.progress_keterangan||"")!=="" || 
			(uDatapasienObj.kondisi_khusus||"").length >= 3) && 
			(uDatapasienObj.alergi||"").length >= 3) && (
			<div className="classborderbottom mt-1"/>
		)}

		{(uDatapasienObj.alergi||"").length >= 3 && (
		<>
		<div className="fst-italic text-warning">
			<marquee>&middot;&middot; Alergi: {uDatapasienObj.alergi} &middot;&middot;</marquee>
		</div>
		</>
		)}
		</small>
		)//>
	}
	const contentTglPeriksa = () => {
		if(uHtml500msg) return "";

		if(!uISAdmininit) return (
		<center className="d-block w-100 py-3">
		<span className="text-primary">{UFunc.HariAngka(uDatamainObj.tgl_periksa)}</span>
		</center>
		)//>

		return (
		<div className="d-block w-100">
		<small>
		<div className="text-center">
		<span className="text-primary">{UFunc.HariAngka(uDatamainObj.tgl_periksa)}</span>
		</div>

		<div className="my-1 classborderbottom"/>

		<div className="text-center">
		<CTooltip className="d-none d-lg-block" content="--Klik untuk Edit">
		<CLink onClick={()=>hdlKlikPilihtanggal()} className="classikontabel classikonedit classcursorpointer"/>
		</CTooltip>
		</div>
		</small>
		</div>
		)//>
	}
	const contentDokter = () => {
		if(uHtml500msg) return "";

		if((uDatamainObj.nama_dokter||"")==="") return (
			<center className="d-block w-100">
			<CTooltip className="d-none d-lg-block" content="--Pilih Dokter..">
			<CLink onClick={()=>hdlKlikDokterlookup()} className="classcursorpointer">
			<CImage src={pjson.homepage+"api/images/menus_dokter.png"} className="classikonbox" height={35}/>
			</CLink>
			</CTooltip>
			</center>
		)//>

		return (
		<div className="d-block w-100">
		<small>
		<div className="text-center">
		<span className="text-primary">{UFunc.Usercaptionfilter(uDatamainObj.nama_dokter,3)}</span>
		</div>

		<div className="my-1 classborderbottom"/>

		<div className="text-center">
		<CTooltip className="d-none d-lg-block" content="--Klik Untuk Edit">
		<CLink onClick={()=>hdlKlikDokterlookup()} 
			className="classcursorpointer classikontabel classikonedit"/>
		</CTooltip>

		<CTooltip className="d-none d-lg-block" content="--Reset Dokter">
		<CLink onClick={async()=>{
			if(await(Konfirm("Apakah Yakin Mereset Dokter ?"))) {
				uDatamainObj.nama_dokter 	= undefined;
				uDatamainObj.id_dokter		= 0;
				setUpdatefield(!uUpdatefield);
			}
			}}
			className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox mx-0 text-danger" height={23}/>
		</CLink>
		</CTooltip>
		</div>
		</small>
		</div>
		)//->
	}
	const contentAnamnase = () => {
		if(uHtml500msg) return "";

		if((uDatamainObj.anamnase||"")==="") return (
			<center className="d-block w-100">
			<CTooltip className="d-none d-lg-block" content="--Pilih Anamnase..">
			<CLink onClick={()=>hdlKlikAnamnaselookup()} className="classcursorpointer">
			<CImage src={pjson.homepage+"api/images/menus_anamnesa.png"} className="classikonbox" height={35}/>
			</CLink>
			</CTooltip>
			</center>
		)//>

		return (
		<div className="d-block w-100">
		<small>
		<div className="text-center">
		<span className="text-primary">{uDatamainObj.anamnase}</span>
		</div>

		<div className="my-1 classborderbottom"/>

		<div className="text-center">
		<CTooltip className="d-none d-lg-block" content="--Klik Untuk Edit">
		<CLink onClick={()=>hdlKlikAnamnaselookup()} 
			className="classcursorpointer classikontabel classikonedit"/>
		</CTooltip>

		<CTooltip className="d-none d-lg-block" content="--Reset Anamnase">
		<CLink onClick={async()=>{
			if(await(Konfirm("Apakah Yakin Mereset Anamnase ?"))) {
				uDatamainObj.anamnase 	= undefined;
				setUpdatefield(!uUpdatefield);
			}
			}}
			className="classcursorpointer">
				<CIcon icon="cilSync" className="classikonbox mx-0 text-danger" height={23}/>
		</CLink>
		</CTooltip>
		</div>
		</small>
		</div>
		)//->
	}
	const contentPerawat = () => {
		if(uHtml500msg) return "";

		if((uPerawatArr).length <= 0) return (
			<center className="d-block w-100">
			<CTooltip className="d-none d-lg-block" content="--Pilih Perawat..">
			<CLink onClick={()=>hdlKlikPerawattambah()} className="classcursorpointer">
			<CImage src={pjson.homepage+"api/images/menus_perawat.png"} className="classikonbox" height={35}/>
			</CLink>
			</CTooltip>
			</center>
		)//>

		return (
		<div className="d-block w-100">
		<small>
		<div className="text-center">
			{uPerawatArr.map((vItems,vKeys)=>{
				return (
				<>
				{vKeys > 0 && (
					<span className="mx-1">&middot;</span>
				)}
				<span key={vKeys} className="text-primary fst-italic">{vItems.perawat_caption||""}</span>
				</>
				)
			})}
		</div>

		<div className="my-1 classborderbottom"/>

		<div className="text-center">
		<CTooltip className="d-none d-lg-block" content="--Klik Untuk Edit">
		<CLink onClick={()=>hdlKlikPerawattambah()} 
			className="classcursorpointer classikontabel classikonedit"/>
		</CTooltip>

		<CTooltip className="d-none d-lg-block" content="--Reset Perawat">
			<CLink className="classcursorpointer"
				onClick={()=>hdlKlikPerawatclear()}>
				<CIcon icon="cilSync" className="classikonbox mx-0 text-danger" height={23}/>
			</CLink>
		</CTooltip>
		</div>
		</small>
		</div>
		)//>
	}
	const contentPeriksamobile = () => {
		if(uHtml500msg) return "";

		return (
		<CForm>
		<CRow className="">
		<CCol xs="4" className="">Tgl Periksa</CCol>
		<CCol className="">
		{(uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN") ? (
			<>
			<CFormInput	size="sm" type="date"
				value={uDatamainObj.tgl_periksa||""}
				onChange={(e)=>{uDatamainObj.tgl_periksa=e.target.value;setUpdatefield(!uUpdatefield)}}
				id="inptglperiksa"/>
			<div className="d-none">tgl_periksa= {uDatamainObj.tgl_periksa}</div>
			</>
		) : (
			<b className="text-primary">{UFunc.HariAngka(uDatamainObj.tgl_periksa)}</b>
		)}
		</CCol>
		</CRow>

		{uTokenObj.userhak !== "DOKTER" && (
		<CRow className="my-1">
		<CCol xs="4" className="">Dokter</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput	size="sm"
				readOnly={true}
				onClick={()=>hdlKlikDokterlookup()}
				value={uDatamainObj.nama_dokter||""}
				onChange={(e)=>{uDatamainObj.nama_dokter=e.target.value;setUpdatefield(!uUpdatefield)}}
				placeholder={"--"+(uBahasaObj.caption_pilihdokter||"Pilih Dokter").toUpperCase()+".."}
				id="inpdoktercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikDokterlookup()}
				id="btndokterlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		)}

		<CRow className="my-1">
		<CCol xs="4" className="">Anamnase</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput	size="sm"
				readOnly={true}
				onClick={()=>hdlKlikAnamnaselookup()}
				placeholder={"--"+(uBahasaObj.caption_pilihanamnase||"Pilih Anamnase").toUpperCase()+".."}
				value={uDatamainObj.anamnase||""}
				onChange={(e)=>{uDatamainObj.anamnase=e.target.value;setUpdatefield(!uUpdatefield)}}
				id="inpanamnasecaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikAnamnaselookup()}
				id="btnanamnaselookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)//>
	}
	const contentFormheader = () => {
		if(uHtml500msg) return "";

		return (
		<div className="justify-content-center justify-content-lg-start" style={{display: "flex",flexWrap: "wrap"}}>
			<div className="pe-2 pe-lg-3 d-flex" 
				style={{minWidth:115,width:(Math.floor(100/uJmlcarddataheader))+"%"}}>
			<CCard className="classbgcard mb-3 w-100" id="idcardtanggal">
			<CCardHeader className="py-0 classfontsmaller">
				<small>Tgl Periksa</small>
			</CCardHeader>
			<CCardBody className="d-flex align-items-center p-1">{contentTglPeriksa()}</CCardBody>
			</CCard>
			</div>

			{uISAdmininit && (
			<div className="pe-2 pe-lg-3 d-flex" 
				style={{minWidth:115,width:(Math.floor(100/uJmlcarddataheader))+"%"}}>
			<CCard className="classbgcard mb-3 w-100" id="idcarddokter">
			<CCardHeader className="py-0 classfontsmaller">
				<small>Dokter</small>
			</CCardHeader>
			<CCardBody className="d-flex align-items-center p-1">{contentDokter()}</CCardBody>
			</CCard>
			</div>
			)}

			<div className="pe-2 pe-lg-3 d-flex" 
				style={{minWidth:115,width:(Math.floor(100/uJmlcarddataheader))+"%"}}>
			<CCard className="classbgcard mb-3 w-100" id="idcardanamnase">
			<CCardHeader className="py-0 classfontsmaller">
				<small>Anamnase</small>
			</CCardHeader>
			<CCardBody className="d-flex align-items-center p-1">{contentAnamnase()}</CCardBody>
			</CCard>
			</div>

			<div className="d-flex" 
				style={{minWidth:115,width:(Math.floor(100/uJmlcarddataheader)*2)+"%"}}>
			<CCard className="classbgcard mb-3 w-100" id="idcardperawat">
			<CCardHeader className="py-0 classfontsmaller">
				<small>Perawat</small>
			</CCardHeader>
			<CCardBody className="d-flex align-items-center p-1">{contentPerawat()}</CCardBody>
			</CCard>
			</div>
		</div>
		)//>
	}
	const contentFormheadermobile = () => {
		if(uHtml500msg) return "";

		return (
		<>
			<CCard className="classbgcard mb-3" id="idcardanamnase">
			<CCardHeader className="py-1 classfontsmaller"><small>Data Periksa</small></CCardHeader>
			<CCardBody className="">{contentPeriksamobile()}</CCardBody>
			</CCard>

			<CCard className="classbgcard mb-3" id="idcardperawat">
			<CCardHeader className="py-1 classfontsmaller">
				<small>Data Perawat</small>
			</CCardHeader>
			<CCardBody className="">{contentPerawat()}</CCardBody>
			</CCard>
		</>
		)//>
	}
	const contentPerawatX = () => {//--TIDAK_DIPAKAI--/
		if(uHtml500msg) return (<></>)//>

		return (
		<small>
		<div className="m-0 py-1 mb-2 text-center classborderbottom d-none">
			<CTooltip className="d-none d-lg-block" content="--Pilih Perawat">
			<CLink className="classcursorpointer classikontabel classikontambah"
				onClick={()=>hdlKlikPerawattambah()}/>
			</CTooltip>
			<span className="mx-1">&middot;</span>
			<CTooltip className="d-none d-lg-block" content="--Bersihkan Semua">
			<CLink className="classcursorpointer classikontabel classikonhapus"
				onClick={()=>hdlKlikPerawatclear()}/>
			</CTooltip>
		</div>

		{uPerawatArr.length <= 0 ? (
		<div
			className="classpetunjuk text-center"
			style={{fontSize:"100%"}}>&middot; Belum Ada Perawat yang Dipilih &middot;</div>
		) : (
		<div className=" text-center">
			<span className="mx-1">&middot;</span>
			{uPerawatArr.map((vItems,vKeys)=>{
				return (
				<>
				<span key={vKeys} className="text-primary fst-italic">{vItems.perawat_caption||""}</span>
				{vKeys < uPerawatArr.length-1 && (
					<span className="mx-1">&middot;</span>
				)}
				</>
				)
			})}
			<span className="mx-1">&middot;</span>
		</div>
		)}
		</small>
		)//>
	}
	const contentAnamnaseX = () => {//--TIDAK_DIPAKAI--/
		if(uHtml500msg) return "";

		return (
		<>
		<CRow className="">
		<CCol xs="4" className="">Tgl Periksa</CCol>
		<CCol className="text-end">
		{((uTokenObj.userhak === "FREEROLE" || uTokenObj.userhak === "ADMIN")
			&& (parseInt(uDatamainObj.progress_id)||0) <= 0
		) ? (
			<>
			<CFormInput	size="sm" type="date"
				value={uDatamainObj.tgl_periksa||""}
				onChange={(e)=>{uDatamainObj.tgl_periksa=e.target.value;setUpdatefield(!uUpdatefield)}}
				id="inptglperiksa"/>
			<div className="d-none">tgl_periksa= {uDatamainObj.tgl_periksa}</div>
			</>
		) : (
			<div className="mb-1">
			<b className="text-primary">{UFunc.HariAngka(uDatamainObj.tgl_periksa)}</b>
			</div>
		)}
		</CCol>
		</CRow>

		{uTokenObj.userhak !== "DOKTER" && (
		<CRow className="my-1">
		<CCol xs="4" className="">Dokter</CCol>
		<CCol className="text-end">
			{(parseInt(uDatamainObj.progress_id)||0) <= 0 ? (
			<CInputGroup size="sm">
			<CFormInput	size="sm"
				readOnly={true}
				onClick={()=>hdlKlikDokterlookup()}
				value={uDatamainObj.nama_dokter||""}
				onChange={(e)=>{uDatamainObj.nama_dokter=e.target.value;setUpdatefield(!uUpdatefield)}}
				placeholder={"--"+(uBahasaObj.caption_pilihdokter||"Pilih Dokter").toUpperCase()+".."}
				id="inpdoktercaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikDokterlookup()}
				id="btndokterlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			) : (
			<div className="mb-1">
				<b className="text-primary">{(uDatamainObj.nama_dokter)}</b>
			</div>
			)}
		</CCol>
		</CRow>
		)}

		<CRow className="my-1">
		<CCol xs="4" className="">Anamnase</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput	size="sm"
				readOnly={true}
				onClick={()=>hdlKlikAnamnaselookup()}
				placeholder={"--"+(uBahasaObj.caption_pilihanamnase||"Pilih Anamnase").toUpperCase()+".."}
				value={uDatamainObj.anamnase||""}
				onChange={(e)=>{uDatamainObj.anamnase=e.target.value;setUpdatefield(!uUpdatefield)}}
				id="inpanamnasecaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikAnamnaselookup()}
				id="btnanamnaselookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>
		</>
		)//>
	}
	const contentTabelform = () => {
		if(uHtml500msg) return (<></>)//>

		if(isMobile) return (
			<CButton size="sm" color="secondary"
				className="text-white"
				onClick={()=>hdlKlikTambahitemmobile()}
				id="btnFormitem">
				<CIcon icon="cibAddthis" className="me-1 align-middle"/>
				Tambah Terapi
			</CButton>
		)//>

		return (
		<CRow className="text-light">
		<CCol md="10" className="d-flex">
		<div style={{width:"3%",minWidth:50}} className="text-start px-1">
			<CImage src={pjson.homepage+"api/images/icon_new.png"} height={30}/>
		</div>

		<div style={{width:"97%"}} className="px-1">
			<div className="d-flex align-items-start">
				<div style={{minWidth:350}} className="w-50">
				<CInputGroup size="sm">
				<CFormInput size="sm" maxLength={255}
					readOnly={true}
					value={uTambahitemObj.diagnosa_caption||""}
					placeholder={"--"+(uBahasaObj.word_diagnosa||"Diagnosa")+".."}
					onClick={()=>hdlKlikDiagnosalookup()}
					id="inptambahitemdiagnosa"/>
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikDiagnosalookup()}
					id="btntambahitemdiagnosalookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>

				<CFormInput size="sm" maxLength={100}
					className="mt-2"
					value={uTambahitemObj.diagnosa_keterangan||""}
					onChange={(e)=>{uTambahitemObj.diagnosa_keterangan=e.target.value;setEfekview(!uEfekview)}}
					placeholder="--Keterangan Diagnosa.."
					id="inptambahitemdiagnosaket"/>
				</div>

				<span className="mx-1">&middot;</span>

				<div className="w-50">
				<CInputGroup className="" size="sm">
				<CFormTextarea size="sm" rows={3}
					className="classfontsmaller py-1"
					value={uTambahitemObj.tindakan||""}
					onChange={(e)=>{uTambahitemObj.tindakan=e.target.value;setEfekview(!uEfekview);}}
					placeholder={"--"+(uBahasaObj.word_tindakan||"Tindakan")+".."}
					id="inptambahitemtindakan"/>
				</CInputGroup>
				<div className="classpetunjuk text-warning"><small>*) {uBahasaObj.petunjuk_textarea}</small></div>
				</div>

				<div className="d-md-none" style={{minWidth:100}}/>
			</div>

			<div className="classborderbottom my-2"/>

			<div className="d-flex align-items-start">
				<div style={{minWidth:350,width:"50%"}}>
				<CInputGroup size="sm">
				<CFormInput size="sm"
					value={uTambahitemObj.terapi_caption||""}
					onKeyDown={(e)=>hdlKDownTerapicaption(e)}
					onChange={(e)=>{uTambahitemObj.terapi_caption=e.target.value;setEfekview(!uEfekview)}}
					placeholder={"--"+(uBahasaObj.caption_keywordterapi||"Masukkan Keyword Terapi").toUpperCase()+".."}
					id="inptambahitemcaption"/>
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikTerapilookup()}
					id="btntambahitemlookup">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>
				<div className="classpetunjuk text-warning"><small>Terapi</small></div>
				</div>

				<div className="mx-2" style={{minWidth:70,width:"10%"}}>
				<CInputGroup size="sm">
				<CFormInput size="sm" maxLength={3}
					type="number"
					className="text-end"
					value={uTambahitemObj.terapi_qty||0}
					onChange={(e)=>{uTambahitemObj.terapi_qty=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					id="inptambahitemqty"/>
				</CInputGroup>
				<div className="classpetunjuk text-warning"><small>UNT</small></div>
				</div>

				<CInputGroup style={{minWidth:100,width:"30%"}} size="sm">
				<CFormInput size="sm" maxLength={255}
					placeholder={"--"+(uBahasaObj.caption_ketgigi||"Ket.Gigi")+".."}
					value={uTambahitemObj.ketgigi||""}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btntambahitemadd")}
					onChange={(e)=>{uTambahitemObj.ketgigi=e.target.value;setEfekview(!uEfekview)}}
					id="inptambahitemketgigi"/>
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikketgigiview()}
					id="btntambahitemketgigi">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				</CInputGroup>

				<div className="d-md-none" style={{minWidth:100}}/>
			</div>
		</div>
		</CCol>

		<CCol md="2" className="text-end">
			<div className="text-success fw-bolder pb-3">Rp{UFunc.formatAngka(uTambahitemObj.terapi_tarif)}</div>
			<CButton size="sm" color="secondary"
				className="px-2 px-lg-4 fw-bolder text-white"
				onClick={()=>hdlKlikTambahitem()}
				id="btntambahitemadd">
				<CIcon icon="cibAddthis"/>{" "}Tambah
			</CButton>
		</CCol>
		</CRow>
		)//>
	}
	const contentTabelitem = () => {
		if(uHtml500msg) return "";

		return (
		<>
		{uItemsArr.map((vItems,vKeys)=>{
			const {
				id_terapi_sub,caption_terapi,tarif_terapi,qty_terapi,
				diagnosa_caption,diagnosa_id,diagnosa_keterangan,tindakan,keterangan,
				status_ada_garansi,tgl_batas_garansi
			} = vItems;

			const vIdxedit 		= uTabelitemObj.tabelidx===undefined
				? -1 : parseInt(uTabelitemObj.tabelidx);

			const vBgColor 		= vKeys%2===0 ? "#F0F0F0" : "#FFFFFF";
			const vBordercolor  = vKeys%2===0 ? "silver" : "gray"; 

			return (
			<div style={{backgroundColor:vBgColor,borderBottom:"1px dotted "+vBordercolor}}
				key={vKeys}>
			<CRow className="mx-0 py-2">
			<CCol md="9" lg="10" className="d-flex px-0">
				<div style={{width:25}} 
					className="text-end px-1 pe-0"><small>{(vKeys+1)}.</small></div>

				<div className="px-1"
					style={{minWidth:"50%",width:"calc(100% - 25px)",}}>
					<div className="d-none d-md-flex align-items-start">
					{(vIdxedit!==vKeys) ? (
					<>
						<div style={{width:"50%"}}>
							{(diagnosa_caption||"").trim()==="" ? (
								<small className="classpetunjuk px-0">{uBahasaObj.word_diagnosa||"Diagnosa"}: {"<EMPTY>"}</small>
							) : (
								<div className="text-capitalize">{diagnosa_caption}</div>
							)}

							{(diagnosa_keterangan||"")!=="" && (
							<div className="text-capitalize classfontsmaller fst-italic" style={{color:"gray"}}>
								<small>{diagnosa_keterangan}</small>
							</div>
							)}
						</div>

						<div className="text-center mx-2">&middot;</div>

						<div>
							{(tindakan||"").trim()==="" ? (
								<small className="classpetunjuk px-0">{uBahasaObj.word_tindakan||"Tindakan"}: {"<EMPTY>"}</small>
							) : (
								<span className="text-capitalize classfontsmaller" style={{color:"maroon"}}>
								<small>{UFunc.renderHTML(tindakan)}</small>
								</span>
							)}
						</div>
					</>
					) : (
					<>
						<div style={{width:"50%"}}>
						<CInputGroup size="sm">
						<CFormInput size="sm" maxLength={255}
							readOnly={true}
							value={uTabelitemObj.diagnosa_caption||""}
							onChange={(e)=>{uTabelitemObj.diagnosa_caption=e.target.value;setEfekview(!uEfekview)}}
							placeholder={"--"+(uBahasaObj.word_diagnosa||"Diagnosa")+".."}
							onClick={()=>hdlKlikDiagnosalookup(vKeys)}
							id={"inptabelitemdiagnosa"}/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikDiagnosalookup(vKeys)}
							id={"btntabelitemdiagnosalookup"}>
						<CIcon icon="cilSearch"/>
						</CButton>
						</CInputGroup>

						<CFormInput size="sm" maxLength={100}
							className="mt-2"
							value={uTabelitemObj.diagnosa_keterangan||""}
							onChange={(e)=>{uTabelitemObj.diagnosa_keterangan=e.target.value;setEfekview(!uEfekview)}}
							placeholder="--Keterangan Diagnosa.."
							id="inptabelitemdiagnosaket"/>
						</div>

						<div className="text-center mx-1">&middot;</div>

						<div style={{width:"50%"}} className="">
						<CFormTextarea size="sm" rows={3}
							className="classfontsmaller py-1"
							value={uTabelitemObj.tindakan||""}
							onChange={(e)=>{uTabelitemObj.tindakan=e.target.value;setEfekview(!uEfekview);}}
							placeholder={"--"+(uBahasaObj.word_tindakan||"Tindakan")+".."}
							id={"inptabelitemtindakan"}/>
						</div>
					</>
					)}
					</div>

					<div className="classborderbottom my-2"/>

					<div className="d-flex justify-content-between align-items-start">
						<div>
						{(vIdxedit!==vKeys) ? (
						<>
							<small className="">{caption_terapi||"UNDF"}
								<span className="mx-2">&middot;</span>
								(<b className="text-primary">{qty_terapi||1}</b>unt
								<span className="mx-1">x</span>
								<b className="text-primary">{UFunc.formatAngka(tarif_terapi)}</b>)
							</small>

							<div className="d-inline d-md-block">
							<span className="mx-1 d-md-none">&middot;</span>
							{(keterangan||"").trim()==="" ? (
								<span className="classpetunjuk px-0">
								<small>{uBahasaObj.caption_ketgigi||"Ket.Gigi"}: {"<EMPTY>"}</small>
								</span>
							) : (
								<span className="text-capitalize classfontsmaller">
								<small>{uBahasaObj.caption_ketgigi||"Ket.Gigi"}: <b>{keterangan}</b></small>
								</span>
							)}
							</div>
						</>
						) : (
						<>
							<div className="d-flex align-items-start">
							<small>{caption_terapi||"UNDF"}</small>
							<small className="me-1">:</small>
							<CInputGroup 
								style={{minWidth:100,width:"15%"}}
								size="sm">
							<CFormInput size="sm" type="number"
								className="ms-2 text-end"
								value={uTabelitemObj.qty_terapi||1}
								onChange={(e)=>{uTabelitemObj.qty_terapi=e.target.value;setEfekview(!uEfekview)}}
								onFocus={(e)=>e.target.select()}
								id="inptabelitemqty"/>
							<CInputGroupText>unt</CInputGroupText>
							</CInputGroup>

							<span className="mx-2">&middot;</span>
							<CInputGroup 
								style={{minWidth:200,width:"35%"}}
								size="sm">
							<CFormInput size="sm" maxLength={255}
								placeholder={"--"+(uBahasaObj.caption_ketgigi||"Ket.Gigi")+".."}
								value={uTabelitemObj.keterangan||""}
								onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnTabelsimpan"+vKeys)}
								onChange={(e)=>{uTabelitemObj.keterangan=e.target.value;setEfekview(!uEfekview)}}
								id={"inptabelketgigi"}/>
							<CButton size="sm" color="dark"
								onClick={()=>hdlKlikketgigiview(vKeys)}
								id={"btntabelitemketgigi"+vKeys}>
								<CIcon icon="cilMagnifyingGlass"/>
							</CButton>
							</CInputGroup>
							</div>
						</>
						)}
						</div>
					</div>
				</div>
			</CCol>

			<CCol md="3" lg="2" className="text-center text-md-end px-1 px-md-2">
				<div className="text-end text-success pb-2">
					{UFunc.formatAngka(parseInt(qty_terapi) * parseFloat(tarif_terapi))}
				</div>

					{(vIdxedit !== vKeys) ? (
					<div className="text-end">
						<CTooltip className="d-none d-lg-block" content={"--EDIT Item Terapi No-"+(vKeys+1)}>
						<CLink className="classcursorpointer classikontabel classikonedit"
							onClick={()=>hdlKlikTabeledit(vKeys)}/>
						</CTooltip>
						<span className="mx-1">&middot;</span>
						<CTooltip className="d-none d-lg-block" content={"--HAPUS Item Terapi No-"+(vKeys+1)}>
						<CLink className="classcursorpointer classikontabel classikonhapus"
							onClick={()=>hdlKlikTabelhapus(vKeys)}/>
						</CTooltip>
					</div>
					) : (
					<div className="text-end mt-2">
						<CButton size="sm" color="dark"
							className="fw-bold text-white"
							onClick={()=>hdlKlikTabelsimpan(vKeys)}
							id={"btnTabelsimpan"+vKeys}>
							<CIcon icon="cilCheckAlt"/> Simpan
						</CButton>
					</div>
					)}
			</CCol>
			</CRow>
			</div>
			)
		})}

		{(uItemsArr.length > 0) && (
		<>
		<CRow className="mx-0 py-2 pb-3 mt-1 bg-primary border border-top border-top-warning text-white">
			<CCol md="9" lg="10" className="text-start text-md-end fw-bolder pe-2">JUMLAH (Rp)</CCol>
			<CCol md="3" lg="2" className="ps-0 text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.nilai_bruto)}</CCol>
		</CRow>

		<CRow className="mx-0 py-2 bg-light border border-top border-top-dark">
			<CCol md="9" lg="10" className="text-start text-md-end text-danger pe-2">
				Diskon &middot; <small className="fst-italic">Max {parseInt(uSystemsettingObj.persen_diskonmax)||100}%</small> (Rp)
			</CCol>
			<CCol md="3" lg="2" className="text-end fw-bolder">
			<NumberFormat 
				value={uDatamainObj.nilai_diskon||"0"}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDatamainObj.nilai_diskon=values.value}
				renderText={values => (
					<CFormInput 
						value={values}
						size="sm"
						className="text-end"
						onChange={(e)=>{uDatamainObj.nilai_diskon=e.target.value;setUpdatefield(!uUpdatefield)}}
						onFocus={(e)=>e.target.select()}
					id="inpdiskon"/>
				)} 
			prefix=""/>
			</CCol>
		</CRow>
		</>
		)}
		</>
		)//->
	}
	const contentTabelitemmobile = () => {
		if(uHtml500msg) return "";

		return (
		<table className="table table-stripped" id="idtabelitemmobile">
		<thead>
			<tr>
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{uItemsArr.map((vItems,vKeys)=>{
			const {
				id_terapi_sub,caption_terapi,tarif_terapi,qty_terapi,
				diagnosa_caption,diagnosa_id,diagnosa_keterangan,
				tindakan,keterangan,
				status_ada_garansi,tgl_batas_garansi
			} = vItems;

			return (
			<tr id={"idtr"+vKeys} className="align-top text-end" key={vKeys}>
			<td className="px-1 pe-0">{vKeys+1}.</td>
			<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" className="px-1 text-start d-flex justify-content-between align-items-start">
					<div>
					{(diagnosa_caption||"")==="" ? (
					<div className="classpetunjuk px-0">Diagnosa {"<EMPTY>"}</div>
					) : (
					<div>{diagnosa_caption}</div>
					)}

					</div>

					<CDropdown className="ps-1">
					<CDropdownToggle color="transparent" className="p-0" caret={false}>
						<CIcon icon="cilChevronBottom" className="classikonbox align-middle mx-0" height={25}/>
					</CDropdownToggle>

					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikTabelEditmobile(vKeys)}>
						<CIcon className="me-2 align-middle classikonedit" height={25}/>Edit Data
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikTabelhapus(vKeys)}>
						<CIcon className="me-2 align-middle classikonhapus" height={25}/>Hapus Terapi
						</CDropdownItem>
					</CDropdownMenu>
					</CDropdown>
				</CCol>

				<CCol xs="12" className="px-1 my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start">
					<small>{uBahasaObj.caption_ketdiagnosa||"Ket.Diagnosa"}</small>
				</CCol>
				<CCol xs="8" className="px-1 text-end">
					<small className="fst-italic text-capitalize">{diagnosa_keterangan}</small>
				</CCol>

				<CCol xs="3" className="px-1 text-start" style={{color:"Maroon"}}>
					<small>{uBahasaObj.word_tindakan||"Tindakan"}</small>
				</CCol>
				<CCol xs="9" className="px-1 text-end" style={{color:"Maroon"}}>
					<small>{(tindakan||"-")}</small>
				</CCol>

				<CCol xs="12" className="px-1 my-3 classborderbottom"/>

				<CCol xs="12" className="px-1 text-start">
					<>{(caption_terapi||"-")}</>
				</CCol>
				<CCol xs="12" className="px-1 text-start">
					<small>
						(<b className="text-primary">{(qty_terapi)}</b>unt
						<span> x </span>
						<b className="text-primary">{UFunc.formatAngka(tarif_terapi)}</b>)
					</small>
				</CCol>

				<CCol xs="4" className="px-1 text-start">
					<small>{uBahasaObj.caption_ketgigi||"Ket.Gigi"}</small>
				</CCol>
				<CCol xs="8" className="px-1 text-end">
					<small className="fw-bolder text-capitalize">{(keterangan||"-")}</small>
				</CCol>

				<CCol xs="12" className="px-1 my-3 classborderbottom"/>

				<CCol xs="4" className="px-1 text-start text-success">
					Nilai (Rp)
				</CCol>
				<CCol xs="8" className="px-1 text-end fw-bolder text-success">
					{UFunc.formatAngka(parseFloat(tarif_terapi) * parseFloat(qty_terapi))}
				</CCol>
				</CRow>
			</td>
			</tr>
			)//->
		})}
		</tbody>

		{(uItemsArr.length > 0) && (
		<tfoot>
		<tr className="bg-primary text-white fw-bolder">
			<td className="px-1"/>
			<td className="px-1">
			<CRow className="mx-0">
			<CCol xs="6" className="text-start px-1">JUMLAH (Rp)</CCol>
			<CCol xs="6" className="px-1 text-end">{UFunc.formatAngka(uDatamainObj.nilai_bruto)}</CCol>
			</CRow>
			</td>
		</tr>
		<tr className="bg-light classborderbottom">
			<td className="px-1"/>
			<td className="px-1">
			<CRow className="mx-0">
			<CCol className="text-start px-1 text-danger classfontsmaller">
				Diskon &middot; <small>Max:{parseInt(uSystemsettingObj.persen_diskonmax)||0}%</small> (Rp)
			</CCol>
			<CCol xs="6" md="4" className="px-1 text-end">
			<NumberFormat 
				value={uDatamainObj.nilai_diskon||"0"}
				displayType={'text'} 
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDatamainObj.nilai_diskon=values.value}
				renderText={values => (
					<CFormInput 
						value={values}
						size="sm"
						className="text-end"
						onChange={(e)=>{uDatamainObj.nilai_diskon=e.target.value;setUpdatefield(!uUpdatefield)}}
						onFocus={(e)=>e.target.select()}
					id="inpdiskon"/>
				)} 
			prefix=""/>
			</CCol>
			</CRow>
			</td>
		</tr>
		</tfoot>
		)}
		</table>
		)//->
	}
	const contentNote = () => {
		if(uHtml500msg) return "";

		return (
		<small>
		<CRow className="my-1">
		<CCol xs="4" className="">
			<small className="d-md-none">Catatan Dokter</small>
			<span className="d-none d-md-block">Catatan Dokter</span>
		</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput	size="sm" type="search"
				placeholder={"--"+(uBahasaObj.caption_pilihcatdokter||"Pilih Catatan Dokter")+".."}
				readOnly={true}
				onClick={()=>hdlKlikCatdokterlookup()}
				value={uDatamainObj.note_dokter||""}
				onChange={(e)=>{uDatamainObj.note_dokter=e.target.value;setUpdatefield(!uUpdatefield)}}
				id="inpcatdokter"/>
			{(uDatamainObj.note_dokter||"")!=="" && (
			<CButton size="sm" color="light"
				className="text-danger"
				onClick={()=>{uDatamainObj.note_dokter="";setUpdatefield(!uUpdatefield)}}
				id="btncatdokterclear">
				<CIcon icon="cilXCircle"/>
			</CButton>
			)}
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikCatdokterlookup()}
				id="btncatdokterlookup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<div className="my-3 d-md-none classborderbottom"/>

		<CRow className="my-1">
		<CCol md="4" className="">Cat.Tambahan</CCol>
		<CCol className="text-end">
			<CFormInput	size="sm" maxLength={255}
				value={uDatamainObj.note_nota||""}
				onChange={(e)=>{uDatamainObj.note_nota=e.target.value;setUpdatefield(!uUpdatefield)}}
				className="text-capitalize"
				id="inpcatatantambahan"/>
		</CCol>
		</CRow>
		</small>
		)//>
	}
	const contentReservasi = () => {
		if(uHtml500msg) return "";

		return (
		<>
			<div className="text-center mb-1">
				<span>Janjian Berikutnya</span>
				<span className="mx-1">&middot;</span>
				<CTooltip className="d-none d-lg-block" content="--Input Janjian Berikutnya">
				<CLink className="classikon classikontambah classcursorpointer"
					onClick={()=>hdlKlikJanjiantambah()}/>
				</CTooltip>
			</div>
			
			<div className="my-1 classborderbottom"/>

			<div className="my-2">
				{uJanjianArr.length <= 0 ? (
					<div className="text-center">
					<small className="classpetunjuk">&middot; Belum ada Janjian Berikutnya &middot;</small>
					</div>
				) : (
				<ol className="ps-3">
					{uJanjianArr.map((vItems,vKeys)=>{
						const {
							id_cabang,id_antrian,no_ruang,tgl_antri,keterangan,jam_awal,
							id_dokter,
						} = vItems;

						const vDokteridx = UFunc.getObjectIndeks(uDatadokterArr,id_dokter);
						const vDoktercaption= vDokteridx >= 0 ? uDatadokterArr[vDokteridx].dokter_caption : "Undf";
						
						const vCabangidx 	= UFunc.getObjectIndeks(uDatacabangArr,id_cabang);
						const vCabangcaption= ((uDatacabangArr||[])[vCabangidx] || {}).cabang_nama;

						return (
						<li key={vKeys}>
						<div className="d-flex justify-content-between">
						<div>
							<b>{UFunc.HariAngka(tgl_antri)}</b>
							{parseInt(no_ruang) > 0 && (
							<>
							<span className="mx-1">&middot;</span>
							(Rg-{no_ruang||"1"})
							</>
							)}
						</div>
						
						<div>
							{(jam_awal||"")!=="" && (
								<b>{(jam_awal||"")}</b>
							)}
							{parseInt(no_ruang) <= 0 && (
								<b>UMUM</b>
							)}

							{(parseInt(id_antrian)||0)<=0 && (
							<>
							<small className="mx-1">&middot;</small>
							<CTooltip className="d-none d-lg-block" content="--Hapus Janjian">
							<CLink
								className="classcursorpointer classikontabel classikonhapus"
								onClick={()=>hdlKlikJanjianhapus(vKeys)}/>
							</CTooltip>
							</>
							)}
						</div>
						</div>
						
						<div className="classfontsmaller">
							{(parseInt(uDatamainObj.id_dokter||"0") !== parseInt(id_dokter)) && (
							<>
							<small className="text-primary">{vDoktercaption}</small>
							<small className="mx-1">&middot;</small>
							</>
							)}
							
							{uDatacabangArr.length > 1 && (
							<small className="">{vCabangcaption}</small>
							)}
						</div>

						{(keterangan||"").trim()!=="" && (
						<div className="fst-italic classfontsmaller text-capitalize"><small>{keterangan||""}</small></div>
						)}
						</li>
						)
					})}
				</ol>
				)}
			</div>
		</>
		)//->
	}
	const contentPage500 = () => {
		if(!uHtml500msg) return ""

		return (
			<CCard className="my-3 classbgcard">
			<CCardHeader><b>Page 500</b></CCardHeader>
			<CCardBody className="py-5">{UFunc.renderHTML(uHtml500msg)}</CCardBody>
			</CCard>
		)//>
	}
	const contentDlgview = () => {
		if(uHtml500msg) return (<></>)//->
		if(!uIsDlgviewshow) return (<></>)//->

		if(uDlgviewjenis === "KETGIGI") return contentDlgviewketgigi()

		return (<></>)//->
	}
	const contentDlgviewperawat = () => {//--TIDAK_DIPAKAI--/
		if(uDlgviewjenis !== "PERAWAT") return "";

		return (
		<>
		<CRow className="mx-0">
		{uDataperawatArr.map((vItems,vKeys)=>{
			const {
				id,perawat_caption
			} = vItems; 

			return (
			<CCol md="6" lg="4" className=""
				key={vKeys}>
				<div className="bg-light p-2 py-1 mb-2 border rounded-1">
				<CFormCheck type="checkbox"
					label={perawat_caption}
					value={id}
					checked={UFunc.getObjectIndeks(uPerawatArr,id)>=0}
					onChange={(e)=>{hdlChangePerawatcheck(e)}}
					id={"inpperawat"+id}/>
				</div>
			</CCol>
			) 
		})}
		</CRow>
		</>
		)//>
	}
	const contentDlgviewketgigi = () => {
		if(uDlgviewjenis !== "KETGIGI") return (<></>)//>

		return (
		<>
		<table width="100%" cellPadding={3} cellSpacing={0} className="fw-bolder">
			<tbody>
			<tr>
			<td align="left" colSpan={4}><CLink className="classcursorpointer ps-3 d-block bg-light text-decoration-none" style={{color:"Orange"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Paramolar KW I")}>Paramolar</CLink></td>
			<td align="center" colSpan={4}><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Blue"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Mesiodent")}>Mesiodent</CLink></td>
			<td className="p-0" rowSpan={9} width={1} bgcolor="Green"><CImage src={pjson.homepage+"api/images/img_spacer.png"} width={1}/></td>
			<td align="center" colSpan={4}><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Blue"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Mesiodent")}>Mesiodent</CLink></td>
			<td align="right" colSpan={4}><CLink className="classcursorpointer pe-3 d-block bg-light text-decoration-none" style={{color:"Orange"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Paramolar KW II")}>Paramolar</CLink></td>
			</tr>
			<tr>
			<td align="center" width="7%">&nbsp;</td>
			<td align="center" width="7%">&nbsp;</td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.55")}>55</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.54")}>54</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.53")}>53</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.52")}>52</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.51")}>51</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.61")}>61</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.62")}>62</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.63")}>63</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.64")}>64</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.65")}>65</CLink></td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="7%">&nbsp;</td>
			<td align="center" width="7%">&nbsp;</td>
			</tr>
			<tr>
			<td colSpan={8} align="center">&nbsp;</td>
			<td colSpan={8} align="center">&nbsp;</td>
			</tr>
			<tr>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.18")}>18</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.17")}>17</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.16")}>16</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.15")}>15</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.14")}>14</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.13")}>13</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.12")}>12</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.11")}>11</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.21")}>21</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.22")}>22</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.23")}>23</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.24")}>24</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.25")}>25</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.26")}>26</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.27")}>27</CLink></td>
			<td align="center"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.28")}>28</CLink></td>
			</tr>
			<tr height={1}>
			<td colSpan={8} align="center" className="p-0" height={1} style={{fontSize:1,backgroundColor:"green"}}>
				<CImage src={pjson.homepage+"api/images/img_spacer.png"} height={1}/>
			</td>
			<td colSpan={8} align="center" className="p-0" height={1} style={{fontSize:1,backgroundColor:"green"}}>
				<CImage src={pjson.homepage+"api/images/img_spacer.png"} height={1}/>
			</td>
			</tr>
			<tr>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.48")}>48</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.47")}>47</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.46")}>46</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.45")}>45</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.44")}>44</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.43")}>43</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.42")}>42</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.41")}>41</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.31")}>31</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.32")}>32</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.33")}>33</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.34")}>34</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.35")}>35</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.36")}>36</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.37")}>37</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Green"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.38")}>38</CLink></td>
			</tr>
			<tr>
			<td colSpan={8} align="center">&nbsp;</td>
			<td colSpan={8} align="center">&nbsp;</td>
			</tr>
			<tr>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.85")}>85</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.84")}>84</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.83")}>83</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.82")}>82</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.81")}>81</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.71")}>71</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.72")}>72</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.73")}>73</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.74")}>74</CLink></td>
			<td align="center" width="6%"><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Maroon"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi No.75")}>75</CLink></td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%">&nbsp;</td>
			<td align="center" width="6%">&nbsp;</td>
			</tr>
			<tr>
			<td align="left" colSpan={4}><CLink className="classcursorpointer ps-3 d-block bg-light text-decoration-none" style={{color:"Orange"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Paramolar KW IV")}>Paramolar</CLink></td>
			<td align="center" colSpan={4}><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Blue"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Mesiodent")}>Mesiodent</CLink></td>
			<td align="center" colSpan={4}><CLink className="classcursorpointer d-block bg-light text-decoration-none" style={{color:"Blue"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Mesiodent")}>Mesiodent</CLink></td>
			<td align="right" colSpan={4}><CLink className="classcursorpointer pe-3 d-block bg-light text-decoration-none" style={{color:"Orange"}} onClick={(e)=> hdlKlikPilihketgigi(e,"Gigi Paramolar KW III")}>Paramolar</CLink></td>
			</tr>
			</tbody>
		</table>
		</>
		)//->
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//>

		if(uDlgformdataObj.jenis === "PILIHTANGGAL") return contentFormtanggal();
		if(uDlgformdataObj.jenis === "JANJIAN") return contentFormjanjian();
		if(uDlgformdataObj.jenis === "PILIHPERAWAT") return contentFormperawat();
		if(uDlgformdataObj.jenis === "FORMTERAPI") return contentFormitemmobile();

		return (<></>)//->
	}
	const contentFormtanggal = () => {
		if(uDlgformdataObj.jenis !== "PILIHTANGGAL") return (<></>)//->

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CFormInput size="sm" type="date"
			value={uDlgformdataObj.tgl_periksa}
			onChange={(e)=>{uDlgformdataObj.tgl_periksa = e.target.value;setEfekview(!uEfekview)}}
			onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
			id="inpformtglperiksa"/>
		</CForm>
		)//->
	}
	const contentFormperawat = () => {
		if(uDlgformdataObj.jenis !== "PILIHPERAWAT") return (<></>)//->

		const vPerawatkwd	= (uDlgformdataObj.kwd_perawat||"").trim();
		const vPerawatviewArr= vPerawatkwd==="" ? uDataperawatArr
			: uDataperawatArr.filter(vItems => 
				((vItems.perawat_caption||"").toUpperCase()).includes(vPerawatkwd.toUpperCase()));//-*/

		return (
		<CForm className=""
			style={{marginRight:"-15px",marginLeft:"-15px",}}>
		<div className="mb-3 bg-light border p-3 px-lg-5 border-top border-bottom">
			<CFormInput size="sm"
				value={uDlgformdataObj.kwd_perawat||""}
				onChange={(e)=>{uDlgformdataObj.kwd_perawat = e.target.value;setEfekview(!uEfekview)}}
				placeholder="--Masukkan Keyword Perawat.."
				onKeyDown={(e)=>{
					if(e.keyCode === 40) {
						e.preventDefault()
						e.stopPropagation()
						document.getElementById("inpperawat0") &&
							document.getElementById("inpperawat0").focus();
						return;
					}
				}}
				id="inpperawatsearch"/>
		</div>

		<div 
			style={{maxHeight:(window.innerHeight - 295),overflowY:"auto",overflowX:"hidden"}}
			className="px-3">
		{vPerawatviewArr.map((vItems,vKeys)=>{
			const {
				id,perawat_caption
			} = vItems; 

			return (
			<>
			<CInputGroup size="sm" className={"p-4 py-2 border"+
				(vKeys > 0 ? " border-top-0": "")+
				(uDlgformdataObj.init_keys===vKeys ? " bg-light text-black": "")+
				""
				}
				onMouseEnter={(e)=>{uDlgformdataObj.init_keys = vKeys;setEfekview2(!uEfekview2)}}
				onMouseLeave={(e)=>{uDlgformdataObj.init_keys = undefined;setEfekview2(!uEfekview2)}}
				key={vKeys}>
				<input type="checkbox" 
					value={id}
					checked={(uDlgformdataObj.perawatid_arr||[]).indexOf(id)>=0}
					onChange={(e)=>{
						const vValueID 	= e.target.value;
						const vValueidx = (uDlgformdataObj.perawatid_arr||[]).indexOf(vValueID);
						if(vValueidx < 0) {
							(uDlgformdataObj.perawatid_arr||[]).push(vValueID);
						} else {
							(uDlgformdataObj.perawatid_arr||[]).splice(vValueidx,1);
						}
						setEfekview2(!uEfekview2)
					}}
					onKeyDown={(e)=>{
						if(vKeys < vPerawatviewArr.length-1) return;

						UFunc.hdlKeyDownInput(e,"btnDialogSimpan");
					}}
					id={"inpperawat"+vKeys}/>
				<label htmlFor={"inpperawat"+vKeys} 
					className="ms-2 classcursorpointer">{perawat_caption}</label>
			</CInputGroup>
			</>
			) 
		})}
		</div>
		</CForm>
		)//->
	}
	const contentFormjanjian = () => {
		if(uDlgformdataObj.jenis !== "JANJIAN") return (<></>)//>
		const vCabangcaption	= uCabanginitObj.nama||"UNDF";

		return (
		<CForm className="mx-lg-5">
			<CRow className="mb-4 mx-0 mx-lg-5">
				<CCol className="py-3 border rounded">
				<div className="classpetunjuk">*){" "}
				{UFunc.renderHTML(uBahasaObj.petunjuk_daftarantri||"Masukkan Data-data Pendaftaran Pemeriksaan pada form di bawah ini, kemudian tekan SIMPAN."+
					"<br/>Jika Pasien Sudah Terdaftar pada Tanggal Tersebut, dan membutuhkan PERUBAHAN, Harap Hubungi FO !"+
				"")}
				</div>
				</CCol>
			</CRow>

			{uDatacabangArr.length > 1 && (
			<CRow className="my-3">
			<CCol xs="4" className="text-md-end">{uBahasaObj.caption_unitcabang||"Unit Cabang"}</CCol>
			<CCol xs="8" lg="5" className="text-end text-md-start text-lg-start text-primary">
				<CFormSelect size="sm"
					value={uDlgformdataObj.id_cabang}
					onChange={(e)=>{uDlgformdataObj.id_cabang = e.target.value;setEfekview(!uEfekview)}}
					className=""
					id="inpformcabangid">
				{uDatacabangArr.map((vItems,vKeys)=>{
					return (
					<option key={vKeys} value={vItems.id}>{vItems.cabang_nama}</option>
					)
				})}
				</CFormSelect>
			</CCol>
			</CRow>
			)}

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end fw-bold">{uBahasaObj.caption_tglantri||"Tgl Antri"}</CCol>
			<CCol xs="8" lg="5" className="text-end">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="date"
					value={uDlgformdataObj.tgl_antri||""}
					onChange={(e)=>{uDlgformdataObj.tgl_antri=(e.target.value);setEfekview(!uEfekview)}}
					id="inptglantri"/>
				<span className="me-2"/>

				<CInputGroupText className="bg-transparent py-0">
				<CTooltip className="d-none d-lg-block" content="--Cek TANGGAL ANTRIAN dulu..">
				<CLink className="classcursorpointer"
					onClick={()=>hdlKlikCekantriantoggle()}>
				<CImage src={pjson.homepage+"api/images/menus_antrianpasien.png"} height={23}/>
				</CLink>
				</CTooltip>
				</CInputGroupText>
				</CInputGroup>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-success">
				<small className="d-md-none">{uBahasaObj.word_dokter||"Dokter"}</small>
				<span className="d-none d-md-block">{uBahasaObj.caption_dokterjanjian||"Dokter Janjian"}</span>
			</CCol>
			<CCol xs="8" lg="5" className="text-start fw-bolder text-primary">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					placeholder="--Pilih Dokter.."
					value={uDlgformdataObj.dokter_caption}
					onClick={()=>hdlKlikDokterjanjianlookup()}
					id="inpjanjiandoktercaption"/>
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikDokterjanjianlookup()}
					id="btnjanjiandokterlookup">
					<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-success">{uBahasaObj.caption_jamjanjian||"Jam Janjian"}</CCol>
			<CCol xs="8" lg="4" className="text-start">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="time"
					min="07:00" max="22:00" 
					value={uDlgformdataObj.jam_awal||""}
					onChange={(e)=>{uDlgformdataObj.jam_awal=(e.target.value);setEfekview(!uEfekview)}}
					id="inpjamawal"/>
				<CInputGroupText className="py-0">s.d.</CInputGroupText>
				<CFormInput size="sm" type="time"
					min="07:00" max="22:00" 
					value={uDlgformdataObj.jam_akhir||""}
					onChange={(e)=>{uDlgformdataObj.jam_akhir=(e.target.value);setEfekview(!uEfekview)}}
					id="inpjamakhir"/>
				</CInputGroup>
				<div className="d-block d-lg-none classpetunjuk">
				*) Format 24hrs (09:00 atau 15.30)
				</div>
			</CCol>
			<CCol lg="4" className="d-none d-lg-block text-start classpetunjuk ps-0">
				*) Format 24hrs (09:00 atau 15.30)
			</CCol>
			</CRow>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-success">
				<small className="d-md-none">{uBahasaObj.word_ruangan||"Ruangan"}</small>
				<span className="d-none d-md-block">{uBahasaObj.caption_pilihruang||"Pilih Ruangan"}</span>
			</CCol>
			<CCol xs="8" lg="4" className="text-start">
				<CFormSelect size="sm"
					value={uDlgformdataObj.no_ruang||"1"}
					onChange={(e)=>{uDlgformdataObj.no_ruang=(e.target.value);setEfekview(!uEfekview)}}
					id="inpnoruang">
					{(() => {
						 let vOption = [];
						 for (let i = 1; i <= (parseInt(uCabanginitObj.jml_ruangan)||2); i++) {
						 	vOption.push(<option value={i} key={i}>Ruang No-{i}</option>);
						 }
						 return vOption;
					})()}
				</CFormSelect>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="my-2">
			<CCol xs="4" className="text-md-end">{uBahasaObj.caption_ketantrian||"Ket.Antrian"}</CCol>
			<CCol xs="8" className="text-start">
				<CFormInput size="sm" maxLength={255}
					value={uDlgformdataObj.keterangan||""}
					onChange={(e)=>{uDlgformdataObj.keterangan=(e.target.value);setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpketantri"/>
			</CCol>
			</CRow>
		</CForm>
		)//->
	}
	const contentFormitemmobile = () => {
		if(uDlgformdataObj.jenis !== "FORMTERAPI") return (<></>);

		const vArridx = uDlgformdataObj.idxtabel===undefined 
			? -1 : parseInt(uDlgformdataObj.idxtabel);

		return (
		<CForm>
		<CRow className="my-2">
			<CCol xs="4">Diagnosa</CCol>
			<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm" maxLength={100}
				readOnly={true}
				value={uDlgformdataObj.diagnosa_caption||""}
				placeholder="--Pilih DIAGNOSA.."
				onClick={()=>hdlKlikFormitemdiagnosalookup()}
				id="inpformitemdiagnosalookup"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikFormitemdiagnosalookup()}
				id="btnformitemdiagnosalookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			<CFormInput size="sm" maxLength={255}
				className="mt-2"
				placeholder="--Keterangan DIAGNOSA.."
				value={uDlgformdataObj.diagnosa_keterangan||""}
				onChange={(e)=>{uDlgformdataObj.diagnosa_keterangan=e.target.value;setEfekview(!uEfekview)}}
				id="inpformitemdiagnosaketerangan"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4">Tindakan</CCol>
			<CCol>
			<CFormTextarea size="sm" rows={2}
				className="classfontsmaller py-1"
				value={uDlgformdataObj.tindakan||""}
				onChange={(e)=>{uDlgformdataObj.tindakan=e.target.value;setEfekview(!uEfekview);}}
				placeholder={"--"+(uBahasaObj.word_tindakan||"Tindakan")+".."}
				id="inpformitemtindakan"/>
			<div className="classpetunjuk">*) {uBahasaObj.petunjuk_textarea}</div>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="12">Nama Terapi</CCol>
			<CCol xs="12" md="9">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				readOnly={vArridx >= 0}
				value={uDlgformdataObj.terapi_caption||""}
				onKeyDown={(e)=>{
					if(vArridx >= 0) return;
					hdlKDownFormitemcaption(e)
				}}
				onChange={(e)=>{uDlgformdataObj.terapi_caption=e.target.value;setEfekview(!uEfekview)}}
				placeholder={"--"+(uBahasaObj.caption_keywordterapi||"Masukkan Keyword Terapi").toUpperCase()+".."}
				id="inpformitemcaption"/>
			{vArridx < 0 && (
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikFormitemlookup()}
				id="btnformitemlookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			)}
			</CInputGroup>
			</CCol>
			
			<div className="d-md-none my-2"/>

			<CCol xs="4" className="d-md-none">QTY(s)</CCol>
			<CCol xs="8" md="3" className="ps-md-0">
				<CInputGroup size="sm">
				<CFormInput size="sm" type="number"
					value={uDlgformdataObj.terapi_qty||0}
					onChange={(e)=>{uDlgformdataObj.terapi_qty=e.target.value;setEfekview(!uEfekview)}}
					onFocus={(e)=>e.target.select()}
					className="text-end"
					id="inpformitemqty"/>
				<CInputGroupText>unt</CInputGroupText>
				</CInputGroup>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4">Ket.Gigi</CCol>
			<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm" maxLength={255}
				value={uDlgformdataObj.ketgigi||""}
				onChange={(e)=>{uDlgformdataObj.ketgigi=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inpformitemketgigi"/>
			<CButton size="sm" color="dark"
				className="d-none d-md-block"
				onClick={()=>hdlKlikketgigiview()}
				id="btnformitemketgigi">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="5" className="text-success">
				<small className="text-success d-md-none">Tarif Terapi (Rp)</small>
				<span className="text-success d-none d-md-block">Tarif Terapi (Rp)</span>
			</CCol>
			<CCol className="text-end text-success fw-bolder">
				{UFunc.formatAngka(uDlgformdataObj.terapi_tarif)}
			</CCol>
		</CRow>
		</CForm>
		)//->
	}
	const contentFloatantrian = () => {
		if(uHtml500msg) return (<></>);
		if(!uCekantrianshow) return (<></>);

		return (
		<>
		<div 
			style={{
				position:"fixed",
				top:"1%",right:"1%",
				minWidth:300,
				width:"35%",
				zIndex:1091,
				backgroundColor:"#AEAEAE99",
				border:"1px dotted gray",
				borderRadius:10,
			}}
			className="d-none d-lg-block"
			id="idshowantrian">
			{contentShowantrian()}
		</div>

		<div 
			style={{
				position:"fixed",
				top:"1%",right:"1%",
				minWidth:300,
				width:"50%",
				zIndex:1091,
				backgroundColor:"#AEAEAE99",
				border:"1px dotted gray",
				borderRadius:10,
			}}
			className="d-none d-md-block d-lg-none"
			id="idshowantrian">
			{contentShowantrian()}
		</div>

		<div 
			style={{
				position:"fixed",
				top:"1%",right:"1%",
				minWidth:300,
				width:"98%",
				zIndex:1091,
				backgroundColor:"#AEAEAE",
				border:"1px dotted gray",
				borderRadius:10,
			}}
			className="d-md-none"
			id="idshowantrian">
			{contentShowantrian()}
		</div>
		</>
		)//->
	}
	const contentShowantrian = () => {
		if(!uCekantrianshow) return "";

		//--> [{"dokter_id":"99","dokter_caption":"AGNES ENDRA, SpPros, DRG","pasien_id":"43371","pasien_caption":"Andy","pasien_telp":null,"jam_awal":"11:15:00","jam_hadir":null,"no_urut":"0","keterangan":"Ini Ketarangan Antrian","id":"26"}]
		const vAntrianjanjianArr= uCekDataantrianArr.filter(vItems=>parseInt(vItems.no_urut)<=0);
		const vAntrianumumArr 	= uCekDataantrianArr.filter(vItems=>parseInt(vItems.no_urut)>0);

		return (
		<div className="position-relative" style={{
			padding:15,
			minHeight:window.innerHeight-50,
			height:window.innerHeight-20,
			}}>
			<div style={{position:"absolute",top:-1,left:-1,zIndex:10}}>
			<CButton size="sm" color="transparent" className="p-1 fw-bolder classcursorpointer"
				onClick={()=>setCekantrianshow(false)}
				id="btncekantriclose">
			<CIcon icon="cilXCircle" className="text-danger" height={30}/>
			</CButton>
			</div>
		<CCard className="classbgcard w-100 h-100">
		<CCardHeader className="text-center">
			<small className="fw-bolder">CEK Antrian Pasien</small>
			<div className="classborderbottom my-2"/>
			<CForm className="mx-3">
			<CInputGroup size="sm">
				{uDatacabangArr.length > 1 && (
				<CFormSelect size="sm"
					value={uCekantrianObj.id_cabang||uDlgformdataObj.id_cabang}
					onChange={(e)=>{uCekantrianObj.id_cabang=e.target.value;setEfekview(!uEfekview)}}
					id="inpcekcabangid">
				{uDatacabangArr.map((vItems,vKeys)=>{
					return (
					<option value={vItems.id} key={vKeys}>{vItems.cabang_nama}</option>
					)
				})}
				</CFormSelect>
				)}
				<CFormInput size="sm" type="date"
					value={uCekantrianObj.tgl_antri || uDlgformdataObj.tgl_antri}
					onChange={(e)=>{uCekantrianObj.tgl_antri=e.target.value;setEfekview(!uEfekview)}}
					id="inpcektglantri"/>
				<CButton size="sm" color="secondary"
					onClick={()=>hdlKlikCekantrianview()}
					id="btncekantri">
					<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
			</CInputGroup>
			</CForm>
		</CCardHeader>
		<CCardBody style={{ overflowY:"auto",}}>
			{(uCekantrianObj.isLoading||false)==true ? (
				<center><CSpinner className="mx-auto" color="blue"/></center>
			) : (
			<>
			{(uCekantrianObj.errormsg||"")!=="" ? (
				<div className="classfontsmaller text-center">{UFunc.renderHTML(uCekantrianObj.errormsg)}</div>
			) : (
				<small>
				{vAntrianjanjianArr.length > 0 && (
				<>
				<div className="px-2 mb-1 fw-bolder text-white" 
					style={{backgroundColor:"#666699"}}>Antrian Janjian</div>
				{vAntrianjanjianArr.map((vItems,vKeys)=>{
					const {
						dokter_id,dokter_caption,pasien_id,pasien_caption,jam_awal,no_urut,keterangan
					} = vItems;

					const vListyle	= {
						borderBottom: "1px dotted black"
					}
					if(parseInt(pasien_id)===parseInt(uPasienID))
						vListyle.backgroundColor = "#FF510044";

					return (
					<div style={vListyle} key={vKeys}>
					<CRow className="mx-0 pb-1">
						<CCol md="1" className="px-0 text-end">{vKeys+1}.</CCol>
						<CCol md="9">
							<div><b>{vItems.pasien_caption}</b></div>
							<small className="text-primary classfontsmaller fst-italic">{vItems.dokter_caption}</small>
						</CCol>
						<CCol md="2" className="px-0 text-center">
							<b>{(vItems.jam_awal||"").substring(0,5)}</b>
						</CCol>
					</CRow>
					</div>
					)
				})}
				</>
				)}

				{vAntrianumumArr.length > 0 && (
				<>
				<div className="px-2 mb-1 fw-bolder text-white" 
					style={{backgroundColor:"#993366"}}>Antrian Umum</div>
				{vAntrianumumArr.map((vItems,vKeys)=>{
					const {
						dokter_id,dokter_caption,pasien_id,pasien_caption,jam_awal,no_urut,keterangan
					} = vItems;

					const vListyle	= {
						borderBottom: "1px dotted black"
					}
					if(parseInt(pasien_id)===parseInt(uPasienID))
						vListyle.backgroundColor = "#FF510044";

					return (
					<div style={vListyle} key={vKeys}>
					<CRow className="mx-0 pb-1">
						<CCol md="1" className="px-0 text-end">{vKeys+1}.</CCol>
						<CCol md="9">
							<div><b>{vItems.pasien_caption}</b></div>
						</CCol>
						<CCol md="2" className="px-0 text-center">
							No-<b>{(vItems.no_urut)}</b>
						</CCol>
					</CRow>
					</div>
					)
				})}
				</>
				)}
				</small>
			)}
			</>
			)}
		</CCardBody>
		</CCard>
		</div>
		)
	}
	const contentDlgviewfs = () => {}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();

		setDatapasienObj({})
		setHeaderloading(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : uPasienID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setHeaderloading(false);
			setLoading(false);
			if(output_string.tampil) {
				setDatapasienObj(JSON.parse(output_string.datapasien||"{}"))
				setDatadokterArr(JSON.parse(output_string.datadokter||"[]"))
				setDataperawatArr(JSON.parse(output_string.dataperawat||"[]"))
				setDataanamnaseArr(JSON.parse(output_string.dataanamnase||"[]"))
				setDatacatdokterArr(JSON.parse(output_string.datanotedokter||"[]"))
				setDataterapiArr(JSON.parse(output_string.dataterapi||"[]"))
				setDatajanjianArr(JSON.parse(output_string.datajanjian||"[]"))
				setDataitemcicilanArr(JSON.parse(output_string.dataitemcicilan||"[]"))
				setDatadiagnosaArr(JSON.parse(output_string.datadiagnosa||"[]"))

				uDatamainObj.progress_id 		 = parseInt(output_string.progress_id || "0");
				uDatamainObj.progress_dokterid 	 = parseInt(output_string.progress_dokterid || "0");
				uDatamainObj.progress_keterangan = output_string.progress_keterangan || "";
				
				//console.log("(Periksa - apiLoadawal) "+(output_string.datadokter||"[]"))
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Periksa - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setHeaderloading(false);
			setLoading(false);
			console.log("(Periksa - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiJanjianchecking = () => {
		const vElsimpan	= document.getElementById("btnDialogSimpan")
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		const vDATAS	= JSON.stringify({
			send_pasienid: uPasienID,
			send_dataformantri : JSON.stringify(uDlgformdataObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_cekinputantri";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				const vTmpObj = {
					id_cabang: uDlgformdataObj.id_cabang||uCabanginitObj.id_cabang,
					tgl_antri: uDlgformdataObj.tgl_antri||"",
					id_dokter: uDlgformdataObj.dokter_id||"0",
					jam_awal: uDlgformdataObj.jam_awal||"",
					jam_akhir: uDlgformdataObj.jam_akhir||"",
					no_ruang: uDlgformdataObj.no_ruang||"1",
					keterangan: uDlgformdataObj.keterangan||"",
				}

				console.log("(Periksa - apiJanjianchecking) vTmpObj : "+JSON.stringify(vTmpObj));
				uDatamainObj.janjian_arr = [...uJanjianArr,vTmpObj];
				setUpdatefield(!uUpdatefield);
				hdlToggleDlgform()
			} else if(output_string.info) {
				showToast(output_string.info);
				setEfekview2(!uEfekview2);
			} else if(output_string.errors) {
				console.log("(Periksa - apiJanjianchecking) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Rekammedis - apiJanjianchecking) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	const apiLoadantrian = () => {
		uCekantrianObj.errormsg 	= "";
		uCekantrianObj.isLoading 	= true;
		setEfekview(!uEfekview);

		const vDATAS	= JSON.stringify({
			send_cabangid : uCekantrianObj.id_cabang||uDlgformdataObj.id_cabang,
			send_tglantri : uCekantrianObj.tgl_antri||uDlgformdataObj.tgl_antri,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_antrian/ld_data";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uCekantrianObj.isLoading = false;
			if(output_string.tampil) {
				//console.log("(Periksa - apiLoadantrian) output_string.dataobject : "+output_string.dataobject);
				const vTmpArr =  JSON.parse(output_string.dataobject || "[]");
				uCekantrianObj.antrian_arr = vTmpArr;
				if(vTmpArr.length <= 0)
					uCekantrianObj.errormsg = "<div class='classpetunjuk'>&middot; Data Antrian Tidak Ada &middot;</div>";
				setEfekview2(!uEfekview2);
			} else if(output_string.info) {
				uCekantrianObj.errormsg = (output_string.info);
				setEfekview2(!uEfekview2);
			} else if(output_string.errors) {
				console.log("(Periksa - apiLoadantrian) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				uCekantrianObj.errormsg = ("<font color='red'>"+vMsg+"</font>");
				setEfekview2(!uEfekview2);
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uCekantrianObj.isLoading = false;
			uCekantrianObj.errormsg = ("<font color='red'>"+(pjson.mydefault.msg500str.join(" "))+"</font>");
			setEfekview2(!uEfekview2)
			console.log("(Rekammedis - apiLoadantrian) catch-error : "+error);
		});
	}
	const apiSimpanmain = () => {
		const vElsimpan	= document.getElementById("btnSimpanmain")
		vElsimpan && (vElsimpan.disabled = true);
		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			showToast(uBahasaObj.pesan_periksasukses||"Berhasil","SUKSES");
			//uNavigate(uUrlbefore,{replace:true});
		},2500);return;//-*/

		const vDATAS	= JSON.stringify({
			send_pasienid: uPasienID,
			send_datamain : JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_simpanmain";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			if(output_string.sukses) {
				uDispatch({type: "set", gBillingperiksaObj: undefined});
				showToast((uBahasaObj.pesan_periksasukses||"Berhasil"),"SUKSES");
				uNavigate(uUrlbefore,{replace:true});
			} else if(output_string.periksa_invalid) {
				uDispatch({type: "set", gBillingperiksaObj: undefined});
				showToast(output_string.periksa_invalid);
				uNavigate(uUrlbefore,{replace:true});
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Periksa - apiLoadantrian) output_string.errors : "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else
					vMsg = pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Rekammedis - apiLoadantrian) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

    	apiLoadawal();

    	return () => {
			setDlgviewshow(false); setDlgformshow(false);
			setDatadiagnosaArr([]);
    		setDatapasienObj({});
    		setDatadokterArr([]);
    		setDataperawatArr([]);
    		setDataanamnaseArr([]);
    		setDatacatdokterArr([]);
    		setDatajanjianArr([]);//-*/
    	}
	},[]);
	/*React.useEffect(()=>{
		if(uHeightperawat > uHeightanamnesa) {
			uElcardanamnesa && (uElcardanamnesa.style.height = uHeightperawat+"px");
		} else if(uHeightperawat < uHeightanamnesa) {
			uElcardperawat && (uElcardperawat.style.height = uHeightanamnesa+"px");
		}
	},[uHeightperawat,uHeightanamnesa])
	React.useEffect(()=>{
		//uElcardfooter && (uElcardfooter.style.height = uHeightnote+"px"); return;
		if(uHeightfooter > uHeightnote) {
			uElcardnote && (uElcardnote.style.height = uHeightfooter+"px");
		} else if(uHeightfooter < uHeightnote) {
			uElcardfooter && (uElcardfooter.style.height = uHeightnote+"px");
		}
	},[uHeightfooter,uHeightnote])//-*/
	React.useEffect(()=>{
		if(uJanjianArr.length <= 0 && uDatajanjianArr.length > 0) {
			uDatamainObj.janjian_arr = uDatajanjianArr;
			setUpdatefield(!uUpdatefield);
			return;
		}
	},[uDatamainObj,uJanjianArr,uDatajanjianArr])
	React.useEffect(()=>{ uDispatch({type: "set", gBillingperiksaObj: uDatamainObj}); },[uUpdatefield])
	React.useEffect(()=>{
		const vDokterid = parseInt(uDatamainObj.progress_dokterid)||0;
		if(vDokterid <= 0 || uDatadokterArr.length <= 0) return;

		const vDokteridx = UFunc.getObjectIndeks(uDatadokterArr,vDokterid);

		uDatamainObj.id_dokter 		= vDokterid;
		uDatamainObj.nama_dokter 	= (uDatadokterArr[vDokteridx]||{}).dokter_caption || "";
		setUpdatefield(!uUpdatefield)
	},[uDatamainObj,uDatamainObj.progress_dokterid])
	React.useEffect(()=>{
		//console.log("(Periksa [tgl_periksa]) uDatamainObj => "+JSON.stringify(uDatamainObj))
		if(uDatamainObj.tgl_periksa===undefined
			) {
			uDatamainObj.tgl_periksa = UFunc.DbDate();
			setUpdatefield(!uUpdatefield);
		}
	},[uDatamainObj,uDatadokterArr,uDatamainObj.tgl_periksa])
	React.useEffect(()=>{
		if(uTokenObj.userhak === "DOKTER") {
			if(uDatamainObj.id_dokter === undefined) {
				uDatamainObj.id_dokter 		= uTokenObj.user_dokterid||"0";
				uDatamainObj.nama_dokter 	= uTokenObj.usercaption||"";
			}
			return;
		}
	},[uDatamainObj,uDatamainObj.id_dokter])
	React.useEffect(()=>{
		if(uItemsArr.length <= 0) {
			uDatamainObj.nilai_bruto = undefined;
			setUpdatefield(!uUpdatefield); return
		}

		uDatamainObj.nilai_bruto	= uItemsArr.reduce((vAkumulator, vItems) => {
			return vAkumulator + 
				(parseFloat(vItems.tarif_terapi||0) * parseInt(vItems.qty_terapi||0));
		}, 0);

		setUpdatefield(!uUpdatefield); 
	},[uItemsArr.length,uTabelitemObj,uIsDlgformshow])
	React.useEffect(()=>{
		const vMaxpersen 	= parseFloat(uSystemsettingObj.persen_diskonmax) || 0;
		const vMaxdiskon	= (vMaxpersen <= 0 ? 1 : (vMaxpersen/100)) 
			* (parseInt(uDatamainObj.nilai_bruto)||0)
		const vDiskon 		= (parseInt(uDatamainObj.nilai_diskon)||0)
		if(vDiskon > vMaxdiskon) {
			uDatamainObj.nilai_diskon = vMaxdiskon;
			setUpdatefield(!uUpdatefield);			
		}
	},[uDatamainObj.nilai_diskon])
	React.useEffect(()=>{
		const vNetto = (parseInt(uDatamainObj.nilai_bruto)||0)
			- (parseInt(uDatamainObj.nilai_diskon)||0)

		uDatamainObj.nilai_netto = vNetto;
		setUpdatefield(!uUpdatefield);
	},[uDatamainObj.nilai_bruto,uDatamainObj.nilai_diskon])
	React.useEffect(()=>{
		if(uDatamainObj.tgl_periksa !== UFunc.DbDate()
			|| (uDatamainObj.anamnase||"").trim() !== ""
			|| (uDatamainObj.note_dokter||"").trim() !== ""
			|| (uDatamainObj.note_nota||"").trim() !== ""
			|| (parseInt(uDatamainObj.id_dokter)||0) !== (parseInt(uTokenObj.user_dokterid)||0)
			|| (parseInt(uDatamainObj.nilai_diskon)||0) > 0
			|| uPerawatArr.length > uDatajanjianArr.length
			|| uItemsArr.length > 0
			|| uJanjianArr.length > 0
			|| uFototambahArr.length > 0
			) setIsFormupdate(true);
		else setIsFormupdate(false);
	},[uDatamainObj.tgl_periksa,
	uDatamainObj.id_dokter,
	uDatamainObj.anamnase,
	uPerawatArr.length,
	uItemsArr.length,
	uDatamainObj.note_dokter,
	uDatamainObj.note_nota,
	uDatamainObj.nilai_diskon,
	uJanjianArr.length,
	uFototambahArr.length
	])
	React.useEffect(()=>{
		if(!uIsDlglookupshow) {
			setDlglookupjenis("");
			setInpDlglookup("");
			setDataDlglookupArr([]);
			return;
		}
	},[uIsDlglookupshow])
	React.useEffect(()=>{
		if(!uIsDlgviewshow) {
			setDlgviewheader("");
			setDlgviewjenis("");
			return;
		}
	},[uIsDlgviewshow])
	React.useEffect(()=>{
		if(!uIsDlgformshow) {
			setDlgformdataObj({})
			setCekantrianshow(false)
			return;
		}


		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			let vElfocus = document.getElementById("inptglantri");
			if(uDlgformdataObj.jenis === "FORMTERAPI")
				vElfocus = document.getElementById("inpformterapicaption");
			else if(uDlgformdataObj.jenis === "PILIHTANGGAL")
				vElfocus = document.getElementById("inpformtglperiksa");
			else if(uDlgformdataObj.jenis === "PILIHPERAWAT")
				vElfocus = document.getElementById("inpperawatsearch");

			if(!isMobile) vElfocus && vElfocus.focus();		
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{ 
		if(!uCekantrianshow) {
			setCekantrianObj({});
			setEfekview(!uEfekview); 
			return
		}
		apiLoadantrian();

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			document.getElementById("btncekantriclose") &&
				document.getElementById("btncekantriclose").focus();
		},350)
	},[uCekantrianshow])
	React.useEffect(()=>{ 
		uCekantrianObj.antrian_arr = [];
		setEfekview(!uEfekview);
	},[uCekantrianObj.tgl_antri,uCekantrianObj.id_cabang])
	//---END EFFECT--/

	/*if(isMobile) return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="classheadertop mb-2 py-0">
		<CContainer fluid className="m-0 p-0 py-1">
			<CHeaderToggler className="ps-1"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="me-auto d-md-block">
				{contentHeadermain()}
			</CHeaderNav>
			
			<CHeaderNav className="ms-3">
				<MyHeaderProfile isWithLogout={false} {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5">UNDEFINED METHOD</div>
		</div>
		</>
	)//-*/

	//console.log("(Periksa) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		{contentFloatantrian()}
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0 m-0">
		<CContainer fluid className="classheadertop m-0" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikBack()}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto">
				{contentHeadermain()}
			</CHeaderBrand>
			
			<CHeaderNav className="">
				<MyHeaderProfile isWithLogout={false} {...props}/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid 
			className="bg-white p-2 px-3 justify-content-center justify-content-md-between align-items-center" 
			style={{minHeight:52}}>
			<div style={{width:"60%"}} className="px-2 d-none d-md-block">{contentHeadersub()}</div>

			<div>
			<CTooltip className="d-none d-lg-block" content="--Reset Form Billing">
				<CLink 
					className="classikon classikonreset classcursorpointer"
					onClick={()=>hdlKlikReset()}/>
			</CTooltip>

			{(!uHtml500msg) ? (
			<>
			<span className="mx-1">&middot;</span>
			(<b>{UFunc.formatAngka(uItemsArr.length)}</b>)
			<span className="mx-1">&middot;</span>
			<small className="align-top">Rp</small>
			<small className="align-middle text-success fw-bold display-6">
				{UFunc.formatAngka(uDatamainObj.nilai_netto)}
			</small>
			</>
			) : (
				<div className="align-middle text-success fw-bold py-1"/>
			)}
			</div>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-1">
		<CContainer fluid className="px-0">
		{(uHtml500msg) ? (<>{contentPage500()}</>) : (
		<CForm>
		<div className="d-none">{JSON.stringify(uCabanginitObj)}</div>

		<div className="d-none d-md-block">
			{contentFormheader()}
		</div>

		<div className="d-md-none">{contentFormheadermobile()}</div>

		<CRow className="">
		<CCol className="">
			<CCard className="classbgcard mb-3">
			<CCardHeader className="py-2 bg-dark">{contentTabelform()}</CCardHeader>
			<CCardBody className="px-0">
			{isMobile ? contentTabelitemmobile() : contentTabelitem()}
			</CCardBody>
			</CCard>
		</CCol>
		</CRow>

		<CRow className="align-items-stretch">
		<CCol md="6" className="mb-3">
			<CCard className="classbgcard h-100" id="idcardnote">
			<CCardHeader className="py-1 classfontsmaller"><small>Catatan Pasien</small></CCardHeader>
			<CCardBody className="">{contentNote()}</CCardBody>
			</CCard>
		</CCol>

		<CCol className="mb-3">
			{uTokenObj.userhak === "DOKTER" && (
			<CCard className="classbgcard h-100" id="idcardfooter">
			<CCardHeader className="py-1 classfontsmaller">
				<small>Reservasi Berikutnya</small>
			</CCardHeader>
			<CCardBody className="">{contentReservasi()}</CCardBody>
			</CCard>
			)}
		</CCol>
		</CRow>
		</CForm>
		)}
		</CContainer>
		</div>

		<CFooter position="sticky" className="px-3 text-black-50 justify-content-center" 
			style={{backgroundColor:"#AEAEAE",borderTop:"1px dashed #f0f0f0"}}>
		<MyButton
			onClick={()=>hdlKlikSimpanmain()}
			disabled={uHeaderloading||uHtml500msg||uIsDlgviewshow||uIsDlgformshow||uIsDlglookupshow}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>

		<div className="classtooltipcheck text-danger fw-bold" id="iddiagramchecked">
		<CIcon icon="cilCheckCircle" height={20}/>
		</div>

		<MyDialogview
			options={{centered:true,size:"xl"}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlToggleDlgLookup()}
			dialogHeader={uDlglookupheader||"Lookup Data"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogform
			options={{centered:uDlgformdataObj.dlg_center,size:uDlgformdataObj.dlg_size}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformdataObj.dlg_header}
			toggle={()=>setDlgformshow(false)}
			renderContent={contentDlgform()}/>

		<MySessionextender active={true} {...props}/>
		</>
	)//->
}	

export default Periksa