import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,CDropdownDivider,
	CFormTextarea,
	CBadge,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
//import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
	MyDialogpasien,
	MyCardformpasien,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Pasien 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uConfigappsObj			= useSelector(state => state.uConfigappsObj) || {}; 
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 25) : 100;
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uISDemoapp				= uConfigappsObj.level_app === "DEMO";
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") && !uISDemoapp ? true : false;
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	const [uEfekview2,setEfekview2] 	= React.useState(false);
	const [uSql,setSql] = React.useState("SQL");

	const uISManipulate					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN"||uTokenObj.userhak==="ENTRI") ? true : false;
	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const uLastinitObj 					= useSelector(state => state.gInitTabelSelected);// || {};
	const [uInitselectedObj,setInitselectedObj] = React.useState({});
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uJenisDlgform,setJenisDlgform]	= React.useState("");
	const [uDlgPasiensimpan,setDlgPasiensimpan]	= React.useState(false);
	//---END FORM_VARS--/

	//---DLG_PASIEN--/
	const [uIsDlgpasienshow,setDlgpasienshow]= React.useState(false);
	const [uDlgPasienID,setDlgPasienID]		= React.useState(0);
	const uDlgPasiendone		= useSelector(state => state.gInitprosespasien) || false;
	const uDlgPasienObj			= useSelector(state => state.gPasiendetilObj) || {};
	//---END DLG_PASIEN--/

	//--DOM_VARS--/
	const uElJmlData			= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setDlgformshow(false) }
	const hdlToggleDlgpasien = () =>{ setDlgpasienshow(false) }

	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].pasien_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		setJenisDlgform("EDITDATA");
		setDlgPasienID(uDatamainArr[vIdxObj].id||"0");
		setHeaderDlgform(vCaptionUpper);
		setDlgformshow(true)
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].pasien_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikBatalperiksa = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].pasien_caption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Batalkan Status Periksa: <B>"+vCaptionUpper+"</B>"+
			"<br/>Apakah Anda Yakin ?"+
			""))) apiProsesbatal(_IDTABEL);
	}
	const hdlKlikLihatdetil = (_IDTABEL) => {
		_IDTABEL	= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		setRowselect(vIdxRow);

		setDlgPasienID(_IDTABEL);
		setDlgpasienshow(true);
	}
	const hdlKlikRekammedis = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subpasien/pasien/rekammedis/"+_IDTABEL);
	}
	const hdlKlikFoto = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subpasien/pasien/fotopasien/"+_IDTABEL);
	}
	const hdlKlikDaftarantrian = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subpasien/pasien/daftarantri/"+_IDTABEL);
	}
	const hdlKlikLihatpiutang = (_IDTABEL) => {}

	const hdlKlikDlgsimpan = () => {
		if(uJenisDlgform==="EDITDATA") 
			{ setDlgPasiensimpan(true); return; }
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(Pasien - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)

		setJenisDlgform("");
		setDlgPasienID(0);
		setDlgPasiensimpan(false);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return ""

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= vMin;
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_pasien || "");
		const vKeywordcolor = uSettingob.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		/*const vDataviewArr	= isMobile 
			? uDatamainArr.slice(0,(vMax+1)) : uDatamainArr.slice(vMin,(vMax+1));//--*/
		const vDataviewArr	= uDatamainArr.filter(vItems => 
			parseInt(vItems.page||1) === parseInt(uPageActive));
		
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
			<th width={35} className="px-1">No</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="4" lg="5" className="text-start">{uBahasaObj.caption_namapasien||"Nama Pasien"}</CCol>
			<CCol md="3" lg="2" className="">&middot;</CCol>
			<CCol md="1" className="">{uBahasaObj.word_umur||"Umur"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"Keterangan"}</CCol>
			</CRow>
			</th>
			</tr>

			<tr className="d-md-none classfontsmaller">
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,pasien_caption,panggilan,
				telp_hp,alamat,umur,init_process,status_periksa,sex,
				status_piutang,cabang_register,nomor_rm,ihs_number,nik
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (pasien_caption||"").toUpperCase();

			const vPasiencaption	= vKeyword==="" ? (pasien_caption||"UNDF") 
				: (pasien_caption||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vPanggilan	= vKeyword==="" ? (panggilan||"") 
				: (panggilan||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNomorRM	= vKeyword==="" ? (nomor_rm||"") 
				: (nomor_rm||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTelphp	= vKeyword==="" ? (telp_hp||"") 
				: (telp_hp||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vNIK	= vKeyword==="" ? (nik||"-") 
				: (nik||"-").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vIHSnumber	= vKeyword==="" ? (ihs_number||"") 
				: (ihs_number||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vCabangregister	= (cabang_register||"UNDF");
				/*vKeyword==="" ? (cabang_register||"UNDF") 
				: (cabang_register||"UNDF").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");//-*/
			const vAlamat	= vKeyword==="" ? (alamat||"") 
				: (alamat||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vTglInput	= vKeyword==="" ? UFunc.TglAngkaDT(tgl_input||"") 
				: UFunc.TglAngkaDT(tgl_input||"").replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");
			const vUserinput	= UFunc.Usercaptionfilter((userinput||"UNDF"),1);
				/*vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<b><u style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</u></b>");//-*/
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1 pe-0">{vNumber}.</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="4" lg="5" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div>
						<span className="text-capitalize text-info">{UFunc.renderHTML(vPasiencaption)}</span>
						{(nomor_rm||"")!=="" && (
						<span className="d-none d-md-inline classfontsmaller ms-1">
						&middot; (<small className="fw-bolder">{UFunc.renderHTML(vNomorRM)}</small>)
						</span>
						)}
						</div>

						<div style={{color:((sex||"")==="P"?"pink":"navy")}} 
							className="fw-bolder d-none d-md-block ps-1">[{sex||"L"}]</div>
						
						{(init_process||false)===true ? (
							<CSpinner className="d-md-none" color="warning" size="sm"/>
						) : (
						<div className="d-md-none">
						{(uISManipulate) && (status_periksa||"TIDAK")==="YA" && (
						<>
							<CLink onClick={()=>hdlKlikBatalperiksa(id)}>
							<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"}
								className="align-middle me-1" height={25}/>
							</CLink>
						</>
						)}

						<CDropdown>
						<CDropdownToggle caret={false} size="sm"
							className="p-0" color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							{(uISManipulate) && (
							<CDropdownItem onClick={()=>hdlKlikDaftarantrian(id)} className="classcursorpointer">
								<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="ms-0 me-2 align-middle" height={25}/>
								Booking Reservasi
							</CDropdownItem>
							)}

							{uTokenObj.userhak!== "ENTRI" && (
							<CDropdownItem onClick={()=>hdlKlikRekammedis(id)} className="classcursorpointer">
								<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="ms-0 me-2 align-middle" height={25}/>
								Rekam Medis Pasien
							</CDropdownItem>
							)}

							<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)}>
								<CIcon icon="cilFindInPage" className="classikonbox ms-0 me-2" height={25}/>
								{uBahasaObj.caption_viewdetil||"Lihat Detil Pasien"}
							</CDropdownItem>

							<CDropdownItem onClick={()=>hdlKlikFoto(id)} className="classcursorpointer">
								<CImage src={pjson.homepage+"api/images/icon_foto.png"} className="ms-0 me-2 align-middle" height={25}/>
								Foto Pasien
							</CDropdownItem>

							{(uISManipulate || (uIsHapusallow||false)===true) && (
								<CDropdownDivider/>
							)}

							{(uISManipulate) && (
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit me-2 align-middle" height={25}/>
								{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							)}
							
							{(uIsHapusallow||false)===true && (
							<>
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus me-2 align-middle" height={25}/>
								{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							</>
							)}
						</CDropdownMenu>
						</CDropdown>
						</div>
						)}
					</div>

					<small className="d-none d-md-block">
						<span className="me-1">&middot;</span>
						{uDatacabangArr.length > 1 && (
						<>
						<span className="text-primary">{UFunc.renderHTML(vCabangregister)}</span>
						<span className="mx-1">&middot;</span>
						</>
						)}
						<span className="text-success classfontsmaller">
							IHS:<b className="ms-1">
							{(ihs_number||"")!=="" ? (
							<>{UFunc.renderHTML(vIHSnumber)}</>
							) : (
							<CIcon icon="cilXCircle" height={15}/>							
							)}
							</b>
						</span>
						<span className="ms-1">&middot;</span>
					</small>

					<span className="text-danger d-none d-md-block classfontsmaller">
						<small>
						[{UFunc.renderHTML(vTglInput)}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}]
						</small>
					</span>

					<div className="d-none">{(nomor_rm||"NORM")}</div>
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="3" lg="2" className="px-0 text-md-center d-none d-md-block">
					{(init_process||false)===true ? (
						<CSpinner color="warning" size="sm"/>
					) : (
					<>
					{(uISManipulate) && (status_periksa||"TIDAK")==="YA" && (
						<CTooltip content={"--Batalkan Periksa: "+vCaptionUpper}>
						<CLink 
							onClick={()=>hdlKlikBatalperiksa(id)}
							className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_periksabatal.png"}
								className="me-2" height={32}/>
						</CLink>
						</CTooltip>
					)}

					{uTokenObj.userhak!== "ENTRI" && (
					<CTooltip content={"--"+(uBahasaObj.caption_rekammedis||"Rekam Medis")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikRekammedis(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_rekammedis.png"} className="me-1" height={27}/>
					</CLink>
					</CTooltip>
					)}

					{(uISManipulate) && (
					<CTooltip content={"--"+(uBahasaObj.caption_bookingreservasi||"Booking Reservasi")+": "+vCaptionUpper}>
					<CLink onClick={()=>hdlKlikDaftarantrian(id)} className="classcursorpointer">
						<CImage src={pjson.homepage+"api/images/icon_booking.png"} className="mx-1" height={27}/>
					</CLink>
					</CTooltip>
					)}
						
					<CDropdown>
					<CDropdownToggle caret={false} size="sm"
						className="p-0" 
						color="transparent">
						<CIcon icon="cilChevronBottom" className="classikonbox" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem onClick={()=>hdlKlikLihatdetil(id)} className="classcursorpointer">
							<CIcon icon="cilFindInPage" className="classikonbox ms-0 me-2" height={27}/>
							{uBahasaObj.caption_viewdetil||"Lihat Detil"}
						</CDropdownItem>

						<CDropdownItem onClick={()=>hdlKlikFoto(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/icon_foto.png"} className="ms-0 me-2 align-middle" height={25}/>
							Foto Pasien
						</CDropdownItem>

						{(uISManipulate || (uIsHapusallow||false)===true) && (
							<CDropdownDivider/>
						)}

						{(uISManipulate) && (
						<CDropdownItem onClick={()=>hdlKlikEdit(id)} className="classcursorpointer">
							<CIcon className="classikonedit align-middle me-2" height={27}/>
							{uBahasaObj.caption_editdata||"Edit Data"}
						</CDropdownItem>
						)}
							
						{(uIsHapusallow||false)===true && (
						<>
						<CDropdownItem onClick={()=>hdlKlikHapus(id)} className="classcursorpointer">
							<CIcon className="classikonhapus align-middle me-2" height={27}/>
							{uBahasaObj.caption_hapusdata||"Hapus Data"}
						</CDropdownItem>
						</>
						)}
					</CDropdownMenu>
					</CDropdown>
					</>
					)}
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_norm||"No.RM"}</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<small className="fw-bolder">{UFunc.renderHTML(vNomorRM)}</small>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">NIK</CCol>
				<CCol xs="7" className="px-1 d-md-none">
					<b>{UFunc.renderHTML(vNIK)}</b>
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">IHS Number</CCol>
				<CCol xs="7" className="px-1 d-md-none text-success">
					{(ihs_number||"")!=="" ? (
					<>{UFunc.renderHTML(vIHSnumber)}</>
					) : (
					<CIcon icon="cilXCircle" height={20}/>							
					)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_sex||"Gender"}</CCol>
				<CCol xs="7" md="1" className="px-1 d-md-none">
					<b style={{color:((sex||"")==="P"?"pink":"navy")}} >
					[ {(sex||"L")} ]
					</b> 
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_umur||"Umur"}</CCol>
				<CCol xs="7" md="1" className="px-1 text-md-center">
					<small className="fw-bolder">{(umur||"-")}</small>
				</CCol>

				<CCol xs="4" className="px-1 d-md-none text-start">{uBahasaObj.word_alamat||"Alamat"}</CCol>
				<CCol xs="8" md="4" className="px-1 text-md-start">
					{(((alamat||"")==="" || (alamat||"")==="-") 
						&& ((telp_hp||"")==="" || (telp_hp||"")==="-") 
						&& ((ihs_number||"")==="" || (telp_hp||"")==="-") 
						&& (status_piutang||"TIDAK")==="TIDAK"
					) ? ("-") : (
					<>
						{(status_piutang||"TIDAK")==="YA" && (
						<div className="d-none d-md-block">
							<CLink
								onClick={()=>hdlKlikLihatpiutang(id)}
								className="classcursorpointer">
							<CBadge color="danger" className="classblinking">&middot; ADA HUTANG &middot;</CBadge>
							</CLink>
						</div>
						)}

						{(nik||"")!=="" && (
						<div className="d-none d-md-block">NIK: <b className="">{UFunc.renderHTML(vNIK)}</b></div>
						)}

						{(alamat||"")!=="" && (
						<small className="fst-italic">{UFunc.renderHTML(vAlamat)}</small>
						)}

						{(telp_hp||"")!=="" && (uTokenObj.userhak !== "DOKTER") && (
						<div className="d-none d-md-block">Telp/HP. <b className="text-primary">{UFunc.renderHTML(vTelphp)}</b></div>
						)}
					</>
					)}
				</CCol>

				{(telp_hp||"")!=="" && (uTokenObj.userhak !== "DOKTER") && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">Telp/HP</CCol>
				<CCol xs="7" className="px-1 text-primary d-md-none">
					<b>{UFunc.renderHTML(vTelphp)}</b>
				</CCol>
				</>
				)}

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				{uDatacabangArr.length > 1 && (
				<>
				<CCol xs="5" className="px-1 d-md-none text-start">
					<small>Cabang Register</small>
				</CCol>
				<CCol xs="7" className="px-1 text-primary d-md-none">
					<small>&middot;{UFunc.renderHTML(vCabangregister)}&middot;</small>
				</CCol>
				</>
				)}

				<CCol xs="4" className="px-1 d-md-none text-start">
					<>{uBahasaObj.caption_inputby||"Inputby"}</>
				</CCol>
				<CCol xs="8" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.renderHTML(vTglInput)} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				{(status_piutang||"TIDAK")==="YA" && (
				<>
				<CCol xs="12" className="px-1 my-2 d-md-none classborderbottom"/>
				<CCol xs="12" className="px-1 d-md-none text-center">
					<CLink
						onClick={()=>hdlKlikLihatpiutang(id)}
						className="classcursorpointer">
					<CBadge color="danger" className="classblinking">&middot; ADA HUTANG &middot;</CBadge>
					</CLink>
				</CCol>
				</>
				)}
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)
	}
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)//->
		if(uJenisDlgform==="EDITDATA") return (<>{contentEditpasien()}</>)

		return (<>UNDF</>)//->
	}
	const contentEditpasien = () => {
		if(uJenisDlgform!=="EDITDATA") return (<></>)//->
		
		return (
			<MyCardformpasien 
				pasienID={uDlgPasienID}
				showButton={false}
				simpanKlik={uDlgPasiensimpan}
				{...props}/>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = (_PAGE) => {
		_PAGE	= parseInt(_PAGE)||1;
		setHtml500msg();

		const vKeyword 		= uKeywordObj.kwd_pasien || "";

		let vDatamainArr	= uDatamainArr;
		if(vKeyword.length < 3) {
			vDatamainArr 	= []
		} else {
			if(uDatamainArr.length >= 10000) {
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) === 1);
			} else
				vDatamainArr	= vDatamainArr.filter(vItems=>parseInt(vItems.page||1) !== uPageActive);
		}
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_keyword : vKeyword,
			send_page: _PAGE,
			send_jmlperpage: uMaxData,
			send_loadjml: (JSON.stringify(uLastinitObj||{}) !== "{}") ? "YA" : "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				//setSql((output_string.sql||"--EMPTY--"))
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				if(vKeyword.length >= 3)
					setJmlData(parseInt(output_string.total_data||uJmlData))
				else setJmlData(vTmpObj.length);

				setDatamainArr([...vDatamainArr,...vTmpObj]);
				//console.log("(Pasien - apiLoaddata) uPageActive : "+(uPageActive));
		    	if(JSON.stringify(uLastinitObj||{}) !== "{}") 
		    		setInitselectedObj(uLastinitObj);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setEfekview(!uEfekview);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_pasien/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vIdxObj,1);
				setRowselect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Pasien - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Pasien - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesbatal = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		uDatamainArr[vIdxObj].init_process = true
		setEfekview(!uEfekview)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainArr[vIdxObj].init_process = false
			uDatamainArr[vIdxObj].status_periksa = "TIDAK"
			setEfekview2(!uEfekview2);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : _IDTABEL,
			send_reloaddata: "TIDAK",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/pr_periksabatal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vIdxObj].init_process = false
			if(output_string.sukses) {
				uDatamainArr[vIdxObj].status_periksa = "TIDAK"
				setEfekview2(!uEfekview2);
			} else {
				setEfekview2(!uEfekview2)
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("(Pasien - apiProsesbatal) output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[vIdxObj].init_process = false
			setEfekview2(!uEfekview2);
			console.log("(Pasien - apiProsesbatal) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		uDispatch({type: "set", gBillingperiksaObj: undefined});

    	//const vPageinit	= uPageActive;
		//console.log("(Pasien - []) uLastinitObj.initPage => "+(uLastinitObj.initPage))
		if(JSON.stringify(uLastinitObj||{}) !== "{}") {
			setPageActive(uLastinitObj.initPage||1);
			apiLoaddata(uLastinitObj.initPage||1);
		}

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
    		setDatamainArr([]);
    		setInitselectedObj({});
    		uDispatch({type: "set", gInitprosespasien: undefined});
			uDispatch({type: "set", gPasiendetilObj: undefined});
			setDlgformshow(false);
			setPageActive(0);
    	}
	},[])
	React.useEffect(()=>{ 
    	if(JSON.stringify(uLastinitObj||{}) !== "{}") return;
    	if(uPageActive <= 0) { setPageActive(1); return }

		setRowselect(-1); 
		const vDataviewArr	= uDatamainArr.filter(vItems=>parseInt(vItems.page||1) === parseInt(uPageActive));
		if(vDataviewArr.length > 0) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			return;
		}
		apiLoaddata(uPageActive);
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{ 
		if(!uElJmlData) return;

		const vKeyword 		= uKeywordObj.kwd_pasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/

		const vKeyword 		= uKeywordObj.kwd_pasien || "";
		if(vKeyword.length < 3) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		//setElJmlData(null);
		setDatamainArr([]);
		setJmlData(0);
		setIDselect(0);
		setRowselect(-1);

		//console.log("(Pasien - [uHandelView]) uHandelView => "+uHandelView)
		if(parseInt(uPageActive) !== 1) {
			setPageActive(1);
		} else apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) return;

		uNavigate("/subpasien/pasieninput");
		//setHeaderDlgform((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		//setDlgformshow(true)
	},[uHandelTambah])
	/*React.useEffect(()=>{
		//console.log("("+cComponentName+"[uIsScrollBottom]) uIsScrollBottom: "+uIsScrollBottom);
		if(!isMobile) return;
		if(!uIsScrollBottom) return;
		
		const vPageNow	= uPageActive;
		if(vPageNow+1 > uJmlHal) return;
		setPageActive(vPageNow+1);
	},[uIsScrollBottom])//-*/
	React.useEffect(()=>{
		uDispatch({type: "set", gInitTabelSelected: {}});
		if(JSON.stringify(uInitselectedObj||{}) === "{}") return;

		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+(uInitselectedObj.initID||0));
		setRowselect(vIdxRow);
		
		const vElscroll	= document.getElementById("idtr"+(uInitselectedObj.initID||0));
		const vRectdimensi = vElscroll && (vElscroll.getBoundingClientRect());
		vRectdimensi && (window.scrollTo({ top: (vRectdimensi.top||0) - 150, behavior: "smooth" }))
		//console.log("(Pasien - [uInitselectedObj]) vRectdimensi => "+JSON.stringify(vRectdimensi))

		setInitselectedObj({});
	},[uInitselectedObj])
	React.useEffect(()=>{
		if(!uIsDlgformshow) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[uIsDlgformshow]) uIsDlgformshow 1: "+uIsDlgformshow);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);
		},200);
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(uDlgPasiensimpan) {
			setDlgPasiensimpan(false);
			return 
		}
	},[uDlgPasiensimpan])
	React.useEffect(()=>{
		if(JSON.stringify(uDlgPasienObj)==="{}") return;
		if(parseInt(uIDselect) <= 0) return;

		//console.log("Pasien - [uDlgPasienObj]) uDlgPasienObj => "+JSON.stringify(uDlgPasienObj));
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,uIDselect)

		uDatamainArr[vIdxObj].pasien_caption	= uDlgPasienObj.pasien_caption||"";
		uDatamainArr[vIdxObj].panggilan	= uDlgPasienObj.panggilan||"";
		uDatamainArr[vIdxObj].telp_hp	= uDlgPasienObj.telp_hp||"";
		uDatamainArr[vIdxObj].alamat	= uDlgPasienObj.alamat||"";
		uDatamainArr[vIdxObj].umur		= uDlgPasienObj.umur||"";
		uDatamainArr[vIdxObj].sex 		= uDlgPasienObj.sex||"";

		uDatamainArr[vIdxObj].nik 		= uDlgPasienObj.nik||"";
		uDatamainArr[vIdxObj].ihs_number= uDlgPasienObj.ihs_number||"";
		uDatamainArr[vIdxObj].nomor_rm	= uDlgPasienObj.nomor_rm||"";
	},[uDlgPasienObj])
	React.useEffect(()=>{
		if(uDlgPasiendone) {
    		uDispatch({type: "set", gInitprosespasien: false});
			uDispatch({type: "set", gPasiendetilObj: {}});
			hdlToggledlg(); return;
		}
	},[uDlgPasiendone])
	//console.log("Pasien - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<b>{uBahasaObj.caption_page500||"Page 500"}</b>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between align-items-center">
		<div>
		<b className="align-middle">{uBahasaObj.caption_listdata||"List Data"}</b>
		<span className="mx-1 d-md-none">&middot;</span>
		<small className="d-md-none text-primary align-middle">{(()=>{
			const vKeyword = (uKeywordObj.kwd_pasien||"").trim();
			if(vKeyword.length < 3) return (<>&#8734;</>);

			return (<>[{UFunc.formatAngka(uJmlData)}]</>)
		})()}</small>
		</div>
		<div className="d-none">{JSON.stringify(uInitselectedObj)}</div>
		<small className="d-none">({uJmlData} - {uMaxData})</small>
		<span className="text-muted classfontsmaller">
			<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
		</span>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentMain()}</CCardBody>

		{(uJmlData > uMaxData) && (
		<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/> 
		</CCardFooter>
		)}
		</CCard>

		<MyDialogform
			options={{size:"xl",centered:false}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>

		<MyDialogpasien show={uIsDlgpasienshow}
			pasienID={uDlgPasienID}
			toggle={hdlToggleDlgpasien}
			{...props}/>
		</>
	)//>
}	

export default Pasien