import React from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
	CBadge,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";
import {
	MyButton,
	MyDialogview,
	MyDialogform,
	MyDialoglookup,
	MyHeaderProfile,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Bayar = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;
	const { tabel_id } 				= useParams(); 

	//--DOM--/
	const uNavigate 				= useNavigate();
	const uDispatch 				= useDispatch();
	//--END DOM--/

	const uRekammedisID				= parseInt(tabel_id) || 0;
	const uBahasaob  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingob 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);
	const uHTMLstylenota			= useSelector(state => state.gHTMLstyleNota);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingob.jml_mobile || 10) : (uSettingob.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsPengelola				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]		= React.useState(); 
	const [uTimeelapse,setTimeelapse]		= React.useState(0); 
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const [uFirstload,setFirstload]	= React.useState("YA"); 

	const [uIsLoadingform,setLoadingform]	= React.useState(false)
	const [uIsFormupdate,setFormupdate]		= React.useState(false)
	const [uEfekform,setEfekform]			= React.useState(false)
	//const uDataloadObj						= useSelector(state => state.kasirdata) || {};
	const uDatacabangArr					= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDataloadObj,setDataloadObj]		= React.useState({})
	const [uDatamainObj,setDatamainObj]		= React.useState({})
	const [uDataterapiArr,setDataterapiArr]	= React.useState([])
	const [uDatapaymentArr,setDatapaymentArr]= React.useState([])
	const [uDataasuransiArr,setDataasuransiArr]= React.useState([])
	const [uTimeRunning,setTimeRunning]		= React.useState(new Date())
	const [uDatacetakObj,setDatacetakObj]	= React.useState({});

	//--DLG_FORM--/
	const [uDlgformheader,setDlgformheader]	= React.useState("")
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false)
	const [uDlgformObj,setDlgformObj]		= React.useState({})
	//--END DLG_FORM--/

	//--DLG_VIEW--/
	const [uDlgviewheader,setDlgviewheader]	= React.useState("")
	const [uIsDlgviewshow,setDlgviewshow]	= React.useState(false)
	//--END DLG_VIEW--/

	//--DLG_VIEW--/
	const [uIsDlglookupshow,setDlglookupshow]	= React.useState(false)
	const [uDlglookupjenis,setDlglookupjenis]	= React.useState("")
	const [uDlglookupheader,setDlglookupheader]	= React.useState("")
	const [uInpDlglookup,setInpDlglookup]		= React.useState("")
	const [uDataDlglookupArr,setDataDlglookupArr]	= React.useState([])
	//--END DLG_VIEW--/

	//--SETTING_CETAK--/
	const uCabanginitObj 		= useSelector(state => state.gCabangatribut) || {};
	const uUrllogoDefault		= pjson.homepage+"api/images/header_nota.png";
	const uSettingcetakObj		= JSON.parse(localStorage.getItem("settingnota")) || {};
	const uLebarKertas			= parseInt(uSettingcetakObj.lebarkertas)||0;
	const uMarginKiri			= parseInt(uSettingcetakObj.marginkiri)||1;
	const uFontsize				= parseInt(uSettingcetakObj.fontsize)||12;
	const uViewLogo				= uSettingcetakObj.view_image_nota ||"TIDAK";
	const uFontstyle			= uSettingcetakObj.fontstyle || "Courier, Courier New, Sans Serif";
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/


	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikBack = async() => {
		const vFunctionnext = () => {
			uNavigate(uUrlbefore,{replace:true})
		}

		if(uIsFormupdate) {
			if(await(Konfirm("<b>Field Pembayaran Belum Disimpan</b>.."+
				"<br/>Data Pembayaran Ada yang Telah DIRUBAH, dan BELUM Disimpan."+
				"<br/><br/>Apakah Akan Melanjutkan ?"+
				""))) vFunctionnext()
		} else vFunctionnext()
	}
	const hdlKlikReset = async() => {
		const vFunctionnext = () => {
			//uDatamainObj.tgl_bayar = undefined;
			//setDatamainObj({}); setEfekform(!uEfekform);
			initCopyloadobj(); setEfekview(!uEfekview);
		}

		if(uIsFormupdate) {
			if(await(Konfirm("<b>Field Pembayaran Belum Disimpan</b>.."+
				"<br/>Data Pembayaran Ada yang Telah DIRUBAH, dan BELUM Disimpan."+
				"<br/><br/>Apakah Tetap Akan Mereset ?"+
				""))) vFunctionnext()
		} else { apiLoadawal(); }
	}
	const hdlKlikLihatpiutang = () => {
		const vPiutangArr = uDataloadObj.piutang_arr || []; 
		setDlgviewheader("Data Piutang Pasien");
		if(vPiutangArr.length > 0) {
			setDlgviewshow(true); return;
		}

		apiLoadpiutang();
	}
	const hdlKlikBayarpiutang = async() => {
		if(await(Konfirm(
			"<b>Anda Akan Meninggalkan Pembayaran Billing</b>.."+
			"<br/>Apakah Anda Yakin ?"+
			""))) {
			uKeywordobj.kwd_kasirpiutang = uDataloadObj.pasien_caption||"";
			localStorage.setItem("listkeyword",JSON.stringify(uKeywordobj));
			uNavigate("/subbilling/kasirpiutang",{replace:true})
		}
	}
	const hdlKlikTerapitambah = () => {
		const vTmpArr	= uDataterapiArr.map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.terapi_caption,
				harga_satuan:vItems.terapi_tarif
			}
		});
		setDlglookupjenis("TERAPI");
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: TERAPI");
		setDlglookupshow(true)
	}
	const hdlKlikTerapihapus = async(_IDXKEY) => {
		_IDXKEY = _IDXKEY===undefined ? -1 : parseInt(_IDXKEY); if(_IDXKEY < 0) return;

		const vFunctionnext = () => {
			const vItemmainArr 		= uDatamainObj.itemmain_arr || []
			uDatamainObj.itemmain_arr  = [];
			if(parseInt(vItemmainArr[_IDXKEY].id) <= 0)
				(vItemmainArr).splice(_IDXKEY,1);
			else {
				(vItemmainArr)[_IDXKEY].status_hapus = "YA";
			}
			uDatamainObj.itemmain_arr = vItemmainArr;
			setEfekview(!uEfekview);
		}

		if(await(Konfirm(
			"Apakah Yakin Akan Menghapus Item No-"+(_IDXKEY+1)+" ?"+
			""))) vFunctionnext()
	}
	const hdlKlikPaymentlookup = () => {
		const vTmpArr	= uDatapaymentArr.map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.payment_caption,
			}
		});
		setDlglookupjenis("PAYMENT");
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: METODE PEMBAYARAN");
		setDlglookupshow(true)
	}

	const hdlDlgLookuptoggle = () => { setDlglookupshow(false) }
	const hdlDlgLookupPILIH = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM)||0; if(_IDITEM <= 0) return;

		let vElfocus;
		if(uDlglookupjenis==="TERAPI") {
			const vArridx	= UFunc.getObjectIndeks(uDataterapiArr,_IDITEM);
			const vTmpObj	= {
				id: 0,
				id_terapi_sub: _IDITEM,
				caption_terapi: uDataterapiArr[vArridx].terapi_caption,
				harga_terapi: uDataterapiArr[vArridx].terapi_tarif,
				qty: 1,
				status_hapus: "TIDAK",
			}
			uDatamainObj.itemmain_arr = [...(uDatamainObj.itemmain_arr||[]),vTmpObj];
		} else if(uDlglookupjenis === "PAYMENT") {
			const vArridx	= UFunc.getObjectIndeks(uDatapaymentArr,_IDITEM);
			uDatamainObj.id_payment		= uDatapaymentArr[vArridx].id;
			uDatamainObj.nama_payment	= uDatapaymentArr[vArridx].payment_caption;

			vElfocus = document.getElementById("inppaymentket");
		} else if(uDlglookupjenis === "ASURANSI") {
			const vArridx	= UFunc.getObjectIndeks(uDataasuransiArr,_IDITEM);
			uDlgformObj.asuransi_id		= uDataasuransiArr[vArridx].id;
			uDlgformObj.asuransi_caption= uDataasuransiArr[vArridx].asuransi_caption;

			vElfocus = document.getElementById("inpasuransinilai");
		}
		setEfekview(!uEfekview);
		hdlDlgLookuptoggle();
		
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElfocus && vElfocus.focus()
		},350) 
	}
	const hdlToggleDlgview = () => { setDlgviewshow(false) }
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikAsuransilookup = () => {
		const vTmpArr	= uDataasuransiArr.map(vItems => {
			return {
				id:vItems.id,
				caption:vItems.asuransi_caption,
			}
		});
		setDlglookupjenis("ASURANSI");
		setDataDlglookupArr(vTmpArr);
		setDlglookupheader("Pilih: ASURANSI");
		setDlglookupshow(true)
	}

	const hdlKlikAsuransi = () => {
		uDlgformObj.jenis_form 		= "ASURANSI";
		if(parseInt(uDatamainObj.id_asuransi||"0") > 0) {
			uDlgformObj.asuransi_caption= uDatamainObj.nama_asuransi;
			uDlgformObj.asuransi_id 	= uDatamainObj.id_asuransi;
		} else {
			const vArridx = UFunc.getObjectIndeks(uDataasuransiArr,
				(uDatamainObj.pasien_asuransi_id||0));

			if(vArridx < 0) {
				uDlgformObj.asuransi_id 	= 0;
				uDlgformObj.asuransi_caption= "";
			} else {
				uDlgformObj.asuransi_id 	= uDatamainObj.pasien_asuransi_id;
				uDlgformObj.asuransi_caption= uDataasuransiArr[vArridx].asuransi_caption;
			}
		}
		uDlgformObj.nilai_asuransi 	= uDatamainObj.nilai_asuransi;

		setDlgformheader("Tanggungan Asuransi");
		setDlgformshow(true);
	}
	const hdlKlikSimpanmain = () => {
		//if(!uIsFormupdate) return;
		let vElfocus	= document.getElementById("inptglbayar");
		if((uDatamainObj.tgl_bayar||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Tidak Valid !"); return
		}
		const vTglbayarDT	= UFunc.toDatetime(uDatamainObj.tgl_bayar+" 00:00:00");
		const vTglnowDT		= UFunc.toDatetime(UFunc.DbDate()+" 00:00:00");
		const vTglperiksaDT	= UFunc.toDatetime(uDataloadObj.tgl_periksa+" 00:00:00");
		if(vTglbayarDT > vTglnowDT) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Melewati Tanggal Hari Ini !"); return
		}
		if(vTglbayarDT < vTglperiksaDT) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Bayar Lebih Lama Dari Tanggal Periksa ! "); return
		}
		if((uDatamainObj.itemmain_arr||[]).
			filter(vItems=>(vItems.status_hapus||"TIDAK")==="TIDAK").length <= 0) {
			showToast("Item Terapi Tidak Ada ! "); return
		}
		vElfocus	= document.getElementById("inpbayar");
		if(uDataloadObj.status_bayar !== "KASIR")
			if((parseInt(uDatamainObj.nilai_uang)||0) <= 0) {
				vElfocus && vElfocus.focus();
				showToast("JML Bayar Tidak Valid ! "); return
			}

		uDlgformObj.jenis_form = "FORMMAIN";
		setDlgformheader("Pembayaran Billing");
		setDlgformshow(true)
	}
	const hdlKlikDlgformsimpan = () => { 
		if(uDlgformObj.jenis_form === "FORMMAIN") {
			apiProsessimpan(); return;
		}
		if(uDlgformObj.jenis_form === "ASURANSI") {
			prosesFormasuransi(); return;
		}
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const prosesFormasuransi = () => {
		const vAsuransiID 	= parseInt(uDlgformObj.asuransi_id||"0");
		const vAsuransinilai= parseFloat(uDlgformObj.nilai_asuransi||"0");

		let vElfocus = document.getElementById("inpasuransicaption");
		if(vAsuransiID <= 0 && vAsuransinilai > 0) {
			vElfocus && vElfocus.focus();
			showToast("Nama Asuransi Harus Dipilih Terlebih Dahulu !"); return;
		}
		vElfocus = document.getElementById("inpasuransinilai");
		if(vAsuransiID > 0 && vAsuransinilai <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Tanggungan Asuransi Belum Ditentukan !"); return;
		}

		const vNilaibruto1	= parseFloat(uDatamainObj.nilai_bruto || "0") -
			parseFloat(uDatamainObj.diskon || "0");

		if(vAsuransinilai > vNilaibruto1) {
			vElfocus && vElfocus.focus();
			showToast("Nilai Klaim Asuransi Melebihi Nilai Tagihan !"); return;
		}

		uDatamainObj.id_asuransi 	= parseInt(uDlgformObj.asuransi_id)||0;
		uDatamainObj.nama_asuransi 	= uDlgformObj.asuransi_caption||"";
		uDatamainObj.nilai_asuransi = parseFloat(uDlgformObj.nilai_asuransi)||0;

		hdlToggleDlgform();
	}
	const initCopyloadobj = () => {
		//console.log("(Bayar - initCopyloadobj) uDataloadObj => "+JSON.stringify(uDataloadObj))
		setDatamainObj({...uDataloadObj,init_difference:"YA"});
	}
	const initNilaiakhir = () => {
		const vNilaibruto 	= (parseFloat(uDatamainObj.nilai_bruto) || 0);
		const vNilaidiskon	= (parseFloat(uDatamainObj.diskon) || 0);
		const vMaxdiskon 	= (parseFloat(uSystemsettingObj.persen_diskonmax) || 100);
		const vNilaiasuransi= (parseFloat(uDatamainObj.nilai_asuransi) || 0);
		const vNilaibayar	= (parseFloat(uDatamainObj.terbayar) || 0);

		const vNilaiakhir	= vNilaibruto - vNilaidiskon - vNilaiasuransi - vNilaibayar;
		uDatamainObj.nilai_akhir = vNilaiakhir;
	}
	const initCetaknota = () => {
		const vContent 	= contentCetaknota();
		if(vContent === "") return

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentNota = () => {
		if(isMobile) return "";
		if(uHtml500msg) return "";

		return (
		<CCard className="classbgcard mb-3">
		<CCardHeader className="py-0"><small>Data Transaksi</small></CCardHeader>
		<CCardBody className="py-1">
			<small>
			<CRow className="mx-0 my-1">
			<CCol>Tanggal</CCol>
			<CCol className="text-end fw-bolder">
				{uIsPengelola ? (
				<CFormInput size="sm" type="date"
					value={uDatamainObj.tgl_bayar||""}
					onChange={(e)=>{uDatamainObj.tgl_bayar = e.target.value;setEfekview(!uEfekview)}}
					id="inptglbayar"/>
				) : (
				<div className="">{UFunc.WaktuAngka(uTimeRunning)}</div>
				)}
			</CCol>
			</CRow>

			{(uDataloadObj.status_bayar||"KASIR")!=="KASIR" && (
			<CRow className="mx-0 my-2">
			<CCol>Tanggal PERIKSA</CCol>
			<CCol className="text-end fw-bolder">
				{UFunc.TglAngka(uDatamainObj.tgl_periksa)}
			</CCol>
			</CRow>
			)}

			<CRow className="mx-0 my-2">
			<CCol>Dokter</CCol>
			<CCol className="text-end fw-bolder text-primary">{uDatamainObj.nama_dokter||"UNDF, DRG"}</CCol>
			</CRow>

			{((uDataloadObj.status_bayar||"KASIR")==="KASIR" && 
				(uDatamainObj.status_piutang||"TIDAK")==="YA") && (
			<CRow className="mx-0 my-2">
			<CCol>Status Cicilan (Piutang)</CCol>
			<CCol className="text-end fw-bolder">
				<CBadge color="danger">Ada Cicilan</CBadge>
				<span className="mx-1">&middot;</span>
				{(uDatamainObj.isLoadpiutang||false)===true ? (
					<CSpinner color="danger" size="sm"/>
				) : (
				<>
				<CTooltip content="--Lihat Cicilan (Piutang)">
				<CLink className="classcursorpointer"
					onClick={()=>hdlKlikLihatpiutang()}>
				<CImage src={pjson.homepage+"api/images/icon_piutang.png"} className="classblinking " height={30}/>
				</CLink>
				</CTooltip>
				<span className="mx-1">&middot;</span>
				<CTooltip content="--Kembali ke Pembayaran Piutang">
				<CLink className="classcursorpointer"
					onClick={()=>hdlKlikBayarpiutang()}>
					<CIcon icon="cilChevronDoubleRight" className="classikonbox text-danger" height={25}/>
				</CLink>
				</CTooltip>
				</>
				)}
			</CCol>
			</CRow>
			)}
			</small>
		</CCardBody>
		</CCard>
		)
	}
	const contentItem = () => {
		if(isMobile) return "";
		if(uHtml500msg) return "";
		
		const vItemsArr	= (uDatamainObj.itemmain_arr || []).filter(
			vItems=>(vItems.status_hapus||"TIDAK")==="TIDAK");

		return (
		<CCard className="classbgcard mb-3">
		<CCardHeader className="py-0"><small>Item Terapi</small></CCardHeader>
		<CCardBody className="py-1">
			<small>
			{(uDataloadObj.status_bayar||"KASIR")==="KASIR" && (
			<>
			<div className="text-center">
				<CTooltip content="--Tambah Item Terapi">
				<CLink className="classcursorpointer classikon classikontambah"
					onClick={()=>hdlKlikTerapitambah()}/>
				</CTooltip>
			</div>
			<div className="my-2 classborderbottom"/>
			</>
			)}
			<div>
				{vItemsArr.map((vItems,vKeys)=>{
					const {
						id,id_terapi_sub,caption_terapi,harga_terapi,keterangan,qty
					} = vItems;

					return (
					<CRow className="mx-0 my-1" key={vKeys}>
					<CCol md="1" className="text-end">{(vKeys+1)}.</CCol>
					<CCol md="8" className="px-0">
						<div className="fw-bolder">{caption_terapi}</div>
						<ul className="my-0 px-0 ps-3">
						<li>
							<small className="">(
							<b className="text-primary">{UFunc.formatAngka(qty||1)}</b>unt
							<span className="mx-1">x</span>
							<span className="text-primary">{UFunc.formatAngka(harga_terapi)}</span>
							)</small>
						</li>

						{(keterangan||"").trim() !== "" && (
						<li>
							<small className="fst-italic">{keterangan}</small>
						</li>
						)}
						</ul>
					</CCol>
					<CCol md="1" className="text-center">
					{(uDataloadObj.status_bayar||"KASIR")==="KASIR" && (
						<CTooltip content={"--Hapus Terapi No-"+(vKeys+1)}>
						<CLink className="classcursorpointer classikontabel classikonhapus"
							onClick={()=>hdlKlikTerapihapus(vKeys)}/>
						</CTooltip>
					)}
					</CCol>
					<CCol md="2" className="px-0 text-end text-success">
						{UFunc.formatAngka(parseFloat(harga_terapi||0) * parseInt(qty||1))}
					</CCol>
					</CRow>
					)
				})}
			</div>
			</small>
		</CCardBody>
		</CCard>
		)//->
	}
	const contentBayar = () => {
		if(isMobile) return "";
		if(uHtml500msg) return "";
		
		const vColorsisa	= parseInt(uDatamainObj.nilai_sisa||0) <= 0 ? "text-success" : "text-danger";

		return (
		<CCard className="classbgcard mb-3">
		<CCardHeader className="py-0"><small>Data Pembayaran</small></CCardHeader>
		<CCardBody className="py-1">
			<small>
			<CRow className="mx-0 my-2">
			<CCol>Nilai Terapi &middot; (Rp)</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.nilai_bruto)}</CCol>
			</CRow>
			
			<CRow className="mx-0 my-1">
			<CCol className="text-danger">Diskon (<b>Maks: {parseInt(uSystemsettingObj.persen_diskonmax)||100}%</b>) &middot; (Rp)</CCol>
			<CCol className="text-end fw-bolder">
				{(uDataloadObj.status_bayar||"KASIR")==="KASIR" ? (
				<NumberFormat 
					value={uDatamainObj.diskon||"0"}
					displayType={'text'} 
					allowNegative={false}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDatamainObj.diskon=values.value}
					renderText={values => (
						<CFormInput 
							value={values}
							size="sm"
							className="text-end"
							onChange={(e)=>{uDatamainObj.diskon=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
						id="inpdiskon"/>
					)} 
				prefix=""/>
				) : (
				<div className="text-danger">{UFunc.formatAngka(uDatamainObj.diskon)}</div>
				)}
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-0 my-2">
			<CCol style={{color:"purple"}}>
				<div>
					Tanggungan Asuransi &middot; (Rp)

					{(uDataloadObj.status_bayar||"")==="KASIR" && (
					<>
					<span className="mx-1">&middot;</span>
					<CTooltip content="--Pilih Asuransi">
					<CLink onClick={()=>hdlKlikAsuransi()} className="classcursorpointer">
					<CIcon icon="cilSearch" className="classikonbox align-middle mx-0" height={25}/>
					</CLink>
					</CTooltip>
					</>
					)}
				</div>

				{parseInt(uDatamainObj.id_asuransi||"0") > 0 && (
				<small>
				(<b>{uDatamainObj.nama_asuransi}</b>)
				</small>
				)}
			</CCol>
			<CCol className="text-end fw-bolder" style={{color:"purple"}}>
				{parseInt(uDatamainObj.id_asuransi||"0")<=0 ? (
				<>
					<i className="fw-normal">0</i>
				</>
				) : (
				<b>{UFunc.formatAngka(uDatamainObj.nilai_asuransi)}</b>
				)}
			</CCol>
			</CRow>

			{(uDataloadObj.status_bayar||"KASIR")!=="KASIR" && (
			<>
			<div className="my-3 classborderbottom"/>

			<CRow className="mx-0 my-2">
			<CCol>Nilai Total &middot; (Rp)</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.nilai_netto)}</CCol>
			</CRow>
			<CRow className="mx-0 my-2">
			<CCol>Terbayar &middot; (Rp)</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.terbayar)}</CCol>
			</CRow>
			</>
			)}

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-0 my-2 text-success">
			<CCol>Nilai Tagihan &middot; (Rp)</CCol>
			<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.nilai_akhir)}</CCol>
			</CRow>
			
			<CRow className="mx-0 my-1">
			<CCol className="fw-bolder text-primary"><big>JML BAYAR &middot; (Rp)</big></CCol>
			<CCol className="text-end fw-bolder">
				<NumberFormat 
					value={uDatamainObj.nilai_uang||"0"}
					displayType={'text'} 
					allowNegative={false}
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uDatamainObj.nilai_uang=values.value}
					renderText={values => (
						<CFormInput size="sm"
							value={values}
							className="text-end"
							onChange={(e)=>{uDatamainObj.nilai_uang=e.target.value;setEfekview(!uEfekview)}}
							onFocus={(e)=>e.target.select()}
							onKeyDown={(e)=>{
								if(!document.getElementById("inpcatatanlain"))
									UFunc.hdlKeyDownInput(e,"btnSimpanmain")
							}}
						id="inpbayar"/>
					)} 
				prefix=""/>
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-0 my-2">
			<CCol className={vColorsisa}>
				{parseInt(uDatamainObj.nilai_sisa||0) <= 0 ? (
					<span className="">KEMBALI</span>
				) : (
					<span className="">SISA Cicilan(PIUTANG)</span>
				)}
				<span className="mx-1">&middot;</span>(Rp)
			</CCol>
			<CCol className={("text-end fw-bolder ")+vColorsisa}>
				<big>{UFunc.formatAngka(Math.abs(uDatamainObj.nilai_sisa))}</big>
			</CCol>
			</CRow>
			</small>
		</CCardBody>
		</CCard>
		)//->
	}
	const contentCatatan = () => {
		if(isMobile) return "";
		if(uHtml500msg) return "";
		if((uDataloadObj.status_bayar||"KASIR")!=="KASIR") return "";//return (<>{uDataloadObj.status_bayar}</>);

		const vJanjianArr	= uDatamainObj.janjian_arr || [];
		
		return (
		<CCard className="classbgcard mb-3">
		<CCardHeader className="py-0"><small>Catatan untuk Pasien</small></CCardHeader>
		<CCardBody className="py-2">
			<small>
			<CRow className="mx-0 my-2">
			<CCol>Catatan Dokter</CCol>
			<CCol className="fw-bolder">{uDatamainObj.note_dokter||"-"}</CCol>
			</CRow>

			<CRow className="mx-0 my-2">
			<CCol>Jadwal Berikutnya</CCol>
			<CCol className="">
				{vJanjianArr.length > 0 ? (
				<ol className="my-0 px-3">
					{vJanjianArr.map((vItems,vKeys)=>{
						const {
							id,tgl_antri,jam_awal,cabang_caption,
						} = vItems;

						return (
						<li key={vKeys}>
							<div>
							Tgl <b>{UFunc.HariAngka(tgl_antri)}</b>
							<span className="mx-1">&middot;</span>
							Jam <b>{(jam_awal||"").substring(0,5)}</b>
							</div>

							{uDatacabangArr.length > 1 && (
							<small className="fst-italic text-primary d-block">
								&middot;&middot; ({cabang_caption}) &middot;&middot;
							</small>
							)}
						</li>
						)
					})}
				</ol>
				) : ("-")}
			</CCol>
			</CRow>

			<div className="my-3 classborderbottom"/>

			<CRow className="mx-0 my-1">
			<CCol>Catatan Lainnya</CCol>
			<CCol className="">
				<CFormInput size="sm" maxLength={255}
					value={uDatamainObj.note_nota||""}
					onChange={(e)=>{uDatamainObj.note_nota = e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpanmain")}
					id="inpcatatanlain"/>
			</CCol>
			</CRow>
			</small>
		</CCardBody>
		</CCard>
		)//->
	}
	const contentDlgview = () => {
		if(isMobile) return (<></>);
		if(!uIsDlgviewshow) return (<></>);

		const vPiutangArr 	= uDataloadObj.piutang_arr || [];
		let vJumlah 		= 0
		return (
		<>
		<small className="classfontsmaller">
		<CRow className="mx-0 py-1 classborderbottom fw-bold">
			<CCol md="1" className="text-center">No</CCol>
			<CCol md="2" className="text-start">Tgl Periksa</CCol>
			<CCol md="3" className="text-start">Dokter</CCol>
			<CCol md="2" className="text-center">N.Transaksi (Rp)</CCol>
			<CCol md="2" className="text-center">Terbayar (Rp)</CCol>
			<CCol md="2" className="text-center">Sisa (Rp)</CCol>
		</CRow>
		</small>

		{vPiutangArr.map((vItems,vKeys) => {
			const {
				id,tgl_piutang,nama_dokter,nilai_netto,terbayar
			} = vItems;

			const vSisa = parseInt(nilai_netto||0) - parseInt(terbayar||0);
			vJumlah		+= vSisa;
			return (
			<CRow className="mx-0 py-2 classborderbottom" key={vKeys}>
				<CCol md="1" className="text-end">{vKeys+1}.</CCol>
				<CCol md="2" className="px-1 text-start">{UFunc.TglAngka(tgl_piutang)}</CCol>
				<CCol md="3" className="px-0 classfontsmaller text-start fw-bolder text-primary">
					{nama_dokter||"UNDF,DRG"}</CCol>
				<CCol md="2" className="text-end">{UFunc.formatAngka(nilai_netto)}</CCol>
				<CCol md="2" className="text-end">{UFunc.formatAngka(terbayar)}</CCol>
				<CCol md="2" className="text-end text-danger">{UFunc.formatAngka(vSisa)}</CCol>
			</CRow>
			)
		})}

		<CRow className="mx-0 py-2 classborderbottom fw-bolder">
			<CCol md="10" className="text-end">JUMLAH (Rp)</CCol>
			<CCol md="2" className="text-end text-danger">{UFunc.formatAngka(vJumlah)}</CCol>
		</CRow>
		</>
		)//->
	}
	const contentDlgform = () => {
		if(isMobile) return (<></>);//->
		if(!uIsDlgformshow) return (<></>)//->

		if(uDlgformObj.jenis_form === "FORMMAIN") return contentFormmain();
		if(uDlgformObj.jenis_form === "ASURANSI") return contentFormasuransi();

		return (<></>)//->
	}
	const contentFormasuransi = () => {
		if(uDlgformObj.jenis_form !== "ASURANSI") return (<></>);

		return (
		<CForm>
		
		<CRow className="my-1">
		<CCol className="text-center">
			<div className="fw-bolder text-success">
			Rp.{UFunc.formatAngka( parseFloat(uDatamainObj.nilai_bruto) - parseFloat(uDatamainObj.diskon) )}
			</div>
			<div  className="classpetunjuk px-0"><small>Nilai Transaksi</small></div>
		</CCol>
		</CRow>

		<div className="my-1 text-center classfontsmaller">
		<small>
		<CLink onClick={()=>{
			uDlgformObj.asuransi_caption = "";
			uDlgformObj.asuransi_id = 0;
			uDlgformObj.nilai_asuransi = 0;
			setEfekview(!uEfekview);
		}} className="text-normal classcursorpointer">[CLEAR]</CLink>
		</small>
		</div>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Nama Asuransi</small>
			<span className="d-none d-md-block">Nama Asuransi</span>
		</CCol>
		<CCol>
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder="--Pilih Nama Asuransi.."
				readOnly={true}
				value={uDlgformObj.asuransi_caption}
				onChange={(e)=>{uDlgformObj.asuransi_caption=e.target.value;setEfekview(!uEfekview)}}
				onClick={()=>hdlKlikAsuransilookup()}
				id="inpasuransicaption"/>
			<CButton size="sm" color="dark"
				onClick={()=>hdlKlikAsuransilookup()}
				id="btnAsuransilookup">
				<CIcon icon="cilSearch"/>
			</CButton>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol xs="3" md="4">
			<small className="d-md-none">Nilai Tanggungan</small>
			<span className="d-none d-md-block">Nilai Tanggungan</span>
		</CCol>
		<CCol>
			<NumberFormat 
				value={uDlgformObj.nilai_asuransi||0}
				displayType={'text'} 
				allowNegative={false}
				thousandSeparator={"."} 
				decimalSeparator={","}
				onValueChange={(values) => uDlgformObj.nilai_asuransi=values.value}
				renderText={values => (
					<CFormInput size="sm"
						value={values}
						className="text-end"
						onChange={(e)=>{uDlgformObj.nilai_asuransi=e.target.value;setEfekview(!uEfekview)}}
						onFocus={(e)=>e.target.select()}
						onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpasuransinilai"/>
				)} 
			prefix={isMobile?"":"Rp"}/>
		</CCol>
		</CRow>
		</CForm>
		)//->
	}
	const contentFormmain = () => {
		if(uDlgformObj.jenis_form !== "FORMMAIN") return (<></>);

		return (
		<CForm>
		<CRow className="my-1">
		<CCol>Tgl Bayar</CCol>
		<CCol className="text-end fw-bolder">{UFunc.TglAngka(uDatamainObj.tgl_bayar)}</CCol>
		</CRow>

		{parseInt(uDatamainObj.id_asuransi||"0") > 0 && (
		<CRow className="my-1">
		<CCol className="" style={{color:"purple"}}>Asuransi (<b>{uDatamainObj.nama_asuransi}</b>)</CCol>
		<CCol className="text-end fw-bolder" style={{color:"purple"}}>{UFunc.formatAngka(uDatamainObj.nilai_asuransi)}</CCol>
		</CRow>
		)}

		<CRow className="my-1">
		<CCol>Nilai Tagihan</CCol>
		<CCol className="text-end fw-bolder">{UFunc.formatAngka(uDatamainObj.nilai_akhir)}</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
		<CCol className="text-primary">Jml BAYAR</CCol>
		<CCol className="text-end fw-bolder text-primary">{UFunc.formatAngka(uDatamainObj.nilai_uang)}</CCol>
		</CRow>

		<CRow className="my-1">
		{parseInt(uDatamainObj.nilai_sisa||0) > 0 ? (
		<>
			<CCol className="text-danger"><big>Sisa Piutang</big></CCol>
			<CCol className="text-end fw-bolder text-danger"><big>{UFunc.formatAngka(uDatamainObj.nilai_sisa)}</big></CCol>
		</>
		) : (
		<>
			<CCol className="text-success">Kembalian</CCol>
			<CCol className="text-end fw-bolder text-success">{UFunc.formatAngka(Math.abs(uDatamainObj.nilai_sisa))}</CCol>
		</>
		)}
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol className="classfontsmaller">Metode Payment</CCol>
		<CCol md="8" className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				value={uDatamainObj.nama_payment||""}
				readOnly={true}
				onClick={()=>hdlKlikPaymentlookup()}
				placeholder="TUNAI (CASH)"
				id="inppaymentcaption"/>
			{parseInt(uDatamainObj.id_payment||0) > 0 && (
			<CInputGroupText className="py-0 bg-transparent">
				<CLink className="classcursorpointer"
					onClick={()=>{uDatamainObj.id_payment=0;uDatamainObj.nama_payment="";setEfekview(!uEfekview)}}>
				<CIcon icon="cilXCircle" className="text-danger"/>
				</CLink>
			</CInputGroupText>
			)}
			<CInputGroupText className="py-0 bg-dark">
			<CTooltip content="--Pilih Metode Pembayaran">
			<CLink 
				className="classcursorpointer"
				onClick={()=>hdlKlikPaymentlookup()}>
				<CIcon icon="cilMagnifyingGlass" className="text-light"/>
			</CLink>
			</CTooltip>
			</CInputGroupText>
			</CInputGroup>
		</CCol>
		</CRow>

		<CRow className="my-2">
		<CCol className="classfontsmaller">Ket.Payment</CCol>
		<CCol md="8" className="text-end">
			<CFormInput size="sm" maxLength={255}
				readOnly={(parseInt(uDatamainObj.id_payment || 0) <= 0)}
				className="text-capitalize"
				value={uDatamainObj.ket_payment||""}
				onChange={(e)=>{uDatamainObj.ket_payment=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inppaymentket"/>
		</CCol>
		</CRow>
		</CForm>
		)//->
	}
	const contentCetaknota = () => {
		if(JSON.stringify(uDatacetakObj||{}) === "{}") return "";
		//console.log("(Bayar - contentCetaknota) uDatacetakObj => "+JSON.stringify(uDatacetakObj))

		const vCabangObj	= uCabanginitObj;

		let vHTMLs 	= uHTMLstylenota;
		vHTMLs 		= vHTMLs.replace(/_LEBARKERTAS_/g, (uLebarKertas<=0?"auto":uLebarKertas+"mm"));//-->PAKESATUAN
		vHTMLs 		= vHTMLs.replace(/_MARGINKIRI_/g,uMarginKiri);
		vHTMLs 		= vHTMLs.replace(/_FONTSIZE_/g,uFontsize);
		vHTMLs 		= vHTMLs.replace(/_FONTFAMILY_/g,uFontstyle);

		const vIsWithlogo	= uViewLogo==="YA";// (uSystemsettingObj.view_image_nota||"TIDAK")==="YA";
		const vURLlogo		= (uSystemsettingObj.url_image_nota||"").trim() === "" ? uUrllogoDefault
			: uSystemsettingObj.url_image_nota;//getBase64Image(document.getElementById("idimglogo"));

		const vMainbody	= UFunc.Rendernota(uDatacetakObj);
		vHTMLs 			+=  vMainbody;
		//---LOGO--/
		let vLogoheader	= "";
		if(vIsWithlogo) 
			vLogoheader = `
			<tr valign="top">
			<td colSpan="4" align="center">
				<img src="`+vURLlogo+`" height="20mm" style="height:20mm" />
			</td>
			</tr>
			`;
		//---END LOGO--/

		//---TELP--/
		let vTelpPerusahaan	= "";
		if((vCabangObj.telp_hp || "") !== "")
			vTelpPerusahaan += `
			<tr valign="top">
			<td colSpan="4" align="center">`+
				(uBahasaob.caption_telphp||"Telp/WA")+". "+
				UFunc.capitalize(vCabangObj.telp_hp || "").toUpperCase()+
				//`<div>`+vIsWithlogo+`</div>`+
			`</td>
			</tr>
			`;
		//---END TELP--/

		const vFooternota = (vCabangObj.footer_nota||"").trim() !=="" ?
			`
			<tr>
			<td colSpan="4"><hr/></td>
			</tr>
			<tr>
			<td colSpan="4" align="center">`+(vCabangObj.footer_nota||"")+`</td>
			</tr>
			` : ``;

		vHTMLs 		= vHTMLs.replace(/_TRBODYLOGO_/g,vLogoheader);
		vHTMLs 		= vHTMLs.replace(/_NAMAPERUSAHAAN_/g,(vCabangObj.nama_klinik || "Dental Care").toUpperCase());
		vHTMLs 		= vHTMLs.replace(/_ALAMATPERUSAHAAN_/g,UFunc.capitalize(vCabangObj.alamat_klinik || ""));
		vHTMLs 		= vHTMLs.replace(/_TRBODYTELP_/g,vTelpPerusahaan);
		vHTMLs 		= vHTMLs.replace(/_TRFOOTERNOTA_/g,vFooternota);

		return vHTMLs		
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoadawal = () => {
		setHtml500msg();

		//uDispatch({type: "set", kasirdata:undefined});
		setDataloadObj({})
		setDatamainObj({})
		//setDataterapiArr([]); setDatapaymentArr([])
		setLoadingform(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoadingform(false);
			setLoading(false);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : uRekammedisID,
			send_firstload : uFirstload,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_bayar/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoadingform(false);
			setLoading(false);
			if(output_string.tampil) {
				//uDispatch({type: "set", kasirdata: JSON.parse(output_string.datamain||"{}")})
				setDataloadObj(JSON.parse(output_string.datamain||"{}"))

				setDataterapiArr(JSON.parse(output_string.dataterapi||JSON.stringify(uDataterapiArr)))
				setDatapaymentArr(JSON.parse(output_string.datapayment||JSON.stringify(uDatapaymentArr)))
				setDataasuransiArr(JSON.parse(output_string.dataasuransi||JSON.stringify(uDataasuransiArr)))
				if(uFirstload==="YA") {
					setFirstload("TIDAK")
				}
				//console.log("(Bayar - apiLoadawal) output_string.datapayment => "+(output_string.datapayment))
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Bayar - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoadingform(false);
			setLoading(false);
			console.log("(Bayar - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiLoadpiutang = () => {
		uDatamainObj.isLoadpiutang 	= true;
		setEfekview(!uEfekview);

		const vDATAS	= JSON.stringify({
			send_pasienid : uDataloadObj.id_pasien||0,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_bayar/ld_perpasien";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainObj.isLoadpiutang 	= false;
			if(output_string.tampil) {
				uDataloadObj.piutang_arr = JSON.parse(output_string.datapiutang || "[]");
				setDlgviewshow(true); 
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Bayar - apiLoadpiutang) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainObj.isLoadpiutang 	= false;
			console.log("(Bayar - apiLoadpiutang) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsessimpan = () => {
		const vEldisabled = document.getElementById("btnDialogSimpan");
		vEldisabled && (vEldisabled.disabled=true);
		setLoading(true); 
		setDatacetakObj({});

		const vDATAS	= JSON.stringify({
			send_rekammedisid : uRekammedisID,
			send_databayar: JSON.stringify(uDatamainObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_bayar/pr_bayar";
		//const vURLs	= pjson.homepage+"api/api_bayar/pr_test";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			vEldisabled && (vEldisabled.disabled = false);
			setLoading(false); 
			if(output_string.sukses) {
				hdlToggleDlgform();
				const vNotaObj	= JSON.parse(output_string.datanota||"{}");
				if(JSON.stringify(vNotaObj)==="{}") {
					showToast((uBahasaob.pesan_kasirsukses||"Berhasil.."),"SUKSES");
					uNavigate(uUrlbefore,{replace:true}); return;
				}
				setDatacetakObj(vNotaObj);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Bayar - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vEldisabled && (vEldisabled.disabled = false);
			setLoading(false); 
			console.log("(Bayar - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		//uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		if(!isMobile) {
			document.getElementById("inpbayar") &&
				document.getElementById("inpbayar").focus();

			apiLoadawal();
		}

    	return () => {
			setDlgformshow(false);
			setDlgviewshow(false);
			setDlglookupshow(false);
    		setDataloadObj({});
    		setDatamainObj({});
			setDataterapiArr([])
			setDatapaymentArr([])
			setDataasuransiArr([])
			setFirstload("YA")
			setDatacetakObj({});
    	}
	},[])
	React.useEffect(()=>{ 
		initCopyloadobj();
		setEfekview(!uEfekview)
	},[uDataloadObj])
	React.useEffect(()=>{ 
		if(JSON.stringify(uDatamainObj||{}) === "{}") return;
		if(uDatamainObj.init_difference === undefined) return;

		const vItemmainArr			= uDatamainObj.items_arr || [];
		uDatamainObj.items_arr 		= undefined;

		const vItemnewArr	= vItemmainArr.map(vItems=> {
			const vTmpObj = {...vItems,diff:"YA"}
			return (vTmpObj);
		});
		uDatamainObj.itemmain_arr 	= vItemnewArr;

		setEfekview(!uEfekview)
	},[uDatamainObj])
	React.useEffect(()=>{ 
		//console.log("(Bayar - [uDatamainObj,uDatamainObj.tgl_bayar]) uDataloadObj => "+JSON.stringify(uDataloadObj))
		//console.log("(Bayar - [uDatamainObj,uDatamainObj.tgl_bayar]) uDatamainObj => "+JSON.stringify(uDatamainObj))
		if(uDatamainObj.tgl_bayar===undefined) {
			uDatamainObj.tgl_bayar = UFunc.DbDate();
		}
	},[uDatamainObj,uDatamainObj.tgl_bayar])
	React.useEffect(()=>{ 
		//if((uDataloadObj.status_bayar||"KASIR") !== "KASIR") return;
		const vItemsArr = uDatamainObj.itemmain_arr || []
		const vJumlah = vItemsArr.reduce((SUM,vItems) => 
			SUM + ((vItems.status_hapus||"TIDAK")==="TIDAK" 
				? ( parseFloat(vItems.harga_terapi||0) * parseInt(vItems.qty||1) ) : 0)
		, 0);
		uDatamainObj.nilai_bruto = vJumlah;
		initNilaiakhir();
		setEfekview(!uEfekview)
	},[uDatamainObj,
	uDatamainObj.itemmain_arr,
	(uDatamainObj.itemmain_arr||[]).filter(vItems=>(vItems.status_hapus||"TIDAK")==="TIDAK").length
	])
	React.useEffect(()=>{ initNilaiakhir(); },[uDatamainObj,uDatamainObj.nilai_bruto])
	React.useEffect(()=>{ 
		const vNilaibruto 	= (parseInt(uDatamainObj.nilai_bruto) || 0);
		const vDiskon 		= (parseInt(uDatamainObj.diskon) || 0);
		const vMaxdiskon 	= (parseFloat(uSystemsettingObj.persen_diskonmax) || 100);
		if(vDiskon > ((vMaxdiskon/100)*vNilaibruto)) {
			uDatamainObj.diskon = (vMaxdiskon/100) * vNilaibruto;
			setEfekview(!uEfekview); return
		}
		initNilaiakhir()
		setEfekview(!uEfekview)
	},[uDatamainObj,uDatamainObj.diskon])
	React.useEffect(()=>{ 
		initNilaiakhir();
		setEfekview(!uEfekview)
	},[uDatamainObj,uDatamainObj.nilai_asuransi])
	React.useEffect(()=>{ 
		const vNilaisisa = 
			(parseInt(uDatamainObj.nilai_akhir)||0)
				 - (parseInt(uDatamainObj.nilai_uang) || 0)
		uDatamainObj.nilai_sisa = vNilaisisa;
		setEfekview(!uEfekview)
	},[uDatamainObj,
	uDatamainObj.nilai_bruto,
	uDatamainObj.diskon,
	uDatamainObj.nilai_asuransi,
	uDatamainObj.nilai_uang])
	React.useEffect(()=>{ 
		const vItemloadArr	= uDataloadObj.items_arr || [];
		const vItemmainArr	= (uDatamainObj.itemmain_arr || []).
			filter(vItems => (vItems.status_hapus||"TIDAK")==="TIDAK");

		if((uDatamainObj.tgl_bayar||"") !== UFunc.DbDate()
			|| parseInt(uDatamainObj.diskon||0) !== parseInt(uDataloadObj.diskon||0)
			|| parseInt(uDatamainObj.nilai_uang||0) > 0
			|| parseInt(uDatamainObj.id_payment||0) > 0
			|| parseInt(uDatamainObj.id_asuransi||0) > 0
			|| (uDatamainObj.note_nota||"").trim() !== (uDataloadObj.note_nota||"").trim()
			|| vItemloadArr.length !== vItemmainArr.length
		) setFormupdate(true);
		else setFormupdate(false);
	},[uDatamainObj,
	uDatamainObj.tgl_bayar,
	uDatamainObj.diskon,
	uDatamainObj.nilai_uang,
	uDatamainObj.id_asuransi,
	uDatamainObj.note_nota,
	(uDatamainObj.itemmain_arr||[]).filter(vItems=>(vItems.status_hapus||"TIDAK")==="TIDAK").length,
	(uDatamainObj.itemmain_arr||[]).length,
	uDatamainObj.id_payment,
	])
	React.useEffect(()=>{ 
		if(!uIsDlgformshow) {
			setDlgformheader(""); setDlgformObj({});
			return;
		}

		const vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			let vElfocus = document.getElementById("inppaymentcaption");
			if(uDlgformObj.jenis_form === "ASURANSI")
				vElfocus = document.getElementById("inpasuransinilai");

			if(!isMobile) vElfocus && vElfocus.focus();
		},350);
	},[uIsDlgformshow])
	React.useEffect(()=>{ 
		if(!uIsDlgviewshow) {
			setDlgviewheader("");
			return;
		}
	},[uIsDlgviewshow])
	React.useEffect(()=>{ 
		if(!uIsDlglookupshow) {
			setInpDlglookup("");
			setDlglookupjenis("");
			setDataDlglookupArr([]);
			setDlglookupheader("");
			return;
		}
	},[uIsDlglookupshow])
	React.useEffect(()=>{
		if(JSON.stringify(uDatacetakObj||{})==="{}") return;

		//---CETAK_NOTA--/
		initCetaknota()
		//---END CETAK_NOTA--/
		showToast((uBahasaob.pesan_kasirsukses||"Berhasil.."),"SUKSES");
		uNavigate(uUrlbefore,{replace:true});
	},[uDatacetakObj])
	React.useEffect(()=>{
		if(!uFrameExport) return;

		const pri 		= uFrameExport.contentWindow; if(!pri) return;
		const vURLlogo	= (uSystemsettingObj.url_image_nota||"").trim() === "" 
			? pjson.homepage+"api/images/header_nota.png"
			: uSystemsettingObj.url_image_nota;
		pri.document.open();
		pri.document.write(`<img src="`+vURLlogo+`"/>`);
		pri.document.close();
	},[uFrameExport])
	//---END EFFECT--/

	//console.log("(Bayar) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	/*if(isMobile) return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0 classheadertop">
		<CContainer fluid className="py-1">
			<CHeaderToggler className=""
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="me-auto text-normal d-md-block">
				<div className="my-0"><b>{uBahasaob.caption_bayarbilling||"Pembayaran Billing"}</b></div>
				<div className="classfontsmaller d-none d-md-block"><small className="classfontsmaller">&middot; {uTokenObj.usercaption} &middot;</small></div>
			</CHeaderNav>

			<CHeaderNav className="">
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2">
		<CContainer xxl className="px-2">
			<CCard className="classbgcard">
			<CCardHeader>Mobile Forbidden</CCardHeader>
			<CCardBody>MAAF, Fitur Mobile Masih Belum..</CCardBody>
			</CCard>
		</CContainer>
		</div>

		</div>
		</>
	)//-*/

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderNav className="me-auto text-normal d-md-block">
				<div className="my-0">
				<b>{uBahasaob.caption_bayarbilling||"Kasir Billing"}:{" "}
					{uDatamainObj.status_bayar!=="PIUTANG"?"PERIKSA":(<span className="text-danger">PIUTANG</span>)}</b>
				</div>
				
				<div className="classfontsmaller d-none d-lg-block">
					<small className="classfontsmaller">&middot; {uTokenObj.usercaption} &middot;</small>
				</div>
			</CHeaderNav>

			<CHeaderNav className="">
				<MyHeaderProfile {...props}/>
			</CHeaderNav>
		</CContainer>

		{!isMobile && (
		<CContainer fluid className="bg-white p-2 px-3" style={{minHeight:52}}>
			{uIsLoadingform===true ? (
				<div className="text-primary classblinking fst-italic mx-auto">Loading...</div>
			) : (
			<>
			{(uHtml500msg) ? (
			<div className="fw-bold">Ooops, Sorry...</div>
			) : (
			<div>
				<big className="text-info fw-bold">{uDatamainObj.pasien_caption}</big>
				{(uDatamainObj.ket_janjian||"")!=="" && (
				<>
				<span className="mx-1">&middot;</span>
				<small className="fst-italic text-primary">{uDatamainObj.ket_janjian}</small>
				</>
				)}
				{(()=>{
					if(parseInt(uDatamainObj.pasien_asuransi_id||0) <= 0) return (<></>);

					const vArridx = UFunc.getObjectIndeks(uDataasuransiArr,(uDatamainObj.pasien_asuransi_id||0));
					return (
					<>
						<span className="mx-1">&middot;</span>
		
						<CBadge color="light" style={{color:"purple",border:"1px solid purple"}}>
						<small>{uDataasuransiArr[vArridx].asuransi_caption}</small>
						</CBadge>
					</>
					)
				})()}
			</div>
			)}

			<div>
			<CTooltip content="--Reset Form Pembayaran">
			<CLink className="classikon classikonreset classcursorpointer"
				 onClick={()=>hdlKlikReset()}/>
			</CTooltip>
			</div>
			</>
			)}
		</CContainer>
		)}
		</CHeader>

		{!isMobile ? (
		<>
		<div className="body flex-grow-1 px-2">
		<CContainer fluid className="px-0">
			{(uHtml500msg) ? (
				<CCard className="classbgcard mx-lg-5">
				<CCardHeader><b>Page 500</b></CCardHeader>
				<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
				</CCard>
			) : (
			<CForm>
				<CRow className="mx-0">
				<CCol md="6" className="">
				{contentNota()}
				{contentItem()}
				</CCol>

				<CCol md="6" className="">
				{contentBayar()}
				{contentCatatan()}
				</CCol>
				</CRow>
			</CForm>
			)}
		</CContainer>
		</div>

		<CFooter position="sticky" className="text-black-50 justify-content-center" 
			style={{backgroundColor:"#AEAEAE88",borderTop:"1px dashed #AEAEAE"}}>
		<MyButton
			disabled={uIsDlgviewshow 
				|| uIsLoadingform || uHtml500msg
				|| uIsDlglookupshow || uIsDlgformshow}
			onClick={()=>hdlKlikSimpanmain()}
			id="btnSimpanmain">{uBahasaob.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>

		</>
		) : (
		<div className="body flex-grow-1 px-2 px-lg-5 mt-2">
		<CContainer fluid className="px-0">
			<CCard className="classbgcard mb-2">
			<CCardHeader className="fw-bolder">Mobile Mode Forbidden</CCardHeader>
			<CCardBody className="px-md-5">
				<p>Maaf,</p>
				<p className="fw-bold">Fitur Ini Tidak Tersedia untuk Smartphone dan Tablet..</p>
				<p>Mohon Gunakan PC/Laptop yang Terhubung dengan Printer Nota..</p>
				<p className="fw-bold">Terima Kasih,</p>
			</CCardBody>
			</CCard>
		</CContainer>
		</div>
		)}
		</div>

		<MyDialoglookup
			show={uIsDlglookupshow}
			toggle={()=>hdlDlgLookuptoggle()}
			dialogHeader={uDlglookupheader||"Lookup Data"}
			inputValue={uInpDlglookup}
			dataItemArr={uDataDlglookupArr} 
			onKlikPilihItem={hdlDlgLookupPILIH}
			id="iddialoglookup"
			{...props}/>

		<MyDialogview
			options={{centered:true,size:"lg"}}
			show={uIsDlgviewshow} 
			dialogHeader={uDlgviewheader}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>

		<MyDialogform
			options={{centered:true}}
			show={uIsDlgformshow} 
			dialogHeader={uDlgformheader}
			toggle={hdlToggleDlgform}
			onSimpan={hdlKlikDlgformsimpan}
			renderContent={contentDlgform()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		</>
	)//>
}	

export default Bayar